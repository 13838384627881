import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import _throttle from 'lodash/throttle'
import { useDispatch } from 'react-redux'
import { checkProgramProgress, programProgressReset } from './progressActions'

import logger from '../../logger'
import { usePlayerMetadata } from '../../components/StickyPlayer/context/PlayerMetadataContext'
import { MEDIA_TYPES } from '../../components/StickyPlayer/helpers/mediaTypes'
import { CoreEvent } from '../../components/StickyPlayer/helpers/coreEvents'
import { useProgressInPlayer, useSaveProgress } from './progressHooks'
import { useAudioOrVideoPlayerElement } from '../../components/StickyPlayer/hooks/useAudioOrVideoPlayerElement'
import { useIsLoggedIn } from '../../components/Auth/AuthContext'

const ProgressReporter = ({ children }) => {
  const { currentIntervalMs, _links } = useProgressInPlayer()
  const progressUrl = _links.self.href
  const {
    $playerElement,
    $videoPlayerElement,
    playerElementReady,
    videoPlayerElementReady,
    isPlayerLoading
  } = useAudioOrVideoPlayerElement()
  const { mediaType, episodeId, seriesId } = usePlayerMetadata()
  const { savePosition, savePositionAndStartPosition } = useSaveProgress()
  const dispatch = useDispatch()
  const isLoggedIn = useIsLoggedIn()

  useEffect(() => {
    if (
      isLoggedIn &&
      (mediaType === MEDIA_TYPES.PODCAST || mediaType === MEDIA_TYPES.PROGRAM || mediaType === MEDIA_TYPES.CLIP)
    ) {
      dispatch(
        checkProgramProgress({
          contentId:
            mediaType === MEDIA_TYPES.PODCAST || mediaType === MEDIA_TYPES.CLIP
              ? `${seriesId}|${episodeId}`
              : episodeId,
          progressContentType:
            mediaType === MEDIA_TYPES.PODCAST || mediaType === MEDIA_TYPES.CLIP ? 'podcastepisode' : 'programs',
          episodeId,
          seriesId,
          isLoggedIn
        })
      )

      return () => {
        dispatch(programProgressReset())
      }
    }
  }, [dispatch, episodeId, isLoggedIn, mediaType, seriesId])

  useEffect(() => {
    if (isLoggedIn && !isPlayerLoading && !!progressUrl && playerElementReady && videoPlayerElementReady) {
      const handleTimeUpdate = _throttle(savePosition, currentIntervalMs, {
        leading: false,
        trailing: true
      })

      logger.info('adding progress eventlisteners')
      const onPlayingChange = event => {
        if (event.detail.isPlaying === false) {
          savePositionAndStartPosition()
        }
      }

      const onWaitingChange = event => {
        if (event.detail.reason === 'seeking' && event.detail.isWaiting === false) {
          savePositionAndStartPosition()
        }
      }

      $playerElement.addEventListener(CoreEvent.TimeUpdate, handleTimeUpdate)
      $playerElement.addEventListener(CoreEvent.PlayingChange, onPlayingChange)
      $playerElement.addEventListener(CoreEvent.WaitingChange, onWaitingChange)

      $videoPlayerElement.addEventListener(CoreEvent.TimeUpdate, handleTimeUpdate)
      $videoPlayerElement.addEventListener(CoreEvent.PlayingChange, onPlayingChange)
      $videoPlayerElement.addEventListener(CoreEvent.WaitingChange, onWaitingChange)

      return () => {
        logger.info('removing progress eventlisteners')
        handleTimeUpdate.cancel()
        $playerElement.removeEventListener(CoreEvent.TimeUpdate, handleTimeUpdate)
        $playerElement.removeEventListener(CoreEvent.PlayingChange, onPlayingChange)
        $playerElement.removeEventListener(CoreEvent.WaitingChange, onWaitingChange)

        $videoPlayerElement.removeEventListener(CoreEvent.TimeUpdate, handleTimeUpdate)
        $videoPlayerElement.removeEventListener(CoreEvent.PlayingChange, onPlayingChange)
        $videoPlayerElement.removeEventListener(CoreEvent.WaitingChange, onWaitingChange)
      }
    }
  }, [
    $playerElement,
    $videoPlayerElement,
    currentIntervalMs,
    isLoggedIn,
    isPlayerLoading,
    playerElementReady,
    progressUrl,
    savePosition,
    savePositionAndStartPosition,
    videoPlayerElementReady
  ])

  return <>{children}</>
}

ProgressReporter.propTypes = { children: PropTypes.node }

export default ProgressReporter
