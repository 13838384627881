import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { trackPageView } from '../../clientMonitoring'
import { useLocation, useParams } from 'react-router-dom'
import { useSub } from '../Auth/AuthContext'

const SECTION_PREFIX = 'nrk/programspiller/radio'

const tnsSectionFrom = (pathname = '') => {
  const [, page, subpage = ''] = pathname.split('/')
  switch (page) {
    case '': {
      return `${SECTION_PREFIX}/frontpage`
    }
    case 'podkast':
    case 'serie':
    case 'program': {
      return `${SECTION_PREFIX}/avspillerside`
    }
    case 'guide': {
      return `${SECTION_PREFIX}/epg`
    }
    case 'direkte': {
      return `${SECTION_PREFIX}/kanal/${subpage}`
    }
    default: {
      return null
    }
  }
}

const PageView = ({ children }) => {
  const sub = useSub()
  const params = useParams()
  const location = useLocation()

  useEffect(() => {
    trackPageView({
      pathname: location.pathname,
      search: location.search,
      tnsSection: tnsSectionFrom(location.pathname),
      userId: sub
    })
  }, [location.pathname, location.search, params, sub])

  return <>{children}</>
}

PageView.propTypes = {
  children: PropTypes.node
}

export default PageView
