/**
 * COPYPASTA from ps.web
 * a helper that transforms a aspNET JSON serialized Date-strings to a Date-object
 *
 * @param {string}      value The date-string to transform
 * @return {Date}      date
 */
export default function aspDateStringToDate(value) {
  const dateRegexp = /^\/?Date\((-?\d+)/i
  if (dateRegexp.exec(value) !== null) {
    const dateInMs = parseInt(value.slice(6, -1), 10)
    return new Date(dateInMs)
  }
  return new Date()
}
