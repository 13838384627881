import React, { useEffect, useState } from 'react'
import LazyBrowserDeprecationNotification from './LazyBrowserDeprecationNotification'

const isInternetExplorer = () => {
  return window.navigator.userAgent.indexOf('Trident') > -1
}

const BrowserDeprecationNotificationContainer = props => {
  const [showNotificationState, setShowNotificationState] = useState(false)
  useEffect(() => {
    setShowNotificationState(isInternetExplorer())
  }, [])

  if (showNotificationState) {
    return <LazyBrowserDeprecationNotification />
  }
  return null
}

BrowserDeprecationNotificationContainer.propTypes = {}

export default BrowserDeprecationNotificationContainer
