import { useColorContrasts, useColors } from '../theme/Color'

const colorVariantProps = (colors, variant, contrastColor) => {
  let style = {
    bg: colors.mediumLight10,
    color: contrastColor,
    _hover: {
      bg: colors.mediumLight20
    },
    _focus: {
      bg: colors.mediumLight20
    },
    _focusVisible: {
      outline: `1px solid ${contrastColor}`
    },
    _active: {
      color: colors.light,
      bg: colors.mediumLight20
    }
  }
  if (variant === 'transparent') {
    style = {
      bg: 'transparent',
      color: colors.contrastLight,
      _hover: {
        bg: 'rgba(0, 0, 0, 0.4)'
      },
      _focus: {
        bg: 'rgba(0, 0, 0, 0.4)'
      },
      _focusVisible: {
        outline: `1px solid ${colors.contrastLight}`
      },
      _active: {
        bg: 'rgba(0, 0, 0, 0.4)'
      }
    }
  }
  if (variant === 'onlytext') {
    style = {
      bg: 'transparent',
      color: colors.contrastLight,
      _hover: {
        opacity: 0.7
      },
      _focus: {
        opacity: 0.7
      },
      _focusVisible: {
        outline: `1px solid ${colors.contrastLight}`
      },
      _active: {
        opacity: 0.7
      }
    }
  }
  if (variant === 'playpause') {
    style = {
      bg: colors.medium,
      _hover: {
        bg: colors.dark
      },
      _focus: {
        bg: colors.dark
      },
      _active: {
        bg: colors.mediumLight10
      }
    }
  }
  if (variant === 'light') {
    style = {
      bg: colors.light,
      color: colors.medium,
      _hover: {
        opacity: 0.7
      },
      _focus: {
        opacity: 0.7
      },
      _focusVisible: {
        outline: `1px solid ${colors.medium}`
      },
      _active: {
        opacity: 0.7
      }
    }
  }
  if (variant === 'login') {
    style = {
      bg: colors.light,
      color: colors.mediumLight10,
      fontWeight: '700',
      padding: '10px 18px 10px 18px',
      rounded: 'full',
      height: '44px',
      fontSize: '15px',
      border: `3px solid ${colors.mediumLight10}`,
      _hover: {
        opacity: 0.7
      },
      _focus: {
        opacity: 0.7
      },
      _active: {
        opacity: 0.7
      }
    }
  }
  if (variant === 'close') {
    style = {
      bg: colors.mediumLight10,
      color: contrastColor,
      padding: 0,
      justifyContent: 'center',
      rounded: 'full',
      _hover: {
        bg: colors.mediumLight20
      },
      _focus: {
        bg: colors.mediumLight20
      },
      _focusVisible: {
        outline: 'none'
      },
      _active: {
        color: colors.light,
        bg: colors.mediumLight20
      }
    }
  }

  if (variant === 'secondary') {
    style = {
      bg: '#061629',
      color: contrastColor,
      _hover: {
        bg: colors.mediumLight20
      },
      _focus: {
        bg: colors.mediumLight20
      },
      _focusVisible: {
        outline: `1px solid ${contrastColor}`
      },
      _active: {
        color: colors.light,
        bg: colors.mediumLight20
      }
    }
  }

  return style
}

const sizes = {
  md: {
    px: 4,
    py: 0,
    h: 10,
    fontSize: 'lg'
  },
  sm: {
    px: 2,
    py: 0,
    h: 6,
    fontSize: 'sm'
  }
}

const sizeProps = size => sizes[size]

const focusProps = () => {
  return {
    _focus: {
      outline: 'none'
    }
  }
}

const activeProps = (colors, active, contrastColor) => {
  if (active) {
    return {
      bg: colors.light,
      color: colors.dark,
      _hover: {
        bg: colors.mediumLight20,
        color: contrastColor
      },
      _focus: {
        bg: colors.light,
        color: colors.dark
      },
      _active: {
        bg: colors.light,
        color: colors.dark
      }
    }
  } else {
    return {}
  }
}

const disabledProps = () => {
  return {
    _disabled: {
      opacity: '0.3'
    }
  }
}

const baseProps = {
  display: 'inline-block',
  appearance: 'none',
  transition: 'all 250ms',
  userSelect: 'none',
  position: 'relative',
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',
  lineHeight: '0',
  outline: 'none',
  fontWeight: '600',
  fontSmoothing: 'antialiased'
}

const useButtonStyle = props => {
  const colors = useColors()[props.variantColor]
  const contrastColor = useColorContrasts()[props.variantColor]

  return {
    ...baseProps,
    ...sizeProps(props.size),
    ...focusProps(),
    ...disabledProps(),
    ...colorVariantProps(colors, props.variant, contrastColor),
    ...activeProps(colors, props.active, contrastColor)
  }
}

export default useButtonStyle
