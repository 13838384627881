import {
  useHasPlayBeenTriggered as useHasAudioPlayBeenTriggered,
  useIsPlayerPlaying as useIsAudioPlayerPlaying,
  usePlayerMediaEnd as useAudioPlayerMediaEnd,
  usePlayerElementState as useAudioPlayerElementState
} from '../context/PlayerElementStateContext'
import { useCurrentlyPlayingMediaType } from '../context/PlayerMetadataContext'
import {
  useHasPlayBeenTriggered as useHasVideoPlayBeenTriggered,
  useIsVideoPlayerPlaying,
  useVideoPlayerElementState,
  usePlayerMediaEnd as useVideoPlayerMediaEnd
} from '../context/VideoPlayerElementStateContext'
import { MEDIA_TYPES } from '../helpers/mediaTypes'

export const usePlayerElementState = () => {
  const currentlyPlayingMediaType = useCurrentlyPlayingMediaType()
  const audioPlayerElementState = useAudioPlayerElementState()
  const videoPlayerElementState = useVideoPlayerElementState()

  return currentlyPlayingMediaType === MEDIA_TYPES.CLIP ? videoPlayerElementState : audioPlayerElementState
}

export const useIsPlayerPlaying = () => {
  const currentlyPlayingMediaType = useCurrentlyPlayingMediaType()
  const isAudioPlayerPlaying = useIsAudioPlayerPlaying()
  const isVideoPlayerPlaying = useIsVideoPlayerPlaying()

  return currentlyPlayingMediaType === MEDIA_TYPES.CLIP ? isVideoPlayerPlaying : isAudioPlayerPlaying
}

export const useHasPlayBeenTriggered = () => {
  const hasAudioPlayBeenTriggered = useHasAudioPlayBeenTriggered()
  const hasVideoPlayBeenTriggered = useHasVideoPlayBeenTriggered()

  return hasAudioPlayBeenTriggered || hasVideoPlayBeenTriggered
}

export const useHasPlayBeenTriggeredOnCurrentType = () => {
  const currentlyPlayingMediaType = useCurrentlyPlayingMediaType()
  const hasAudioPlayBeenTriggered = useHasAudioPlayBeenTriggered()
  const hasVideoPlayBeenTriggered = useHasVideoPlayBeenTriggered()
  return currentlyPlayingMediaType === MEDIA_TYPES.CLIP ? hasVideoPlayBeenTriggered : hasAudioPlayBeenTriggered
}

export const usePlayerMediaEnd = () => {
  const mediaType = useCurrentlyPlayingMediaType()
  const videoPlayerMediaEnd = useVideoPlayerMediaEnd()
  const audioPlayerMediaEnd = useAudioPlayerMediaEnd()

  if (mediaType === MEDIA_TYPES.CLIP) {
    return videoPlayerMediaEnd
  } else {
    return audioPlayerMediaEnd
  }
}
