import PropTypes from 'prop-types'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import clientConfig from '../../../clientConfig'
import logger from '../../../logger'
import { CoreEvent } from '../helpers/coreEvents'
import { MEDIA_TYPES } from '../helpers/mediaTypes'
import { setPersistedMedia } from '../helpers/persistedMedia'
import { setPersistedMuted, setPersistedVolume } from '../helpers/persistedVolume'
import { toSrc } from '../helpers/toSrc'
import { usePlayerMetadata } from './PlayerMetadataContext'
import { usePlayerTime } from './PlayerTimeContext'
import { useVideoPlayerElementState } from './VideoPlayerElementStateContext'

const noop = () => {}

const VideoPlayerElementContext = createContext({
  videoPlayerElementReady: false,
  playerElementReady: false,
  playerCoreInitializerReady: false,
  isPlayerLoading: true,
  $videoPlayerElement: undefined,
  loadPlayer: noop,
  loadAndStartPlayer: noop,
  loadAndStartOrTogglePlayPause: noop,
  play: noop,
  pause: noop,
  togglePlayPause: noop,
  loadAndStartOrPlayFrom: noop,
  loadAndStartOrTogglePlayFrom: noop,
  updateToNextPlaybackSpeed: noop,
  skipBack: noop,
  skipForward: noop,
  seekTo: noop,
  seekToPosition: noop,
  setVolume: noop,
  toggleMute: noop,
  setMuted: noop,
  setPlaybackRate: noop,
  positionToLiveTime: noop,
  liveTimeToPosition: noop,
  getCurrentTime: noop,
  setSeekToState: noop,
  airplay: noop,
  requestFullscreen: noop,
  exitFullscreen: noop,
  requestPictureInPicture: noop,
  exitPictureInPicture: noop,
  toggleSubtitleVisibility: noop,
  nextSpeed: 1
})

export const VideoPlayerElementProvider = ({ children }) => {
  const [radioWebVideoPlayerElementReady, setRadioWebVideoPlayerElementReady] = useState(false)
  const [playerCoreInitializerReady, setPlayerCoreInitializerReady] = useState(false)
  const [videoPlayerElementReady, setVideoPlayerElementReady] = useState(false)
  const playerCoreInitializerRef = useRef()
  const videoPlayerElementRef = useRef()
  const [seekToState, setSeekToState] = useState(null)

  const {
    onPlayingChange,
    onError,
    onMediaInfoLoaded,
    onMediaRangeChange,
    onTimeRunningChange,
    onSessionCreated,
    onSessionClosed,
    onWaitingChange,
    onVolumeChange,
    onMutedChange,
    onRateChange,
    clearPlayerPlayerElementState,
    onPanic,
    onWebkitcurrentplaybacktargetiswirelesschanged,
    onWebkitplaybacktargetavailabilitychanged,
    onFullscreenChange,
    onEnterPictureInPicture,
    onLeavePictureInPicture,
    onSubtitlesChange,
    isTimeRunning,
    isPlaying,
    mediaRange,
    airplaySupported,
    hasPlayBeenTriggered,
    subtitles,
    isFullscreen: isVideoFullscreen
  } = useVideoPlayerElementState()

  const { loadMetadata, loading: playerMetadataLoading } = usePlayerMetadata()

  const { onTimeUpdate, onScrubberUpdate } = usePlayerTime()

  useEffect(() => {
    import('hls.js')
      .then(() => {
        return import('@nrk/radio-web-player/index.js')
      })
      .then(() => {
        setRadioWebVideoPlayerElementReady(true)
      })
  }, [])

  useEffect(() => {
    import('@nrk/radio-web-player/radio-web-player-dependencies.esm.js').then(module => {
      const { ConfiguredAdapter, HlsJsAdapter, NativeAdapter, PlayerCore, platform } = module

      const nativeAdapter = new ConfiguredAdapter(NativeAdapter)
      const hlsJsAdapter = new ConfiguredAdapter(HlsJsAdapter, () => import('hls.js'), { immediateQualityJumps: true })
      const adapters = platform.isApple ? [nativeAdapter, hlsJsAdapter] : [hlsJsAdapter, nativeAdapter]

      const playerCoreInitializer = playerElement => {
        return new PlayerCore(playerElement, adapters, {
          mediaElementOptions: { type: 'video', crossOrigin: 'anonymous' }
        })
      }
      playerCoreInitializerRef.current = playerCoreInitializer
      setPlayerCoreInitializerReady(true)
    })
  }, [])

  useEffect(() => {
    if (radioWebVideoPlayerElementReady && playerCoreInitializerReady) {
      videoPlayerElementRef.current = document.createElement('radio-web-player')
      videoPlayerElementRef.current.setAttribute('type', 'player')
      videoPlayerElementRef.current.setAttribute('ga', clientConfig.GA_ACCOUNT)
      videoPlayerElementRef.current.setAttribute('snowplowurl', clientConfig.SNOWPLOW_COLLECTOR_ENDPOINT)
      videoPlayerElementRef.current.setAttribute('psapiorigin', clientConfig.PS_API_BASE_URL)
      videoPlayerElementRef.current.playerCoreInitializer = playerCoreInitializerRef.current
      videoPlayerElementRef.current.addEventListener(CoreEvent.PlayingChange, onPlayingChange)
      videoPlayerElementRef.current.addEventListener(CoreEvent.Broken, onError)
      videoPlayerElementRef.current.addEventListener(CoreEvent.MediaInfoLoaded, onMediaInfoLoaded)
      videoPlayerElementRef.current.addEventListener(CoreEvent.MediaRangeChange, onMediaRangeChange)
      videoPlayerElementRef.current.addEventListener(CoreEvent.TimeRunningChange, onTimeRunningChange)
      videoPlayerElementRef.current.addEventListener(CoreEvent.TimeUpdate, onTimeUpdate)
      videoPlayerElementRef.current.addEventListener(CoreEvent.ScrubberUpdate, onScrubberUpdate)
      videoPlayerElementRef.current.addEventListener(CoreEvent.SessionCreated, onSessionCreated)
      videoPlayerElementRef.current.addEventListener(CoreEvent.SessionClosed, onSessionClosed)
      videoPlayerElementRef.current.addEventListener(CoreEvent.WaitingChange, onWaitingChange)
      videoPlayerElementRef.current.addEventListener(CoreEvent.VolumeChange, onVolumeChange)
      videoPlayerElementRef.current.addEventListener(CoreEvent.MutedChange, onMutedChange)
      videoPlayerElementRef.current.addEventListener(CoreEvent.RateChange, onRateChange)
      videoPlayerElementRef.current.addEventListener(CoreEvent.SubtitlesChange, onSubtitlesChange)

      videoPlayerElementRef.current.addEventListener('ElementPanic', onPanic)

      window.videoPlayerElement = videoPlayerElementRef.current
      setVideoPlayerElementReady(true)

      return () => {
        logger.warn('Hmm. Why did this happen?')
        setVideoPlayerElementReady(false)
        videoPlayerElementRef.current.removeEventListener(CoreEvent.PlayingChange, onPlayingChange)
        videoPlayerElementRef.current.removeEventListener(CoreEvent.Broken, onError)
        videoPlayerElementRef.current.removeEventListener(CoreEvent.MediaInfoLoaded, onMediaInfoLoaded)
        videoPlayerElementRef.current.removeEventListener(CoreEvent.MediaRangeChange, onMediaRangeChange)
        videoPlayerElementRef.current.removeEventListener(CoreEvent.TimeRunningChange, onTimeRunningChange)
        videoPlayerElementRef.current.removeEventListener(CoreEvent.TimeUpdate, onTimeUpdate)
        videoPlayerElementRef.current.removeEventListener(CoreEvent.ScrubberUpdate, onScrubberUpdate)
        videoPlayerElementRef.current.removeEventListener(CoreEvent.SessionCreated, onSessionCreated)
        videoPlayerElementRef.current.removeEventListener(CoreEvent.SessionClosed, onSessionClosed)
        videoPlayerElementRef.current.removeEventListener(CoreEvent.WaitingChange, onWaitingChange)
        videoPlayerElementRef.current.removeEventListener(CoreEvent.VolumeChange, onVolumeChange)
        videoPlayerElementRef.current.removeEventListener(CoreEvent.MutedChange, onMutedChange)
        videoPlayerElementRef.current.removeEventListener(CoreEvent.RateChange, onRateChange)
        videoPlayerElementRef.current.removeEventListener(CoreEvent.SubtitlesChange, onSubtitlesChange)

        videoPlayerElementRef.current.removeEventListener('ElementPanic', onPanic)
      }
    }
  }, [
    onError,
    onMediaInfoLoaded,
    onMediaRangeChange,
    onMutedChange,
    onPanic,
    onPlayingChange,
    onRateChange,
    onScrubberUpdate,
    onSessionClosed,
    onSessionCreated,
    onSubtitlesChange,
    onTimeRunningChange,
    onTimeUpdate,
    onVolumeChange,
    onWaitingChange,
    playerCoreInitializerReady,
    radioWebVideoPlayerElementReady
  ])

  useEffect(() => {
    if (videoPlayerElementReady && airplaySupported) {
      videoPlayerElementRef.current.addEventListener(
        'webkitplaybacktargetavailabilitychanged',
        onWebkitplaybacktargetavailabilitychanged
      )

      videoPlayerElementRef.current.addEventListener(
        'webkitcurrentplaybacktargetiswirelesschanged',
        onWebkitcurrentplaybacktargetiswirelesschanged
      )

      return () => {
        videoPlayerElementRef.current.removeEventListener(
          'webkitplaybacktargetavailabilitychanged',
          onWebkitplaybacktargetavailabilitychanged
        )

        videoPlayerElementRef.current.removeEventListener(
          'webkitcurrentplaybacktargetiswirelesschanged',
          onWebkitcurrentplaybacktargetiswirelesschanged
        )
      }
    }
  }, [
    airplaySupported,
    onWebkitcurrentplaybacktargetiswirelesschanged,
    onWebkitplaybacktargetavailabilitychanged,
    videoPlayerElementReady
  ])

  useEffect(() => {
    document.addEventListener('fullscreenchange', onFullscreenChange)

    return () => document.removeEventListener('fullscreenchange', onFullscreenChange)
  }, [onFullscreenChange])

  useEffect(() => {
    if (hasPlayBeenTriggered) {
      videoPlayerElementRef.current
        .querySelector('video')
        .addEventListener('enterpictureinpicture', onEnterPictureInPicture)
      videoPlayerElementRef.current
        .querySelector('video')
        .addEventListener('leavepictureinpicture', onLeavePictureInPicture)

      return () => {
        videoPlayerElementRef.current
          .querySelector('video')
          .removeEventListener('enterpictureinpicture', onEnterPictureInPicture)
        videoPlayerElementRef.current
          .querySelector('video')
          .removeEventListener('leavepictureinpicture', onLeavePictureInPicture)
      }
    }
  }, [hasPlayBeenTriggered, onEnterPictureInPicture, onFullscreenChange, onLeavePictureInPicture])

  useEffect(() => {
    if (isVideoFullscreen && subtitles?.active) {
      videoPlayerElementRef.current.activeSession.enableNativeSubtitlesDisplay()
      return () => {
        videoPlayerElementRef.current.activeSession?.disableNativeSubtitlesDisplay()
      }
    }
  }, [isVideoFullscreen, subtitles?.active])

  const seekToByType = useCallback(({ seekTo, mediaType }) => {
    if (mediaType === MEDIA_TYPES.CHANNEL && seekTo instanceof Date) {
      videoPlayerElementRef.current.activeSession.seekToLiveTime(seekTo)
    } else {
      videoPlayerElementRef.current.seekTo(seekTo)
    }
  }, [])

  // Workaround for not being able to seek before media has been played
  useEffect(() => {
    if (!!seekToState && (isTimeRunning || isPlaying) && !!mediaRange) {
      seekToByType(seekToState)
      setSeekToState(null)
    }
  }, [isPlaying, isTimeRunning, mediaRange, seekToByType, seekToState])

  const clearPlayer = useCallback(() => {
    videoPlayerElementRef.current.src = null
    videoPlayerElementRef.current.removeAttribute('snowplowoverridemediaid')
    videoPlayerElementRef.current.removeAttribute('snowplowoverridecontentsource')
    videoPlayerElementRef.current.removeAttribute('snowplowoverridecontentkind')
  }, [])

  const loadPlayer = useCallback(
    ({ clipId, episodeId, seasonId, seriesId, channelId, mediaType }) => {
      clearPlayerPlayerElementState()
      clearPlayer()
      loadMetadata({ clipId, episodeId, seasonId, seriesId, channelId, mediaType })

      videoPlayerElementRef.current.src = toSrc({ clipId, mediaType })
      videoPlayerElementRef.current.setAttribute('snowplowoverridemediaid', episodeId)
      videoPlayerElementRef.current.setAttribute('snowplowoverridecontentsource', 'podcast')
      videoPlayerElementRef.current.setAttribute('snowplowoverridecontentkind', 'video')

      setPersistedMedia({ clipId, episodeId, seasonId, seriesId, channelId, mediaType })
    },
    [clearPlayer, clearPlayerPlayerElementState, loadMetadata]
  )

  const loadAndStartPlayer = useCallback(
    ({ clipId, episodeId, seasonId, seriesId, channelId, mediaType, seekTo = null }) => {
      loadPlayer({ clipId, episodeId, seasonId, seriesId, channelId, mediaType })
      seekTo && setSeekToState({ mediaType, seekTo })
      return videoPlayerElementRef.current.play()
    },
    [loadPlayer]
  )

  const play = useCallback(() => {
    return videoPlayerElementRef.current.play()
  }, [])

  const pause = useCallback(() => {
    return videoPlayerElementRef.current.pause()
  }, [])

  const togglePlayPause = useCallback(() => {
    return videoPlayerElementRef.current.togglePlay()
  }, [])

  const loadAndStartOrTogglePlayPause = useCallback(
    ({ clipId, episodeId, seasonId, seriesId, channelId, mediaType, seekTo = null }) => {
      if (clipId && clipId === videoPlayerElementRef.current?.metadata?.id) {
        return togglePlayPause()
      }

      return loadAndStartPlayer({ clipId, episodeId, seasonId, seriesId, channelId, mediaType, seekTo })
    },
    [loadAndStartPlayer, togglePlayPause]
  )
  const loadAndStartOrTogglePlayFrom = useCallback(
    ({ clipId, episodeId, seasonId, seriesId, channelId, mediaType, seekTo = null }) => {
      if (clipId && clipId !== videoPlayerElementRef.current?.metadata?.id) {
        return loadAndStartPlayer({ clipId, episodeId, seasonId, seriesId, channelId, mediaType, seekTo })
      }
      if (isPlaying) {
        return pause()
      } else {
        play()
        // return !!seekTo && seekToByType({ seekTo, mediaType })
        seekTo && setSeekToState({ seekTo, mediaType })
      }
    },
    [isPlaying, loadAndStartPlayer, pause, play]
  )

  const loadAndStartOrPlayFrom = useCallback(
    ({ clipId, episodeId, seasonId, seriesId, channelId, mediaType, seekTo = null }) => {
      if (clipId && clipId === videoPlayerElementRef.current?.metadata?.id) {
        loadMetadata({ clipId, episodeId, seasonId, seriesId, channelId, mediaType })
        if (isPlaying) {
          seekTo && setSeekToState({ seekTo, mediaType })
          return
        } else {
          play()
          seekTo && setSeekToState({ seekTo, mediaType })
          return
        }
      }
      return loadAndStartPlayer({ clipId, episodeId, seasonId, seriesId, channelId, mediaType, seekTo })
    },
    [isPlaying, loadAndStartPlayer, loadMetadata, play]
  )

  const skipBack = useCallback((relativeTime = -15) => {
    videoPlayerElementRef.current.seekToRelativeTime(relativeTime)
  }, [])

  const skipForward = useCallback((relativeTime = 15) => {
    videoPlayerElementRef.current.seekToRelativeTime(relativeTime)
  }, [])

  const seekTo = useCallback(seekToSeconds => {
    if (!Number.isFinite(seekToSeconds)) {
      logger.warn('invalid seekToSecond', seekToSeconds)
      return
    }
    videoPlayerElementRef.current.seekTo(seekToSeconds)
  }, [])

  const seekToPosition = useCallback(position => {
    videoPlayerElementRef.current.seekToPosition(position)
  }, [])

  const getCurrentTimeLive = useCallback(() => {
    return videoPlayerElementRef.current?.activeSession?.getCurrentTimeLive?.()
  }, [])

  const positionToLiveTime = useCallback(position => {
    return videoPlayerElementRef.current?.activeSession?.positionToLiveTime?.(position)
  }, [])

  const liveTimeToPosition = useCallback(liveTime => {
    return videoPlayerElementRef.current?.activeSession?.liveTimeToPosition?.(liveTime)
  }, [])

  const setVolume = useCallback(volume => {
    videoPlayerElementRef.current.volume = volume
    setPersistedVolume(volume)
  }, [])

  const setPlaybackRate = useCallback(rate => {
    // videoPlayerElementRef.current.playbackRate = rate
    const $videoElement = videoPlayerElementRef.current.querySelector('video')
    if ($videoElement) {
      $videoElement.playbackRate = rate
    }
  }, [])

  const toggleMute = useCallback(() => {
    videoPlayerElementRef.current.toggleMute()
    setPersistedMuted(videoPlayerElementRef.current.mute)
  }, [])

  const setMuted = useCallback(mute => {
    videoPlayerElementRef.current.muted = mute
    setPersistedMuted(videoPlayerElementRef.current.mute)
  }, [])

  const getCurrentTime = useCallback(() => {
    return videoPlayerElementRef.current.currentTime
  }, [])

  const airplay = useCallback(() => {
    return videoPlayerElementRef.current.querySelector('video')?.webkitShowPlaybackTargetPicker()
  }, [])

  const toggleSubtitleVisibility = useCallback(() => {
    return videoPlayerElementRef.current.subtitleState?.toggleVisibility()
  }, [])

  const requestFullscreen = useCallback(() => {
    const $videoElement = videoPlayerElementRef.current.querySelector('video')

    if ($videoElement.requestFullscreen) {
      // W3C API
      return $videoElement.requestFullscreen()
    } else if ($videoElement.mozRequestFullscreen) {
      // Mozilla current API
      return $videoElement.mozRequestFullscreen()
    } else if ($videoElement.webkitRequestFullscreen) {
      // Webkit current API
      return $videoElement.webkitRequestFullscreen()
    } else if ($videoElement.webkitEnterFullscreen) {
      // IOS Mobile edge case
      return $videoElement.webkitEnterFullscreen()
    }
  }, [])

  const exitFullscreen = useCallback(() => {
    if (document.exitFullscreen) {
      return document.exitFullscreen()
    } else if (document.mozCancelFullscreen) {
      return document.mozCancelFullscreen()
    } else if (document.webkitExitFullscreen) {
      return document.webkitExitFullscreen()
    }
    return document.exitFullscreen()
  }, [])

  const requestPictureInPicture = useCallback(() => {
    return videoPlayerElementRef.current.querySelector('video')?.requestPictureInPicture()
  }, [])

  const exitPictureInPicture = useCallback(() => {
    return document.exitPictureInPicture()
  }, [])

  const value = useMemo(() => {
    return {
      $videoPlayerElement: videoPlayerElementRef.current,
      videoPlayerElementReady,
      isPlayerLoading: !videoPlayerElementReady || playerMetadataLoading,
      loadPlayer,
      loadAndStartPlayer,
      loadAndStartOrTogglePlayPause,
      loadAndStartOrPlayFrom,
      loadAndStartOrTogglePlayFrom,
      play,
      pause,
      togglePlayPause,
      skipBack,
      skipForward,
      seekTo,
      seekToPosition,
      setSeekToState,
      setVolume,
      toggleMute,
      setMuted,
      setPlaybackRate,
      getCurrentTimeLive,
      positionToLiveTime,
      liveTimeToPosition,
      getCurrentTime,
      airplay,
      requestFullscreen,
      exitFullscreen,
      requestPictureInPicture,
      exitPictureInPicture,
      toggleSubtitleVisibility
    }
  }, [
    videoPlayerElementReady,
    playerMetadataLoading,
    loadPlayer,
    loadAndStartPlayer,
    loadAndStartOrTogglePlayPause,
    loadAndStartOrPlayFrom,
    loadAndStartOrTogglePlayFrom,
    play,
    pause,
    togglePlayPause,
    skipBack,
    skipForward,
    seekTo,
    seekToPosition,
    setVolume,
    toggleMute,
    setMuted,
    setPlaybackRate,
    getCurrentTimeLive,
    positionToLiveTime,
    liveTimeToPosition,
    getCurrentTime,
    airplay,
    requestFullscreen,
    exitFullscreen,
    requestPictureInPicture,
    exitPictureInPicture,
    toggleSubtitleVisibility
  ])

  return <VideoPlayerElementContext.Provider value={value}>{children}</VideoPlayerElementContext.Provider>
}

VideoPlayerElementProvider.propTypes = {
  children: PropTypes.node
}

export const useVideoPlayerElement = () => {
  const context = useContext(VideoPlayerElementContext)
  return context
}

export const useIsVideoPlayerLoading = () => {
  const { isPlayerLoading } = useContext(VideoPlayerElementContext)
  return isPlayerLoading
}
