/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import Icon from '../Icon'
import Button from '../Button'

const IconButton = forwardRef(({ children, iconId, borderRadius = 'md', 'aria-label': ariaLabel, ...rest }, ref) => {
  // Remove some props before passing it to IconButton
  const { leftIcon, rightIcon, loadingText, ...props } = rest

  return (
    <Button px={2} borderRadius={borderRadius} ref={ref} aria-label={ariaLabel} {...props} lineHeight="0">
      {children}
      <Icon iconId={iconId} aria-hidden />
    </Button>
  )
})

IconButton.defaultProps = Button.defaultProps

export default IconButton
