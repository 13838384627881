import { LOGIN_HAS_ERRORED } from './actions'

const initialState = {
  loginHasFailed: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_HAS_ERRORED: {
      return { loginHasErrored: true }
    }
    default: {
      return state
    }
  }
}
