import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import logger from '../../logger'
import { del as deleteIdbKey } from 'idb-keyval'
import { IDB_KEY_SUBMISSION_PHONE_NUMBER } from '../../RadioUI/Submissions/helpers'

const AuthContext = createContext({
  sessionState: {},
  loginSession: {},
  login: () => {},
  logout: () => {},
  acceptForumTerms: () => {},
  getIdToken: async () => {},
  loginClient: {}
})

export const AuthContextProvider = ({ children }) => {
  const [hasLoginClient, setHasLoginClient] = useState()
  const [sessionState, setSessionState] = useState({})
  const [loginSession, setLoginSession] = useState({})
  const clientRef = useRef()
  const [idTokenExpires, setIdTokenExpires] = useState(0)

  const setIdTokenExpirey = token => {
    try {
      setIdTokenExpires(JSON.parse(atob(token.split('.')[1]))?.exp)
    } catch {
      setIdTokenExpires(0)
    }
  }

  useEffect(() => {
    if (!clientRef.current) {
      import('@nrk/innlogging-web/redirect-login-client').then(({ getRedirectLoginClient, LogLevel }) => {
        clientRef.current = getRedirectLoginClient({ logLevel: LogLevel.debug }, sessionData => {
          setSessionState(sessionData)
          setIdTokenExpirey(sessionData?.idToken)
          logger.info('sessionData', sessionData)
        })
        clientRef.current.initOpSession()
        setHasLoginClient(true)
        window.loginClient = clientRef.current
        logger.info('loginClient', clientRef.current)
      })
    }
  }, [])

  useEffect(() => {
    if (hasLoginClient) {
      const onUpdate = event => {
        setSessionState(event.detail)
        setIdTokenExpirey(event.detail?.idToken)
        logger.info('Session state from update', event.detail)
      }
      clientRef.current.addEventListener('update', onUpdate)
      return () => {
        clientRef.current.removeEventListener('update', onUpdate)
      }
    }
  }, [hasLoginClient])

  useEffect(() => {
    if (hasLoginClient) {
      clientRef.current.getSession().then(session => {
        setLoginSession(session)
        window.loginSession = session
        logger.info('Session state from login client', session)
      })
    }
  }, [hasLoginClient])

  useEffect(() => {
    if (loginSession.isExpired) {
      logger.error('Login session is expired. Something is wrong. Not able to use auth')
      logger.debug('loginSession', loginSession)
    }
  }, [loginSession])

  const value = useMemo(() => {
    return {
      sessionState,
      loginSession,
      login: () => clientRef.current?.login?.(),
      logout: () => {
        deleteIdbKey(IDB_KEY_SUBMISSION_PHONE_NUMBER)
        clientRef.current?.logout?.()
      },
      acceptForumTerms: () => {
        clientRef.current?.ensureUserConformsToRequirements({ requirements: ['forum'] })
      },
      getIdToken: async () => {
        if (Date.now() / 1000 > idTokenExpires) {
          logger.info('force refreshing tokens')
          const LoginSession = await clientRef.current?.forceRefresh()
          return LoginSession?.idToken
        }
        return sessionState?.idToken
      },
      loginClient: hasLoginClient ? clientRef.current : null
    }
  }, [hasLoginClient, loginSession, sessionState, idTokenExpires])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthContextProvider.propTypes = {
  children: PropTypes.node
}

export const useAuthContext = () => {
  const authContext = useContext(AuthContext)
  return authContext
}

export const useLoginSession = () => {
  const { loginSession } = useContext(AuthContext)
  return loginSession
}

export const useIsLoggedIn = () => {
  const { loginSession } = useContext(AuthContext)
  return loginSession.isLoggedIn
}

export const useSub = () => {
  const { loginSession } = useContext(AuthContext)
  return loginSession.user?.sub
}

export const useHasAcceptedSubmissionTerms = () => {
  const { loginSession } = useContext(AuthContext)
  return loginSession.user?.claims?.['nrk/consent/forum'] === '1'
}
