import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'
import {
  fetchProgressListForUser,
  fetchProgramProgressById,
  saveProgramProgress
} from '../../apiClients/personalisation'

export const PreviouslyHeardContext = createContext({
  previouslyHeard: [],
  setPreviouslyHeard: () => {},
  fetchProgress: () => {},
  fetchProgramProgress: () => {},
  saveProgress: () => {}
})

export const PreviouslyHeardProvider = ({ children }) => {
  const [previouslyHeard, setPreviouslyHeard] = useState([])

  const fetchProgramProgress = (contentId, progressContentType) => {
    return fetchProgramProgressById({ contentId, progressContentType }).then(res => {
      return res
    })
  }

  const saveProgress = (url, position, startPlayBackPosition) => {
    return saveProgramProgress(url, position, startPlayBackPosition).then(res => {
      return res
    })
  }

  const fetchProgress = () => {
    fetchProgressListForUser().then(res => {
      const dates = [...new Set(res.progresses.map(progress => progress.registeredAt.split('T')[0]))]
      setPreviouslyHeard(
        dates.map(date => {
          const list = res.progresses.filter(prog => prog.registeredAt.split('T')[0] === date)
          return { date, list }
        })
      )
    })
  }

  return (
    <PreviouslyHeardContext.Provider
      value={{ previouslyHeard, setPreviouslyHeard, fetchProgress, fetchProgramProgress, saveProgress }}
    >
      {children}
    </PreviouslyHeardContext.Provider>
  )
}

PreviouslyHeardProvider.propTypes = {
  children: PropTypes.node
}

export default PreviouslyHeardProvider
