import React, { Component } from 'react'
import PropTypes from 'prop-types'
import logger from '../../logger'
import ErrorMessage from './ErrorMessage'

class LoadableComponentErrorBoundry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    logger.error(error)
    logger.error(info.componentStack)
  }

  render() {
    const { showMessageOnError = false, ErrorMessageComponent = ErrorMessage } = this.props
    if (this.state.hasError) {
      return showMessageOnError ? <ErrorMessageComponent /> : null
    }
    return this.props.children
  }
}

LoadableComponentErrorBoundry.propTypes = {
  children: PropTypes.node,
  showMessageOnError: PropTypes.bool,
  onErrorClick: PropTypes.func,
  ErrorMessageComponent: PropTypes.elementType
}

export default LoadableComponentErrorBoundry
