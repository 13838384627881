export const liveChannels = [
  {
    id: 'p1',
    title: 'NRK P1',
    isLive: true,
    isDistrictChannel: false,
    hasSuggestions: true
  },
  {
    id: 'p1pluss',
    title: 'NRK P1+',
    isLive: true,
    isDistrictChannel: false,
    hasSuggestions: true
  },
  {
    id: 'p2',
    title: 'NRK P2',
    isLive: true,
    isDistrictChannel: false,
    hasSuggestions: true
  },
  {
    id: 'p3',
    title: 'NRK P3',
    isLive: true,
    isDistrictChannel: false,
    hasSuggestions: true
  },
  {
    id: 'p13',
    title: 'NRK P13',
    isLive: true,
    isDistrictChannel: false,
    hasSuggestions: true
  },
  {
    id: 'mp3',
    title: 'NRK mP3',
    isLive: true,
    isDistrictChannel: false,
    hasSuggestions: true
  },
  {
    id: 'alltid_nyheter',
    title: 'NRK Nyheter',
    isLive: true,
    isDistrictChannel: false,
    hasSuggestions: false
  },
  {
    id: 'radio_super',
    title: 'NRK Radio Super',
    isLive: true,
    isDistrictChannel: false,
    hasSuggestions: false
  },
  {
    id: 'klassisk',
    title: 'NRK Klassisk',
    isLive: true,
    isDistrictChannel: false,
    hasSuggestions: false
  },
  {
    id: 'sapmi_fm',
    title: 'NRK Sápmi FM',
    isLive: false,
    isDistrictChannel: false,
    hasSuggestions: false
  },
  {
    id: 'sapmi',
    title: 'NRK Sápmi',
    isLive: true,
    isDistrictChannel: false,
    hasSuggestions: false
  },
  {
    id: 'jazz',
    title: 'NRK Jazz',
    isLive: true,
    isDistrictChannel: false,
    hasSuggestions: false
  },
  {
    id: 'folkemusikk',
    title: 'NRK Folkemusikk',
    isLive: true,
    isDistrictChannel: false,
    hasSuggestions: false
  },
  {
    id: 'sport',
    title: 'NRK Sport',
    isLive: true,
    isDistrictChannel: false,
    hasSuggestions: false
  },
  {
    id: 'urort',
    title: 'NRK Urørt',
    isLive: true,
    isDistrictChannel: false,
    hasSuggestions: false
  },
  {
    id: 'radioresepsjonen',
    title: 'NRK Radioresepsjonen',
    isLive: true,
    isDistrictChannel: false,
    hasSuggestions: false
  },
  {
    id: 'p3x',
    title: 'NRK P3X',
    isLive: false,
    isDistrictChannel: false,
    hasSuggestions: false
  },
  {
    id: 'national_rap_show',
    title: 'NRK National Rap Show',
    isLive: false,
    isDistrictChannel: false,
    hasSuggestions: false
  },
  {
    id: 'p1_buskerud',
    title: 'NRK P1 Buskerud',
    isLive: true,
    isDistrictChannel: true,
    parentChannel: 'p1',
    hasSuggestions: false
  },
  {
    id: 'p1_finnmark',
    title: 'NRK P1 Finnmark',
    isLive: true,
    isDistrictChannel: true,
    parentChannel: 'p1',
    hasSuggestions: false
  },
  // {
  //   id: 'p1_hedmark_oppland',
  //   title: 'NRK P1 Hedmark og Oppland',
  //   isLive: true,
  //   isDistrictChannel: true,
  //   parentChannel: 'p1',
  //   hasSuggestions: false
  // },
  {
    id: 'p1_hordaland',
    title: 'NRK P1 Hordaland',
    isLive: true,
    isDistrictChannel: true,
    parentChannel: 'p1',
    hasSuggestions: false
  },
  {
    id: 'p1_innlandet',
    title: 'NRK P1 Innlandet',
    isLive: true,
    isDistrictChannel: true,
    parentChannel: 'p1',
    hasSuggestions: false
  },
  {
    id: 'p1_more_romsdal',
    title: 'NRK P1 Møre og Romsdal',
    isLive: true,
    isDistrictChannel: true,
    parentChannel: 'p1',
    hasSuggestions: false
  },
  {
    id: 'p1_nordland',
    title: 'NRK P1 Nordland',
    isLive: true,
    isDistrictChannel: true,
    parentChannel: 'p1',
    hasSuggestions: false
  },
  {
    id: 'p1_oslo_akershus',
    title: 'NRK P1 Stor-Oslo',
    isLive: true,
    isDistrictChannel: true,
    parentChannel: 'p1',
    hasSuggestions: false
  },
  {
    id: 'p1_rogaland',
    title: 'NRK P1 Rogaland',
    isLive: true,
    isDistrictChannel: true,
    parentChannel: 'p1',
    hasSuggestions: false
  },
  {
    id: 'p1_sogn_fjordane',
    title: 'NRK P1 Sogn og Fjordane',
    isLive: true,
    isDistrictChannel: true,
    parentChannel: 'p1',
    hasSuggestions: false
  },
  {
    id: 'p1_sorlandet',
    title: 'NRK P1 Sørlandet',
    isLive: true,
    isDistrictChannel: true,
    parentChannel: 'p1',
    hasSuggestions: false
  },
  {
    id: 'p1_telemark',
    title: 'NRK P1 Telemark',
    isLive: true,
    isDistrictChannel: true,
    parentChannel: 'p1',
    hasSuggestions: false
  },
  {
    id: 'p1_troms',
    title: 'NRK P1 Troms',
    isLive: true,
    isDistrictChannel: true,
    parentChannel: 'p1',
    hasSuggestions: false
  },
  {
    id: 'p1_trondelag',
    title: 'NRK P1 Trøndelag',
    isLive: true,
    isDistrictChannel: true,
    parentChannel: 'p1',
    hasSuggestions: false
  },
  {
    id: 'p1_vestfold',
    title: 'NRK P1 Vestfold',
    isLive: true,
    isDistrictChannel: true,
    parentChannel: 'p1',
    hasSuggestions: false
  },
  {
    id: 'p1_ostfold',
    title: 'NRK P1 Østfold',
    isLive: true,
    isDistrictChannel: true,
    parentChannel: 'p1',
    hasSuggestions: false
  }
]

export const liveChannelsNonLocal = liveChannels.filter(({ isDistrictChannel }) => !isDistrictChannel)

export const liveChannelsLocal = liveChannels.filter(({ isDistrictChannel }) => isDistrictChannel)

export const channelIds = liveChannels.map(({ id }) => id)

export function channelIdToChannelName(channelId) {
  const liveChannel = liveChannels.find(({ id }) => id === channelId)
  return liveChannel ? liveChannel.title : ''
}

export function getTopLevelId(channelId) {
  const channel = liveChannels.find(({ id }) => id === channelId) || {}
  return channel.parentChannel || channel.id
}

export function hasSuggestions(channelId) {
  const channel = liveChannels.find(({ id }) => id === channelId) || {}
  return !!channel.hasSuggestions
}

export function getInitialChannels(districtChannelId) {
  const initialChannels = liveChannels.filter(({ id, isDistrictChannel, isLive }) => {
    return (isLive && !isDistrictChannel) || districtChannelId === id
  })
  return initialChannels
}
