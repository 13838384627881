import PropTypes from 'prop-types'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Flex from '../RadioUI/Flex'
import NavItem from '../RadioUI/NavItem'
import routes, { ROUTE_TYPES } from '../routes'
import Home from './Home'
import PseudoBox from '../RadioUI/PseudoBox'
import { linkStyle } from '../RadioUI/theme/link'
import TooltipLiveGuide from '../components/TooltipRadioGuide'
import { css } from '@emotion/react'
import { useColors } from '../RadioUI/theme/Color'

const navRoutes = routes.filter(({ type }) => type === ROUTE_TYPES.NAV)
const noop = () => {}

const Menu = ({ location }) => {
  const colors = useColors()
  return (
    <Flex
      as="nav"
      py={4}
      justify={{ base: 'center', lg: 'flex-start' }}
      align="center"
      fontSmoothing="antialiased"
      css={css`
        & > *:not(:last-child) {
          margin-right: 1rem;
        }
      `}
    >
      <Home />
      {navRoutes.map(({ path, linkText, MenuItemComponent, testId, iconId, nofollow, onMenuClick = noop }) => {
        const isCurrentPage = location.pathname.startsWith(path)
        const ariaCurrentPage = isCurrentPage ? { 'aria-current': 'page' } : {}
        const activeClassname = isCurrentPage ? 'active' : ''
        const rel = nofollow ? { rel: 'nofollow' } : {}
        if (MenuItemComponent) {
          return (
            <MenuItemComponent
              onClick={event => onMenuClick(event, location)}
              key={path}
              path={path}
              className={activeClassname}
              {...rel}
              {...ariaCurrentPage}
            />
          )
        }
        return (
          <PseudoBox key={path} pos="relative">
            <Link
              key={path}
              className={`nav-link ${activeClassname}`}
              to={path}
              onClick={event => onMenuClick(event, location)}
              data-test={`menu-button-${testId}`}
              css={linkStyle(colors.main)}
              {...rel}
              {...ariaCurrentPage}
            >
              <NavItem iconId={iconId} text={linkText} />
            </Link>
            {location.pathname === '/' && path === '/direkte' && (
              <TooltipLiveGuide
                key={`${path}-tooltip`}
                header="Hør direkte"
                text="Her finner du direktekanalene og radioguiden"
                arrowDir="bottom"
                toPath={path}
                minW="205px"
                localStorageKey="hasSeenTooltipFrontpage2"
                localStorageKeyTimeSince="timeSinceFirstTooltipFrontpage2"
              />
            )}
          </PseudoBox>
        )
      })}
    </Flex>
  )
}

Menu.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired
}

export default withRouter(Menu)
