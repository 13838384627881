/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react'
import Box from '../Box'
import CoreIcon from '../../components/CoreIcons'

const Icon = ({ iconId, color = 'currentColor', size = 'inherit', ...rest }) => {
  return (
    <Box as="span" d="inline-block" color={color} fontSize={size} {...rest}>
      <CoreIcon id={iconId} />
    </Box>
  )
}

export default Icon
