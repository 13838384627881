/* eslint-disable react/prop-types */
import React from 'react'
import Flex from '../Flex'
import Icon from '../Icon'
import Text from '../Text'

const NavItem = ({ iconId, text, ...rest }) => {
  return (
    <Flex direction={{ base: 'column', md: 'row' }} align="center" p={{ base: 2, md: 4 }} {...rest}>
      <Icon size="16px" mr={{ base: 0, md: 2 }} mb={{ base: 2, md: 0 }} iconId={iconId} />
      <Text size={{ base: 'sm', lg: 'lg' }} weight="semibold" align={{ base: 'center', md: 'left' }}>
        {text}
      </Text>
    </Flex>
  )
}

export default NavItem
