import _get from 'lodash/get'

import {
  SERIES_SUBMISSION_SETTINGS_LOADING,
  SERIES_SUBMISSION_SETTINGS_LOADED,
  SERIES_SUBMISSION_SETTINGS_FAILED,
  SERIES_LOADING,
  SERIES_LOADED,
  SERIES_FAILED,
  SERIES_CLEARED,
  SEASON_LOADING,
  SEASON_LOADED,
  SEASON_FAILED,
  SEASON_CLEARED,
  EPISODE_LOADED,
  EPISODE_LOADING,
  EPISODE_FAILED,
  EPISODE_CLEARED,
  SORT_TOGGLE,
  SEASON_EPISODES_REPLACE_LOADING,
  SEASON_EPISODES_REPLACE_LOADED,
  SEASON_EPISODES_REPLACE_FAILED,
  SEASON_PAGE_LOADING,
  SEASON_PAGE_LOADED,
  SEASON_PAGE_FAILED,
  LATEST_EPISODES_PAGE_LOADING,
  LATEST_EPISODES_PAGE_LOADED,
  LATEST_EPISODES_PAGE_FAILED,
  LATEST_EPISODES_REPLACE_LOADING,
  LATEST_EPISODES_REPLACE_LOADED,
  LATEST_EPISODES_REPLACE_FAILED,
  SET_EPISODE_LOADING
} from './actions'
import {
  EXTRAMATERIAL_CLIPS_LOADING,
  EXTRAMATERIAL_CLIPS_LOADED,
  EXTRAMATERIAL_CLIPS_FAILED,
  EXTRAMATERIAL_CLIPS_CLEARED,
  EXTRAMATERIAL_CLIPS_PAGE_LOADING,
  EXTRAMATERIAL_CLIPS_PAGE_LOADED,
  EXTRAMATERIAL_CLIPS_PAGE_FAILED,
  FALLBACK_CLIP_LOADING,
  FALLBACK_CLIP_LOADED,
  FALLBACK_CLIP_FAILED
} from './ExtraMaterialPage/extraMaterialActions'
import latestEpisodesFromSeries from './helpers/latestEpisodesFromSeries'
import SORTS from './helpers/sorts'
import { PREFERRED_SERIES_TOAST_VIEWED } from '../../common/preferredSeries'

const SERIES_TYPES = {
  SEQUENTIAL: 'sequential',
  UMBRELLA: 'umbrella',
  STANDARD: 'standard'
}

const initialState = {
  type: 'series',
  loading: false,
  series: { sort: null },
  seasons: {},
  season: { loading: false, _embedded: { episodes: {} } },
  latestEpisodes: { loading: false, latestEpisodesPageLoading: false },
  firstOfNextPage: null,
  episode: { loading: false },
  error: null,
  extraMaterial: { _embedded: { metadata: [] }, fallbackClip: { loading: false } },
  preferredSeriesToastViewed: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PREFERRED_SERIES_TOAST_VIEWED: {
      return {
        ...state,
        preferredSeriesToastViewed: true
      }
    }
    case SERIES_SUBMISSION_SETTINGS_LOADING:
    case SERIES_SUBMISSION_SETTINGS_LOADED: {
      return {
        ...state,
        submissions: { ...action.payload, loading: action.loading }
      }
    }
    case SERIES_SUBMISSION_SETTINGS_FAILED: {
      return {
        ...state,
        submissions: { loading: action.loading, error: action.error.toString() }
      }
    }

    case SERIES_LOADING:
    case SERIES_LOADED: {
      const seasons = seasonsFromSeries(action.payload)
      const latestEpisodes = latestEpisodesFromSeries(action.payload) // TODO: Consider not duplicating state and / or consolidating season and latestEpisodes
      const contentType = contentTypeFromType(action.payload.type)
      return {
        ...state,
        firstOfNextPage: null,
        loading: action.loading,
        ...action.payload,
        contentType,
        seasons,
        latestEpisodes: { ...latestEpisodes, loading: false }
      }
    }
    case SERIES_FAILED: {
      return { ...state, loading: action.loading, error: action.error.toString() }
    }
    case SERIES_CLEARED: {
      return { ...initialState }
    }

    case SEASON_LOADING:
    case SEASON_LOADED: {
      const contentType = contentTypeFromType(action.payload.type)
      return {
        ...state,
        firstOfNextPage: null,
        contentType,
        season: { ...action.payload, loading: action.loading }
      }
    }
    case SEASON_FAILED: {
      return { ...state, season: { ...state.season, loading: action.loading, error: action.error.toString() } }
    }
    case SEASON_CLEARED: {
      return { ...state, season: initialState.season }
    }
    case LATEST_EPISODES_REPLACE_LOADING: {
      return { ...state, firstOfNextPage: null, latestEpisodes: { loading: action.loading } }
    }
    case LATEST_EPISODES_REPLACE_LOADED: {
      const episodes = _get(action, 'payload._embedded.episodes', [])
      const _links = _get(action, 'payload._links', {})
      return { ...state, latestEpisodes: { loading: action.loading, episodes, _links } }
    }
    case LATEST_EPISODES_REPLACE_FAILED: {
      return { ...state, latestEpisodes: { loading: action.loading, error: action.error.toString() } }
    }
    case SEASON_EPISODES_REPLACE_LOADING: {
      return { ...state, firstOfNextPage: null, season: { ...state.season, episodesLoading: action.loading } }
    }
    case SEASON_EPISODES_REPLACE_LOADED: {
      return {
        ...state,
        season: { ...state.season, episodesLoading: action.loading, _embedded: { episodes: action.payload } }
      }
    }
    case SEASON_EPISODES_REPLACE_FAILED: {
      return {
        ...state,
        season: {
          ...state.season,
          episodesLoading: action.loading,
          _embedded: { episodes: { error: action.error.toString() } }
        }
      }
    }
    case SEASON_PAGE_LOADING: {
      return { ...state, season: { ...state.season, seasonPageLoading: action.loading } }
    }
    case SEASON_PAGE_LOADED: {
      const { _embedded, ...seasonPageRest } = action.payload
      const currentEpisodes = _get(state, 'season._embedded.episodes._embedded.episodes', [])
      const episodesPage = _get(_embedded, 'episodes', [])
      const firstOfNextPage = _get(episodesPage, '[0].episodeId', null)

      return {
        ...state,
        firstOfNextPage,
        season: {
          ...state.season,
          seasonPageLoading: action.loading,
          _embedded: { episodes: { ...seasonPageRest, _embedded: { episodes: [...currentEpisodes, ...episodesPage] } } }
        }
      }
    }
    case SEASON_PAGE_FAILED: {
      return { ...state, season: { ...state.season, seasonPageLoading: action.loading } }
    }
    case LATEST_EPISODES_PAGE_LOADING: {
      return { ...state, latestEpisodes: { ...state.latestEpisodes, latestEpisodesPageLoading: action.loading } }
    }
    case LATEST_EPISODES_PAGE_LOADED: {
      const episodesPage = _get(action, 'payload._embedded.episodes', [])
      const firstOfNextPage = _get(episodesPage, '[0].episodeId', null)
      const _links = _get(action, 'payload._links', {})
      return {
        ...state,
        firstOfNextPage,
        latestEpisodes: {
          ...state.latestEpisodes,
          episodes: [...state.latestEpisodes.episodes, ...episodesPage],
          _links,
          latestEpisodesPageLoading: action.loading
        }
      }
    }
    case LATEST_EPISODES_PAGE_FAILED: {
      return { ...state, latestEpisodes: { ...state.latestEpisodes, latestEpisodesPageLoading: action.loading } }
    }
    case EPISODE_LOADING:
    case EPISODE_LOADED: {
      return { ...state, episode: { loading: action.loading, ...action.payload } }
    }
    case EPISODE_FAILED: {
      return { ...state, episode: { loading: action.loading, error: action.error.toString() } }
    }
    case EPISODE_CLEARED: {
      return { ...state, episode: initialState.episode }
    }
    case SET_EPISODE_LOADING: {
      return { ...state, episode: { ...state.episode, loading: action.loading } }
    }
    case SORT_TOGGLE: {
      if (state.seriesType === SERIES_TYPES.STANDARD) {
        return { ...state, series: { ...state.series, sort: state.series.sort === SORTS.ASC ? SORTS.DESC : SORTS.ASC } }
      }
      return state
    }
    case EXTRAMATERIAL_CLIPS_CLEARED:
      return { ...state, extraMaterial: initialState.extraMaterial }
    case EXTRAMATERIAL_CLIPS_LOADING:
    case EXTRAMATERIAL_CLIPS_LOADED: {
      return {
        ...state,
        extraMaterial: {
          ...initialState.extraMaterial,
          ...action.payload,
          error: null,
          loading: action.loading
        }
      }
    }
    case EXTRAMATERIAL_CLIPS_FAILED: {
      return {
        ...state,
        extraMaterial: {
          ...initialState.extraMaterial,
          loading: action.loading,
          error: action.error.toString(),
          ...action.payload
        }
      }
    }
    case FALLBACK_CLIP_LOADING: {
      return { ...state, extraMaterial: { ...state.extraMaterial, fallbackClip: { loading: action.loading } } }
    }
    case FALLBACK_CLIP_LOADED: {
      return {
        ...state,
        extraMaterial: { ...state.extraMaterial, fallbackClip: { loading: action.loading, ...action.payload } }
      }
    }
    case FALLBACK_CLIP_FAILED: {
      return {
        ...state,
        extraMaterial: {
          ...state.extraMaterial,
          fallbackClip: { loading: action.loading, error: action.error.toString() }
        }
      }
    }
    case EXTRAMATERIAL_CLIPS_PAGE_LOADING: {
      return {
        ...state,
        extraMaterial: { ...state.extraMaterial, pageLoading: action.loading }
      }
    }
    case EXTRAMATERIAL_CLIPS_PAGE_LOADED: {
      return {
        ...state,
        extraMaterial: {
          ...state.extraMaterial,
          pageLoading: action.loading,
          _embedded: {
            metadata: [...state.extraMaterial._embedded.metadata, ...action.payload._embedded.metadata]
          },
          _links: { ...state.extraMaterial._links, next: action.payload._links.next }
        }
      }
    }
    case EXTRAMATERIAL_CLIPS_PAGE_FAILED: {
      return {
        ...state,
        extraMaterial: { ...state.extraMaterial, pageLoading: action.loading, pageError: action.error.toString() }
      }
    }
    default: {
      return state
    }
  }
}

export default reducer

function seasonsFromSeries(series) {
  const seasons = _get(series, '_links.seasons', [])
  return { seasons }
}

function contentTypeFromType(type) {
  switch (type) {
    case 'series':
      return 'onDemand'
    case 'podcast':
      return 'podcast'
    default:
      return null
  }
}
