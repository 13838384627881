import _get from 'lodash/get'
import _set from 'lodash/set'
import clientConfig from './clientConfig'
import logger from './logger'
import {
  newTracker,
  trackPageView as trackSnowplowPageView,
  addGlobalContexts,
  trackSelfDescribingEvent as trackSnowplowSelfDescribingEvent
  // trackStructEvent
} from '@snowplow/browser-tracker'

const noop = () => {}

const isServerEnv = ['stage', 'test', 'preprod', 'prod'].includes(clientConfig.SERVER_ENV)

const stubbedAppinsights = {
  downloadAndSetup: noop,
  queue: { push: noop },
  context: {
    addTelemetryInitializer: noop
  },
  trackPageView: noop,
  trackException: noop
}

let appInsights = stubbedAppinsights

const exceptionLoggingFilterTypes = [
  `uncaught exception: out of memory`,
  `Ikke nok minne`,
  `Out of memory`,
  `null is not an object (evaluating 'document.getElementById('sfMain').addEventListener')`, // iPad app causes this
  `TypeError: null is not an object (evaluating 'document.getElementById('sfMain').addEventListener')`, // iPad app causes this
  `undefined is not an object (evaluating 'document.querySelectorAll('.ludo-player')[0].addEventListener')`, // iPad app causes this
  `TypeError: undefined is not an object (evaluating 'document.querySelectorAll('.ludo-player')[0].addEventListener')`, // iPad app causes this
  `undefined is not an object (evaluating 'document.querySelectorAll('.ludo-player')[0].currentTime')`, // iPad app causes this
  `Objektet støtter ikke egenskapen eller metoden bringToFront`, // Ludo
  `Object doesn't support property or method 'bringToFront'`, // Ludo
  `Script error.`, // Not helpful
  `Unspecified error.`, // Not helpful,
  `Object doesn't support property or method 'getDuration'`, // Ludo
  `Objektet støtter ikke egenskapen eller metoden getDuration`, // Ludo
  `e.el.getDuration is not a function` // Ludo
]

const exceptionsFilterInitializer = envelope => {
  const baseType = _get(envelope, 'data.baseType')
  if (baseType === 'ExceptionData') {
    const exceptions = _get(envelope, 'data.baseData.exceptions', [])
    const filteredExceptions = exceptions.filter(exception => !exceptionLoggingFilterTypes.includes(exception.message))

    if (Array.isArray(filteredExceptions) && filteredExceptions.length > 0) {
      return _set(envelope, 'data.baseData.exceptions', filteredExceptions)
    }
    return false
  }

  return true
}

const remoteDependencyFilterInitializer = envelope => {
  const baseType = _get(envelope, 'data.baseType')
  if (baseType === 'RemoteDependencyData') {
    const target = _get(envelope, 'data.baseData.target')
    return /^(radio|psapi)(-stage|-test|-preprod|-staging)?\.nrk\.no/.test(target)
  }
  return true
}

const customDimensionsInitializer = envelope => {
  const telemetryItem = envelope.data.baseData
  telemetryItem.properties = {
    ...telemetryItem.properties,
    applicationName: clientConfig.APPLICATION_NAME,
    applicationVersion: clientConfig.RELEASE_NUMBER,
    appsettingWebSiteName: clientConfig.APPSETTING_WEBSITE_SITE_NAME,
    client: true
  }
}

async function initAppInsights() {
  try {
    const { AppInsights } = await import('applicationinsights-js')
    AppInsights.downloadAndSetup({
      instrumentationKey: clientConfig.APPINSIGHTS_INSTRUMENTATIONKEY
    })

    AppInsights.queue.push(() => {
      AppInsights.context.addTelemetryInitializer(exceptionsFilterInitializer)
      AppInsights.context.addTelemetryInitializer(remoteDependencyFilterInitializer)
      AppInsights.context.addTelemetryInitializer(customDimensionsInitializer)
    })

    appInsights = AppInsights
  } catch (error) {
    logger.error('Failed importing and initing AppInsights', error)
  }
}

function initSnowplow() {
  if (!clientConfig.SNOWPLOW_COLLECTOR_ENDPOINT) {
    logger.warn('Missinng snowplow collector endpoint')
    return
  }
  try {
    newTracker('radio-web', clientConfig.SNOWPLOW_COLLECTOR_ENDPOINT, {
      appId: 'no.nrk.radio.web',
      postPath: '/nrk/wd6',
      cookieSameSite: 'Lax', // Recommended
      stateStorageStrategy: 'cookieAndLocalStorage',
      eventMethod: 'beacon',
      plugins: []
    })
    const service = {
      schema: 'iglu:no.nrk/service/jsonschema/1-0-0',
      data: { id: 'nrkradio' }
    }
    addGlobalContexts([service])
  } catch (error) {
    logger.error('Error in initSnowplow', error)
  }
}

export const init = () => {
  if (process.browser && isServerEnv) {
    initAppInsights()
    initSnowplow()
  }
}

export const trackPageView = ({ pathname, search, ...rest }) => {
  appInsights.trackPageView(pathname)
  trackSnowplowPageView()
}

export const trackException = error => {
  appInsights.trackException(error)
}

const EntitySchema = {
  ClientID: 'iglu:no.nrk.innlogging/client-id/jsonschema/1-0-0',
  Content: 'iglu:no.nrk/content/jsonschema/1-0-1',
  Experiment: 'iglu:no.nrk/experiment/jsonschema/1-0-0',
  Link: 'iglu:no.nrk/link/jsonschema/2-0-0',
  NrkSession: 'iglu:no.nrk/nrk-session/jsonschema/1-0-0',
  NrkUser: 'iglu:no.nrk/nrk-user/jsonschema/1-0-0',
  Plug: 'iglu:no.nrk/plug/jsonschema/3-0-0',
  PreviousWebPage: 'iglu:no.nrk/previous-web-page/jsonschema/1-0-0',
  RegistrationContext: 'iglu:no.nrk.innlogging/registration-context/jsonschema/1-0-0',
  SearchResult: 'iglu:no.nrk/search-result/jsonschema/1-0-1',
  Service: 'iglu:no.nrk/service/jsonschema/2-0-0',
  Application: 'iglu:com.snowplowanalytics.mobile/application/jsonschema/1-0-0'
}

const EventSchema = {
  ClickEvent: 'iglu:no.nrk/click-event/jsonschema/1-0-0',
  ImpressionEvent: 'iglu:no.nrk/impression-event/jsonschema/1-0-0',
  RadioEvent: 'iglu:no.nrk.radio/radio-event/jsonschema/1-0-0'
}

export const addUserToSnowplowContext = userId => {
  addGlobalContexts([
    {
      schema: EntitySchema.NrkUser,
      data: {
        id: userId
      }
    }
  ])
}

export const trackSelfDescribingEvent = (schema, data, context) => {
  if (!schema) {
    logger.warn('Missing schema in event')
    return
  } else if (!data) {
    logger.warn('Missing data in event')
    return
  }
  const event = {
    event: {
      schema,
      data
    },
    context
  }
  trackSnowplowSelfDescribingEvent(event)
}

const ImpressionEventKind = {
  Impression: 'impression'
}

export const trackImpression = ({ snowplowPlugEntity, snowplowContentEntity }) => {
  trackSelfDescribingEvent(EventSchema.ImpressionEvent, { kind: ImpressionEventKind.Impression }, [
    {
      schema: EntitySchema.Plug,
      data: snowplowPlugEntity
    },
    { schema: EntitySchema.Content, data: snowplowContentEntity }
  ])
}

export const trackImpressions = ({ snowplowPlugEntities = [], snowplowContentEntity }) => {
  const plugs = snowplowPlugEntities.map(snowplowPlugEntity => {
    return { schema: EntitySchema.Plug, data: snowplowPlugEntity }
  })

  trackSelfDescribingEvent(EventSchema.ImpressionEvent, { kind: ImpressionEventKind.Impression }, [
    ...plugs,
    { schema: EntitySchema.Content, data: snowplowContentEntity }
  ])
}

const ClickEventKind = {
  Content: 'content',
  Other: 'other'
}

export const trackClick = ({ snowplowPlugEntity, snowplowContentEntity }) => {
  trackSelfDescribingEvent(EventSchema.ClickEvent, { kind: ClickEventKind.Content }, [
    { schema: EntitySchema.Plug, data: snowplowPlugEntity },
    { schema: EntitySchema.Content, data: snowplowContentEntity }
  ])
}

export const trackSnowplowEvent = (category, action, label, interactionType) => {
  trackSelfDescribingEvent(EventSchema.RadioEvent, {
    category,
    action,
    label,
    interactionType
  })
}

export const spCategory = {
  ProfilePage: 'ProfilePage',
  NewEpisodesPage: 'NewEpisodesPage',
  PreviouslyHeardPage: 'PreviouslyHeardPage',
  SeriesPage: 'SeriesPage',
  Radioguide: 'Radioguide',
  QueuePage: 'QueuePage',
  Player: 'Player',
  FullscreenPlayer: 'FullscreenPlayer',
  Frontpage: 'Frontpage',
  CategoryPage: 'CategoryPage',
  SubmissionPage: 'SubmissionPage'
}
