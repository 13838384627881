import {
  fetchProgramProgressById,
  saveProgramProgress,
  fetchProgramProgressByHref
} from '../../apiClients/personalisation'
import logger from '../../logger'
import { durationToMilliseconds } from '../../common/parseIsoDuration'

export const PROGRAM_PROGRESS_RESET = 'PROGRAM_PROGRESS_RESET'
export const PROGRAM_PROGRESS_LOADING = 'PROGRAM_PROGRESS_LOADING'
export const PROGRAM_PROGRESS_LOADED = 'PROGRAM_PROGRESS_LOADED'
export const PROGRAM_PROGRESS_FAILED = 'PROGRAM_PROGRESS_FAILED'

export const PROGRAM_PROGRESS_SAVING = 'PROGRAM_PROGRESS_SAVING'
export const PROGRAM_PROGRESS_SAVED = 'PROGRAM_PROGRESS_SAVED'
export const PROGRAM_PROGRESS_SAVE_FAILED = 'PROGRAM_PROGRESS_SAVE_FAILED'

export const PROGRAMS_PROGRESS_LIST_RESET = 'PROGRAMS_PROGRESS_LIST_RESET'

export const PROGRAMS_PROGRESS_LIST_LOADING = 'PROGRAMS_PROGRESS_LIST_LOADING'
export const PROGRAMS_PROGRESS_LIST_LOADED = 'PROGRAMS_PROGRESS_LIST_LOADED'
export const PROGRAMS_PROGRESS_LIST_FAILED = 'PROGRAMS_PROGRESS_LIST_FAILED'
export const PROGRAMS_PROGRESS_LIST_CLEARED = 'PROGRAMS_PROGRESS_LIST_CLEARED'

const noopAbortController = { abort: () => {} }

const fallbackNotBefore = 'PT5S'
const fallbackInterval = 'PT5S'

const fallbackReportInterval = {
  notBefore: fallbackNotBefore,
  notBeforeMs: durationToMilliseconds(fallbackNotBefore),
  interval: fallbackInterval,
  intervalMs: durationToMilliseconds(fallbackInterval)
}

export function programProgressReset() {
  return {
    type: PROGRAM_PROGRESS_RESET
  }
}

function programProgressStarted(payload) {
  return {
    type: PROGRAM_PROGRESS_LOADING,
    payload,
    loading: true
  }
}

function programProgressDone(payload) {
  return {
    type: PROGRAM_PROGRESS_LOADED,
    payload,
    loading: false
  }
}
function programProgressFailed(error, payload) {
  return {
    type: PROGRAM_PROGRESS_FAILED,
    error,
    payload,
    loading: false
  }
}

function programProgressSaving(payload) {
  return {
    type: PROGRAM_PROGRESS_SAVING,
    payload,
    loading: true
  }
}

function programProgressSaved(payload) {
  return {
    type: PROGRAM_PROGRESS_SAVED,
    payload,
    loading: false
  }
}

function programProgressSaveFailed(error, payload) {
  return {
    type: PROGRAM_PROGRESS_SAVE_FAILED,
    error,
    payload,
    loading: false
  }
}

export const checkProgramProgress = ({
  contentId,
  progressContentType,
  episodeId,
  seriesId,
  isLoggedIn
}) => dispatch => {
  if (!contentId || !progressContentType || !isLoggedIn) {
    return
  }

  dispatch(
    programProgressStarted({
      contentId,
      progressContentType,
      reportInterval: fallbackReportInterval,
      episodeId,
      seriesId
    })
  )

  const abortController = new AbortController()
  const timeout = setTimeout(() => {
    clearTimeout(timeout)
    abortController.abort()
  }, 2000)

  return fetchProgramProgressById({ contentId, progressContentType }, { signal: abortController.signal })
    .then(result => {
      const notBeforeMs = durationToMilliseconds(result.reportInterval.notBefore)
      const intervalMs = durationToMilliseconds(result.reportInterval.interval)
      const resultWithMs = { ...result, reportInterval: { ...result.reportInterval, intervalMs, notBeforeMs } }
      dispatch(programProgressDone({ ...resultWithMs, contentId, progressContentType, episodeId, seriesId }))
    })
    .catch(error => {
      logger.error(error)
      dispatch(
        programProgressFailed(error, {
          contentId,
          progressContentType,
          reportInterval: fallbackReportInterval,
          episodeId,
          seriesId
        })
      )
    })
    .finally(() => {
      logger.info('checkProgramProgress done')
      clearTimeout(timeout)
    })
}

export const saveProgress = ({ url, localProgress, startPlaybackPosition, isLoggedIn }) => async dispatch => {
  if (!localProgress.time || !isLoggedIn) return

  dispatch(programProgressSaving({ _links: { self: { href: url } }, localProgress, startPlaybackPosition }))
  const abortController = new AbortController()
  const timeout = setTimeout(() => {
    clearTimeout(timeout)
    abortController.abort()
  }, 1000)

  try {
    await saveProgramProgress(
      { url, position: localProgress.time, startPlaybackPosition },
      { signal: abortController.signal }
    )
    dispatch(programProgressSaved({ _links: { self: { href: url } }, localProgress, startPlaybackPosition }))
  } catch (error) {
    dispatch(
      programProgressSaveFailed(error, { _links: { self: { href: url } }, localProgress, startPlaybackPosition })
    )
  } finally {
    clearTimeout(timeout)
  }
}

const programProgressListLoading = payload => {
  return {
    type: PROGRAMS_PROGRESS_LIST_LOADING,
    loading: true,
    payload
  }
}

const programProgressListLoaded = payload => {
  return {
    type: PROGRAMS_PROGRESS_LIST_LOADED,
    loading: false,
    payload
  }
}

const programProgressListFailed = error => {
  return {
    type: PROGRAMS_PROGRESS_LIST_FAILED,
    loading: false,
    error
  }
}

export const clearProgramProgressList = () => {
  return {
    type: PROGRAMS_PROGRESS_LIST_CLEARED,
    loading: false
  }
}

let fetchProgramProgressListAbortController = noopAbortController

export const fetchProgramProgressList = ({ href }) => async dispatch => {
  fetchProgramProgressListAbortController.abort()
  if (!window.loginSession?.isLoggedIn) return
  fetchProgramProgressListAbortController = new AbortController()

  dispatch(programProgressListLoading({ href }))

  try {
    const programProgressList = await fetchProgramProgressByHref(href, {
      signal: fetchProgramProgressListAbortController.signal
    })
    dispatch(programProgressListLoaded(programProgressList))
  } catch (error) {
    dispatch(programProgressListFailed(error))
  }
}
