import { getUpNextForContent } from '../../../apiClients/personalisation'

export const UP_NEXT_LOADING = 'UP_NEXT_LOADING'
export const UP_NEXT_LOADED = 'UP_NEXT_LOADED'
export const UP_NEXT_FAILED = 'UP_NEXT_FAILED'
export const UP_NEXT_CLEARED = 'UP_NEXT_CLEARED'

const upNextLoading = payload => {
  return {
    type: UP_NEXT_LOADING,
    payload,
    loading: true
  }
}

const upNextLoaded = payload => {
  return {
    type: UP_NEXT_LOADED,
    payload,
    loading: false
  }
}

const upNextFailed = ({ error, ...payload }) => {
  return {
    type: UP_NEXT_FAILED,
    payload,
    error,
    loading: false
  }
}

export const fetchUpNext = ({ upNextContentType, contentId, upNextSeriesId, queryString }) => async dispatch => {
  dispatch(upNextLoading({ upNextContentType, sourceContentId: contentId, upNextSeriesId }))
  try {
    const upNext = await getUpNextForContent({ upNextContentType, contentId, queryString })
    return dispatch(upNextLoaded({ ...upNext, sourceContentId: contentId, upNextSeriesId }))
  } catch (error) {
    dispatch(upNextFailed({ upNextContentType, sourceContentId: contentId, upNextSeriesId, error }))
    return Promise.reject(error)
  }
}

export const upNextCleared = () => {
  return { type: UP_NEXT_CLEARED }
}
