import { fixedEncodeURIComponent } from '../../../common/fixedEncodeURIComponent'
import { fetchSeriesById, fetchSeasonById, fetchEpisodeById, fetchSeriesSubmissionsById } from '../actions'

const pageDataByAllSeriesIdsServerSide = (store, { match }) => {
  const seriesLoadDataPromise = Promise.all([
    store.dispatch(fetchSeriesById(fixedEncodeURIComponent(match.params.seriesId))).loadDataPromise,
    store.dispatch(
      fetchSeasonById(fixedEncodeURIComponent(match.params.seriesId), fixedEncodeURIComponent(match.params.seasonId))
    ).loadDataPromise,
    store.dispatch(fetchEpisodeById(fixedEncodeURIComponent(match.params.programId))).loadDataPromise
  ])

  const seriesSubmissionsLoadDataPromise = store.dispatch(
    fetchSeriesSubmissionsById(fixedEncodeURIComponent(match.params.seriesId))
  ).loadDataPromise

  return Promise.allSettled([seriesLoadDataPromise, seriesSubmissionsLoadDataPromise]).then(([seriesFetchResult]) => {
    const { status, value, reason } = seriesFetchResult
    return status === 'fulfilled' ? value : Promise.reject(reason)
  })
}

export default pageDataByAllSeriesIdsServerSide
