import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Box from '../Box'

const Flex = forwardRef(({ justify, align, wrap, direction, gap, ...rest }, ref) => (
  <Box
    display="flex"
    ref={ref}
    flexDirection={direction}
    alignItems={align}
    justifyContent={justify}
    flexWrap={wrap}
    gap={gap}
    {...rest}
  />
))

Flex.displayName = 'Flex'
Flex.propTypes = {
  /**
   * Shorthand for Styled-System `alignItems` prop
   */
  align: PropTypes.any,
  /**
   * Shorthand for Styled-System `justifyContent` prop
   */
  justify: PropTypes.any,
  /**
   * Shorthand for Styled-System `flexWrap` prop
   */
  wrap: PropTypes.any,
  /**
   * Shorthand for Styled-System `flexDirection` prop
   */
  direction: PropTypes.any,

  gap: PropTypes.any
}

export default Flex
