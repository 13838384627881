import { fixedEncodeURIComponent } from '../../../common/fixedEncodeURIComponent'
import { fetchPodcastById, fetchPodcastEpisodeById, fetchSeason, fetchSeriesSubmissionsById } from '../actions'
import latestEpisodesFromSeries from '../helpers/latestEpisodesFromSeries'

const pageDataByPodcastIdAndPodcastEpisodeIdServerSide = (store, { match }) => {
  const seriesLoadDataPromise = Promise.all([
    store.dispatch(fetchPodcastById(fixedEncodeURIComponent(match.params.seriesId))).loadDataPromise,
    store.dispatch(
      fetchPodcastEpisodeById(
        fixedEncodeURIComponent(match.params.seriesId),
        fixedEncodeURIComponent(match.params.programId)
      )
    ).loadDataPromise
  ]).then(([seriesActionRes, episodeActionRes]) => {
    const latestEpisodes = latestEpisodesFromSeries(seriesActionRes.payload)
    const foundEpisodeInLatestEpisodes = latestEpisodes.episodes.find(
      episode => episode.episodeId === match.params.programId
    )
    if (!foundEpisodeInLatestEpisodes) {
      return (
        episodeActionRes.payload._links.season &&
        store.dispatch(fetchSeason(episodeActionRes.payload._links.season)).loadDataPromise
      )
    }
  })

  const seriesSubmissionsLoadDataPromise = store.dispatch(
    fetchSeriesSubmissionsById(fixedEncodeURIComponent(match.params.seriesId))
  ).loadDataPromise

  return Promise.allSettled([seriesLoadDataPromise, seriesSubmissionsLoadDataPromise]).then(([seriesFetchResult]) => {
    const { status, value, reason } = seriesFetchResult
    return status === 'fulfilled' ? value : Promise.reject(reason)
  })
}

export default pageDataByPodcastIdAndPodcastEpisodeIdServerSide
