import { fetchCategories as psapiFetchCategories } from '../../../apiClients/psapi'
import logger from '../../../logger'

export const CATEGORIES_LOADING = 'CATEGORIES_LOADING'
export const CATEGORIES_LOADED = 'CATEGORIES_LOADED'
export const CATEGORIES_RESET = 'CATEGORIES_RESET'
export const CATEGORIES_LOADING_CANCELLED = 'CATEGORIES_LOADING_CANCELLED'
export const CATEGORIES_LOADING_FAILED = 'CATEGORIES_LOADING_FAILED'

function categoriesLoading() {
  return {
    type: CATEGORIES_LOADING,
    loading: true
  }
}

function categoriesLoaded(payload) {
  return {
    type: CATEGORIES_LOADED,
    loading: false,
    payload
  }
}

function categoriesCancelled() {
  return {
    type: CATEGORIES_LOADING_CANCELLED,
    loading: false,
    cancelled: true
  }
}

function categoriesFailed(error) {
  return {
    type: CATEGORIES_LOADING_FAILED,
    loading: false,
    error
  }
}

export const fetchCategories = () => dispatch => {
  const abortController = new AbortController()
  dispatch(categoriesLoading())

  const fetchCategoriesPromise = psapiFetchCategories({ signal: abortController.signal })

  fetchCategoriesPromise
    .then(categories => {
      dispatch(categoriesLoaded(categories))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        dispatch(categoriesCancelled())
      } else {
        logger.error(`Failed fetching categories: ${error.messsage}`)
        dispatch(categoriesFailed(error))
      }
    })

  return {
    abort: () => {
      abortController.abort()
    },
    loadDataPromise: fetchCategoriesPromise
  }
}

export const fetchCategoriesServerSide = (store, { match }) => {
  return store.dispatch(fetchCategories(match.params.seriesId)).loadDataPromise
}
