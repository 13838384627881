import PropTypes from 'prop-types'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useProgressInPlayerSeconds } from '../../../redux/personalisation/progressHooks'
import { MEDIA_TYPES } from '../helpers/mediaTypes'
import { useAudioOrVideoPlayerElement } from '../hooks/useAudioOrVideoPlayerElement'
import { useHasPlayBeenTriggered as useHasAudioPlayBeenTriggered } from './PlayerElementStateContext'
import { usePlayerMetadata, useVideoVersionId } from './PlayerMetadataContext'
import { useHasPlayBeenTriggered as useHasVideoPlayBeenTriggered } from './VideoPlayerElementStateContext'

const noop = () => {}

const StickyPlayerContext = createContext({
  isFullscreen: false,
  setIsFullscreen: noop,
  closeFullscreen: noop,
  openFullscreen: noop,
  openFullscreenAndSwitchToVideo: noop,
  closeFullscreenAndSwitchToAudio: noop,
  toggleFullscreen: noop,
  fullscreenRef: { current: null }
})

export const StickyPlayerProvider = ({ children }) => {
  const [isFullscreen, setIsFullscreen] = useState(false)
  const fullscreenRef = useRef()
  const { episodeId, seasonId, seriesId } = usePlayerMetadata()
  const { loadAndStartOrPlayFrom, loadPlayer, getCurrentTime, $videoPlayerElement } = useAudioOrVideoPlayerElement()
  const hasAudioPlayBeenTriggered = useHasAudioPlayBeenTriggered()
  const hasVideoPlayBeenTriggered = useHasVideoPlayBeenTriggered()
  const hasPlayBeenTriggered = hasAudioPlayBeenTriggered || hasVideoPlayBeenTriggered
  const clipId = useVideoVersionId()
  const progressInSeconds = useProgressInPlayerSeconds()

  useEffect(() => {
    if (fullscreenRef.current?.open && !isFullscreen) {
      fullscreenRef.current?.close()
    } else if (!fullscreenRef.current?.open && isFullscreen) {
      fullscreenRef.current?.showModal()
    }
  }, [isFullscreen])

  const toggleFullscreen = useCallback(() => {
    setIsFullscreen(prevIsFullscreen => !prevIsFullscreen)
  }, [])

  const closeFullscreen = useCallback(() => {
    setIsFullscreen(false)
  }, [])

  const openFullscreen = useCallback(() => {
    setIsFullscreen(true)
  }, [])

  const openFullscreenAndSwitchToVideo = useCallback(() => {
    if (clipId) {
      const seekTo =
        !hasPlayBeenTriggered && typeof progressInSeconds === 'number' ? progressInSeconds : getCurrentTime()

      loadAndStartOrPlayFrom({
        episodeId,
        clipId,
        seasonId,
        seriesId,
        mediaType: MEDIA_TYPES.CLIP,
        seekTo
      })
    }
    openFullscreen()
  }, [
    clipId,
    episodeId,
    getCurrentTime,
    hasPlayBeenTriggered,
    loadAndStartOrPlayFrom,
    openFullscreen,
    progressInSeconds,
    seasonId,
    seriesId
  ])

  const closeFullscreenAndSwitchToAudio = useCallback(() => {
    if (episodeId && clipId) {
      const seekTo =
        !hasPlayBeenTriggered && typeof progressInSeconds === 'number' ? progressInSeconds : getCurrentTime()

      if ($videoPlayerElement?.isPlaying) {
        loadAndStartOrPlayFrom({
          episodeId,
          clipId,
          seriesId,
          seasonId,
          mediaType: MEDIA_TYPES.PODCAST,
          seekTo
        })
      } else {
        loadPlayer({
          episodeId,
          clipId,
          seriesId,
          seasonId,
          mediaType: MEDIA_TYPES.PODCAST
        })
      }
    }
    closeFullscreen()
  }, [
    $videoPlayerElement?.isPlaying,
    clipId,
    closeFullscreen,
    episodeId,
    getCurrentTime,
    hasPlayBeenTriggered,
    loadAndStartOrPlayFrom,
    loadPlayer,
    progressInSeconds,
    seasonId,
    seriesId
  ])

  const value = useMemo(() => {
    return {
      isFullscreen,
      setIsFullscreen,
      closeFullscreen,
      openFullscreen,
      openFullscreenAndSwitchToVideo,
      closeFullscreenAndSwitchToAudio,
      toggleFullscreen,
      fullscreenRef
    }
  }, [
    closeFullscreen,
    closeFullscreenAndSwitchToAudio,
    isFullscreen,
    openFullscreen,
    openFullscreenAndSwitchToVideo,
    toggleFullscreen
  ])

  return <StickyPlayerContext.Provider value={value}>{children}</StickyPlayerContext.Provider>
}

StickyPlayerProvider.propTypes = {
  children: PropTypes.node
}

export default StickyPlayerProvider

export const useStickyPlayer = () => {
  const context = useContext(StickyPlayerContext)
  return context
}

export const useIsFullscreen = () => {
  const { isFullscreen } = useContext(StickyPlayerContext)
  return isFullscreen
}
