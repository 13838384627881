import { css } from '@emotion/react'
import zIndices from '../theme/zIndices'

export const style = color => css`
  position: absolute;
  background: ${color.light};
  color: ${color.dark};
  border-radius: 6px;
  z-index: ${zIndices.tooltip};
  display: flex;
  justify-content: space-between;

  &.top {
    top: 5px;
    left: 50%;
    transform: translate(-50%, -100%);
  }

  &.bottom {
    top: 45px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &.right {
    top: 0%;
    left: 90%;
    transform: translate(0, -50%);
  }

  &.left {
    top: 0%;
    right: 100%;
    transform: translate(0, -50%);
  }
`

export const arrowStyle = color => css`
  position: absolute;
  overflow: hidden;

  &.top {
    top: 100%;
    left: 50%;
    margin-left: -12px;
    width: 24px;
    height: 12px;
  }

  &.bottom {
    bottom: 100%;
    left: 50%;
    margin-left: -12px;
    width: 24px;
    height: 12px;
  }

  &.right {
    top: 75%;
    right: 100%;
    margin-top: -12px;
    width: 12px;
    height: 24px;
  }

  &.left {
    top: 75%;
    left: 100%;
    margin-top: -12px;
    width: 12px;
    height: 24px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: ${color.light};
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  }

  &.top::after {
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &.bottom::after {
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
  }

  &.right::after {
    left: 0;
    top: 50%;
    transform: translate(50%, -50%) rotate(-45deg);
  }

  &.left::after {
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`
