import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Box from '../Box'

const ContentWidth = forwardRef(({ children, ...props }, ref) => {
  return (
    <Box ref={ref} w={{ base: '90%', lg: '82%' }} mx={{ base: '5%', lg: '9%' }} {...props}>
      {children}
    </Box>
  )
})

ContentWidth.displayName = 'ContentWidth'
ContentWidth.propTypes = {
  children: PropTypes.node
}

export default ContentWidth
