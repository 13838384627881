import appendQueryToPath from '../common/appendQueryToPath'
import logger from '../logger'
import clientConfig from '../clientConfig'
import SORTS from '../pages/Series/helpers/sorts'
import serverSideFetch from '../common/serverSideFetch'

export const apiSections = {
  pages: '/radio/pages',
  personalised: '/radio/personalised/v2',
  crossdomain: '/radio/crossdomain/v1',
  series: '/series',
  programs: '/programs',
  channels: '/channels',
  suggestions: '/medium/radio',
  autocomplete: '/autocomplete',
  radioSearch: '/radio/search',
  search: '/search',
  podcasts: '/podcasts',
  epg: '/epg',
  radio: '/radio',
  letters: '/medium/radio/letters',
  playbackMetadata: '/playback/metadata',
  catalog: '/radio/catalog',
  radioChannels: '/radio/channels',
  recommended: '/radio/recommendations'
}

const defaultOptions = {
  useProxy: true,
  credentials: 'omit'
}

const defaultHeaders = {
  Accept: 'application/json'
}

const getUrl = (path, useProxy, baseUrl) => {
  if (baseUrl) {
    return `${baseUrl}${path}`
  }

  if (useProxy) {
    return process.browser
      ? `/psapi${path}`
      : `${process.env.PS_API_BASE_URL}${appendQueryToPath({ apiKey: process.env.PS_API_KEY }, path)}`
  }
  return `${clientConfig.PS_API_BASE_URL}${path}`
}

const fetchFunc = (...params) => {
  return process.browser ? fetch(...params) : serverSideFetch(...params)
}

export function fetchPSAPIJSON(path, options = {}) {
  const { headers: userHeaders = {}, ...restOptions } = options
  const { useProxy, baseUrl, ...fetchOptions } = {
    headers: { ...defaultHeaders, ...userHeaders },
    ...defaultOptions,
    ...restOptions
  }

  const url = getUrl(path, useProxy, baseUrl)
  // logger.debug(`Fetching from ${url}`)
  const begin = Date.now()
  return fetchFunc(url, fetchOptions)
    .then(response => {
      const { status, statusText } = response
      logger.info(`Fetched from url ${url} ${status} ${Date.now() - begin} ms`)
      if (!response.ok) {
        const error = new Error(`Failed fetching ${url}`)
        error.status = status
        error.statusText = statusText
        error.url = url
        return Promise.reject(error)
      }

      return response
    })
    .then(response => response.json())
    .then(data => {
      if (data.sourceMedium && data.sourceMedium === 1) {
        // Prevent tv content in radio.nrk.no
        const error = new Error('Not found')
        error.status = 404
        error.statusText = 'Not found'
        return Promise.reject(error)
      }
      return data
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        logger.info(`Request for ${url} aborted.  ${Date.now() - begin} ms`)
      } else {
        logger.error(`Failed fetching ${url} ${Date.now() - begin} ms`, error)
      }
      return Promise.reject(error)
    })
}

export function fetchUrl(url) {
  return fetchPSAPIJSON(url)
}

export function fetchFrontPage(options = {}) {
  return fetchPSAPIJSON(`${apiSections.pages}/discover`, {
    // return fetchPSAPIJSON(`${apiSections.pages}/test-page`, {
    headers: {
      Accept: 'application/json; api-version=3.5',
      'Content-Type': 'application/json;api-version=3.5'
    },
    useProxy: false,
    ...options
  })
}

export function fetchPageV3(pageId, options = {}) {
  return fetchPSAPIJSON(`${apiSections.pages}/${pageId}`, {
    // return fetchPSAPIJSON(`${apiSections.pages}/test-page`, {
    headers: {
      Accept: 'application/json; api-version=3.5',
      'Content-Type': 'application/json;api-version=3.5'
    },
    useProxy: false,
    ...options
  })
}

export function fetchSeriesLatestEpisode(seriesId) {
  return fetchPSAPIJSON(`${apiSections.series}/${seriesId}/latestOrNextEpisode`)
}

export function fetchSeriesSeasons(seriesId) {
  return fetchPSAPIJSON(`${apiSections.series}/${seriesId}`)
}

export function fetchEpisodes(seriesId, seasonId) {
  return fetchPSAPIJSON(`${apiSections.series}/${seriesId}/seasons/${seasonId}/Episodes`)
}

export function fetchEpisode(programId, includeIndexPoints = true) {
  return fetchPSAPIJSON(`${apiSections.programs}/${programId}?includeIndexPoints=${includeIndexPoints}`)
}

export function fetchEpisodeContext(episodeId) {
  return fetchPSAPIJSON(`${apiSections.catalog}/episode/context/${episodeId}`)
}

export function fetchRecommendedProgramsByChannel(channelId, maxNumber) {
  return fetchPSAPIJSON(`${apiSections.channels}/${channelId}/recommendedprograms?maxnumber=${maxNumber}`)
}

export function fetchRecommendedProgramsByCategory(category, maxNumber) {
  return fetchPSAPIJSON(`${apiSections.suggestions}/categories/${category}/recommendedprograms?maxnumber=${maxNumber}`)
}

export function fetchMostListened(listType, maxNumber, startRow) {
  return fetchPSAPIJSON(
    `${apiSections.suggestions}/popularprograms/${listType}?maxnumber=${maxNumber}&startRow=${startRow}`
  )
}

export function fetchRecentlySent(maxNumber, startRow) {
  return fetchPSAPIJSON(`${apiSections.suggestions}/recentlysentprograms?maxnumber=${maxNumber}&startRow=${startRow}`)
}

export function fetchRecentlySentByCategory(category, maxNumber, startRow) {
  return fetchPSAPIJSON(
    `${apiSections.suggestions}/categories/${category}/recentlysentprograms?maxnumber=${maxNumber}&startRow=${startRow}`
  )
}

export function fetchRecentlySentByChannel(channelId, maxNumber, startRow) {
  return fetchPSAPIJSON(
    `${apiSections.channels}/${channelId}/recentlysentprograms?maxnumber=${maxNumber}&startRow=${startRow}`
  )
}

export function fetchIndexPointsByCategory(category) {
  return fetchPSAPIJSON(`${apiSections.suggestions}/categories/${category}/indexelements`)
}

export function fetchIndexPointsByChannel(channelId) {
  return fetchPSAPIJSON(`${apiSections.suggestions}/channels/${channelId}/indexelements`)
}

export function fetchCategoriesAndChannels() {
  const urls = [`${apiSections.suggestions}/categories`, `${apiSections.suggestions}/channels`]

  return Promise.all(urls.map(url => fetchPSAPIJSON(url)))
}

export function fetchAutocomplete(query, options = {}) {
  return fetchPSAPIJSON(`${apiSections.autocomplete}/?q=${query}&medium=radio&includePodcasts=true`, options)
}

export function fetchTitleSearch(query, options = {}) {
  const { take = 10, skip = 0, ...restOptions } = options
  return fetchPSAPIJSON(`${apiSections.radioSearch}/title?q=${query}&take=${take}&skip=${skip}`, {
    ...restOptions,
    useProxy: false,
    headers: { accept: 'application/json;api-version=2' }
  })
}

export function fetchTitleSearchSuggestions(query, options = {}) {
  return fetchPSAPIJSON(`${apiSections.radioSearch}/suggest?q=${query}`, {
    ...options,
    useProxy: false,
    headers: { accept: 'application/json;api-version=2' }
  })
}

export function fetchSearchResults(search, options = {}) {
  const { page = 0, maxResults = 20, ...restOptions } = options
  return fetchPSAPIJSON(
    `${apiSections.search}/?q=${search}&page=${page}&maxResultsPerPage=${maxResults}&medium=radio&includePodcasts=true`,
    restOptions
  )
}

export function fetchSearch(query, options = {}) {
  const { take = 12, skip = 0, page = 0, useProxy = false, ...restOptions } = options
  const headers = { accept: 'application/json;api-version=2' }
  return fetchPSAPIJSON(`${apiSections.radioSearch}/search?q=${query}&take=${take}&skip=${skip}&page=${page}`, {
    useProxy,
    headers,
    ...restOptions
  })
}

export function fetchSearchSuggestion(query, options = {}) {
  const { useProxy = false, ...restOptions } = options
  const headers = { accept: 'application/json;api-version=2' }
  return fetchPSAPIJSON(`${apiSections.radioSearch}/search/suggest?q=${query}`, {
    useProxy,
    headers,
    ...restOptions
  })
}

export function fetchAlphabeticIndexedCategory(categoryId, options = {}) {
  const defaultOptions = { take: 50, skip: 0, useProxy: false }
  const { take, skip, letter, useProxy, ...restOptions } = { ...defaultOptions, ...options }
  const letterParamStrMaybe = letter ? `&letter=${letter}` : ''
  return fetchPSAPIJSON(
    `${apiSections.radioSearch}/categories/${categoryId}?take=${take}&skip=${skip}${letterParamStrMaybe}`,
    {
      useProxy,
      ...restOptions
    }
  )
}
export function fetchSearchPageByHref(href, options = {}) {
  const { useProxy = false, ...restOptions } = options
  const headers = { accept: 'application/json;api-version=2' }
  return fetchPSAPIJSON(href, { useProxy, headers, ...restOptions })
}

export function fetchPodcast(podcastId) {
  return fetchPSAPIJSON(`${apiSections.podcasts}/${podcastId}`)
}

export function fetchPodcastEpisode(podcastId, episodeId) {
  return fetchPSAPIJSON(`${apiSections.podcasts}/${podcastId}/episodes/${episodeId}`)
}

export function fetchProgram(programId) {
  return fetchPSAPIJSON(`${apiSections.programs}/${programId}?includeIndexPoints=true`)
}

export function fetchLiveChannels() {
  return fetchPSAPIJSON(`${apiSections.suggestions}/channels`)
}

export function fetchNextNowLive() {
  return fetchPSAPIJSON(`${apiSections.suggestions}/nownextliveepg`)
}

export function fetchMusicPlaylist(programId, options = {}) {
  const defaultOptions = { useProxy: false }
  return fetchPSAPIJSON(`${apiSections.programs}/${programId}/playlist`, { ...defaultOptions, ...options })
}

export function fetchLiveElements(channelId, options = {}) {
  return fetchPSAPIJSON(`${apiSections.channels}/${channelId}/liveelements`, { useProxy: false, ...options })
}

export function fetchChannels(options) {
  return fetchPSAPIJSON(`${apiSections.radio}/live`, options)
}

export function fetchLetter(letter = '', onlyOnDemandRights = true, options = {}) {
  const path = `${apiSections.letters}/${letter}/indexelements?onlyOnDemandRights=${onlyOnDemandRights}`
  return fetchPSAPIJSON(path, options)
}

export function fetchLetters(options = {}) {
  return fetchPSAPIJSON(`${apiSections.letters}`, options)
}

export function fetchCategories(options = {}) {
  return fetchPSAPIJSON(`${apiSections.pages}`, {
    useProxy: false,
    ...options
  })
}

export function fetchNRKnoMultiplugg(options = {}) {
  return fetchPSAPIJSON(`${apiSections.pages}/nrkno-multiplugg?limit=1`, {
    headers: {
      Accept: 'application/json; api-version=3.5',
      'Content-Type': 'application/json;api-version=3.5'
    },
    useProxy: false,
    ...options
  })
}

export function fetchCategory(categoryId, options = {}) {
  if (!categoryId) {
    // TODO:
    return Promise.reject(new Error('Unknown categoryId'))
  }
  return fetchPageV3(categoryId, options)
}

export function fetchPlaybackMetadata(id, options = {}) {
  const defaultOptions = { useProxy: false, headers: { Accept: 'application/json', 'Content-Type': 'text/plain' } }
  return fetchPSAPIJSON(`${apiSections.playbackMetadata}/${id}`, { ...defaultOptions, ...options })
}

export function fetchPlaybackMetadataClip(id, options = { useProxy: false, headers: { Accept: 'application/json' } }) {
  return fetchPSAPIJSON(`${apiSections.playbackMetadata}/clip/${id}`, options)
}

export function fetchCatalogSeries(seriesId, options = {}) {
  return fetchPSAPIJSON(`${apiSections.catalog}/series/${seriesId}`, {
    // baseUrl: 'https://psapi.nrk.no',
    useProxy: false,
    ...options
  })
}

export function fetchCatalogPodcast(podcastId, options = {}) {
  return fetchPSAPIJSON(`${apiSections.catalog}/podcast/${podcastId}`, {
    // baseUrl: 'https://psapi.nrk.no',
    useProxy: false,
    ...options
  })
}

export function fetchCatalogSeason(seriesId, seasonId, options = {}) {
  return fetchPSAPIJSON(`${apiSections.catalog}/series/${seriesId}/seasons/${seasonId}`, {
    // baseUrl: 'https://psapi.nrk.no',
    useProxy: false,
    ...options
  })
}

const defaultQueryParams = { pageSize: 10, page: 1, sort: SORTS.DESC }

export function fetchCatalogSeasonEpisodes(seriesId, seasonId, queryParams = defaultQueryParams, options = {}) {
  const actualQueryParams = { ...defaultQueryParams, ...queryParams }
  const seasonIdPart = seasonId ? `/seasons/${seasonId}` : ''
  const path = `${apiSections.catalog}/series/${seriesId}${seasonIdPart}/episodes`
  const pathWithQueryParams = appendQueryToPath(actualQueryParams, path)

  return fetchPSAPIJSON(pathWithQueryParams, {
    // baseUrl: 'https://psapi.nrk.no',
    useProxy: false,
    ...options
  })
}

export function fetchCatalogPodcastSeason(podcastId, seasonId, options = {}) {
  return fetchPSAPIJSON(`${apiSections.catalog}/podcast/${podcastId}/seasons/${seasonId}`, {
    // baseUrl: 'https://psapi.nrk.no',
    useProxy: false,
    ...options
  })
}

export function fetchCatalogPodcastSeasonEpisodes(podcastId, seasonId, queryParams = defaultQueryParams, options = {}) {
  const actualQueryParams = { ...defaultQueryParams, ...queryParams }
  const seasonIdPart = seasonId ? `/seasons/${seasonId}` : ''
  const path = `${apiSections.catalog}/podcast/${podcastId}${seasonIdPart}/episodes`
  const pathWithQueryParams = appendQueryToPath(actualQueryParams, path)

  return fetchPSAPIJSON(pathWithQueryParams, {
    // baseUrl: 'https://psapi.nrk.no',
    useProxy: false,
    ...options
  })
}

export function fetchCatalogPodcastEpisode(podcastId, podcastEpisodeId, options = {}) {
  return fetchPSAPIJSON(`${apiSections.catalog}/podcast/${podcastId}/episodes/${podcastEpisodeId}`, {
    // baseUrl: 'https://psapi.nrk.no',
    useProxy: false,
    ...options
  })
}

export function fetchCatalogProgram(programId, options = {}) {
  return fetchPSAPIJSON(`${apiSections.catalog}/programs/${programId}`, {
    // baseUrl: 'https://psapi.nrk.no',
    useProxy: false,
    ...options
  })
}

export function fetchCatalogHref({ href }, options = {}) {
  return fetchPSAPIJSON(href, {
    // baseUrl: 'https://psapi.nrk.no',
    useProxy: false,
    ...options
  })
}
export function fetchRecommendedByPodcastId({ contentId, maxNumber }, options = {}) {
  const maxNumberString = maxNumber ? `&maxNumber=${maxNumber}` : ''
  return fetchPSAPIJSON(
    `${apiSections.recommended}/${contentId}?list=radio_viderenavigasjon_fra_program${maxNumberString}`,
    {
      // baseUrl: 'https://psapi.nrk.no',
      useProxy: false,
      ...options
    }
  )
}

export function fetchChannelsLivebufferNow(options = {}) {
  return fetchPSAPIJSON(`${apiSections.radioChannels}/livebuffer/now`, {
    useProxy: false,
    ...options,
    headers: { 'Content-Type': 'text/plain' }
  })
}

export function fetchChannelLiveBufferForChannel(channelId, options = {}) {
  return fetchPSAPIJSON(`${apiSections.radioChannels}/livebuffer/${channelId}`, {
    useProxy: false,
    ...options,
    headers: { 'Content-Type': 'text/plain' }
  })
}

export function fetchSegment(options = {}) {
  const { isLoggedIn, isExpired, accessToken } = window.loginSession
  if (!isLoggedIn || isExpired) {
    return Promise.reject(new Error('Missing accessToken'))
  }

  return fetchPSAPIJSON(`${apiSections.recommended}/segment`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    useProxy: false,
    ...options
  })
}

export function fetchSegmentedPage(pageId, segment, options = {}) {
  if (!pageId) {
    return Promise.reject(new Error('Missing pageId'))
  }
  if (!segment) {
    return Promise.reject(new Error('Missing segment data'))
  }

  const urlParams = new URLSearchParams(
    Object.fromEntries(
      Object.entries({
        userSegments: segment.userSegments,
        experimentId: segment.activeExperiment?.experimentId,
        variant: segment.activeExperiment?.variant
      }).filter(([_, v]) => v != null)
    )
  )

  const path = `${apiSections.pages}/${pageId}?${urlParams.toString()}`

  return fetchPSAPIJSON(path, {
    headers: {
      Accept: 'application/json; api-version=3.5',
      'Content-Type': 'application/json;api-version=3.5'
    },
    useProxy: false,
    ...options
  })
}
