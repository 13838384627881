import { fetchSeriesSubmissionSettings } from '../../apiClients/interaction'
import {
  fetchCatalogSeries,
  fetchCatalogHref,
  fetchCatalogProgram,
  fetchCatalogPodcast,
  fetchCatalogPodcastEpisode,
  fetchCatalogSeason,
  fetchCatalogPodcastSeason,
  fetchCatalogSeasonEpisodes,
  fetchCatalogPodcastSeasonEpisodes
} from '../../apiClients/psapi'
// import { fetchSeriesSubmissionSettings } from '../../apiClients/interaction'
import logger from '../../logger'

const noop = () => {}
const noopAbortController = { abort: noop }

let seriesAbortController = noopAbortController
let seriesSubmissionsAbortController = noopAbortController
let seasonAbortController = noopAbortController
let episodeAbortController = noopAbortController

export const SERIES_SUBMISSION_SETTINGS_LOADING = 'SERIES_SUBMISSION_SETTINGS_LOADING'
export const SERIES_SUBMISSION_SETTINGS_LOADED = 'SERIES_SUBMISSION_SETTINGS_LOADED'
export const SERIES_SUBMISSION_SETTINGS_FAILED = 'SERIES_SUBMISSION_SETTINGS_FAILED'

export const SERIES_LOADING = 'SERIES_LOADING'
export const SERIES_LOADED = 'SERIES_LOADED'
export const SERIES_FAILED = 'SERIES_FAILED'
export const SERIES_CANCELLED = 'SERIES_CANCELLED'
export const SERIES_CLEARED = 'SERIES_CLEARED'

export const SEASON_LOADING = 'SEASON_LOADING'
export const SEASON_LOADED = 'SEASON_LOADED'
export const SEASON_FAILED = 'SEASON_FAILED'
export const SEASON_CANCELLED = 'SEASON_CANCELLED'
export const SEASON_CLEARED = 'SEASON_CLEARED'

export const SEASON_EPISODES_REPLACE_LOADING = 'SEASON_EPISODES_REPLACE_LOADING'
export const SEASON_EPISODES_REPLACE_LOADED = 'SEASON_EPISODES_REPLACE_LOADED'
export const SEASON_EPISODES_REPLACE_FAILED = 'SEASON_EPISODES_REPLACE_FAILED'
export const SEASON_EPISODES_REPLACE_CANCELLED = 'SEASON_EPISODES_REPLACE_CANCELLED'
export const SEASON_EPISODES_REPLACE_CLEARED = 'SEASON_EPISODES_REPLACE_CLEARED'

export const RESET_EPISODES = 'RESET_EPISODES'

export const EPISODE_LOADING = 'EPISODE_LOADING'
export const EPISODE_LOADED = 'EPISODE_LOADED'
export const EPISODE_FAILED = 'EPISODE_FAILED'
export const EPISODE_CANCELLED = 'EPISODE_CANCELLED'
export const EPISODE_CLEARED = 'EPISODE_CLEARED'
export const SET_EPISODE_LOADING = 'SET_EPISODE_LOADING'

export const SEASON_PAGE_LOADING = 'SEASON_PAGE_LOADING'
export const SEASON_PAGE_LOADED = 'SEASON_PAGE_LOADED'
export const SEASON_PAGE_FAILED = 'SEASON_PAGE_FAILED'
export const SEASON_PAGE_CANCELLED = 'SEASON_PAGE_CANCELLED'
export const SEASON_PAGE_CLEARED = 'SEASON_PAGE_CLEARED'

export const LATEST_EPISODES_PAGE_LOADING = 'LATEST_EPISODES_PAGE_LOADING'
export const LATEST_EPISODES_PAGE_LOADED = 'LATEST_EPISODES_PAGE_LOADED'
export const LATEST_EPISODES_PAGE_FAILED = 'LATEST_EPISODES_PAGE_FAILED'
export const LATEST_EPISODES_PAGE_CANCELLED = 'LATEST_EPISODES_PAGE_CANCELLED'
export const LATEST_EPISODES_PAGE_CLEARED = 'LATEST_EPISODES_PAGE_CLEARED'

export const LATEST_EPISODES_REPLACE_LOADING = 'LATEST_EPISODES_REPLACE_LOADING'
export const LATEST_EPISODES_REPLACE_LOADED = 'LATEST_EPISODES_REPLACE_LOADED'
export const LATEST_EPISODES_REPLACE_FAILED = 'LATEST_EPISODES_REPLACE_FAILED'
export const LATEST_EPISODES_REPLACE_CANCELLED = 'LATEST_EPISODES_REPLACE_CANCELLED'
export const LATEST_EPISODES_REPLACE_CLEARED = 'LATEST_EPISODES_REPLACE_CLEARED'

export const SORT_TOGGLE = 'SORT_TOGGLE'

const seriesSubmissionSettingsLoading = payload => {
  return {
    type: SERIES_SUBMISSION_SETTINGS_LOADING,
    loading: true,
    payload
  }
}

const seriesSubmissionSettingsLoaded = payload => {
  return {
    type: SERIES_SUBMISSION_SETTINGS_LOADED,
    loading: false,
    payload
  }
}

const seriesSubmissionSettingsFailed = error => {
  return {
    type: SERIES_SUBMISSION_SETTINGS_FAILED,
    loading: false,
    error: error.toString()
  }
}

const seriesLoading = payload => {
  return {
    type: SERIES_LOADING,
    loading: true,
    payload
  }
}

const seriesLoaded = payload => {
  return {
    type: SERIES_LOADED,
    loading: false,
    payload
  }
}

const seriesFailed = error => {
  return {
    type: SERIES_FAILED,
    loading: false,
    error: error.toString()
  }
}

const seriesCancelled = () => {
  return {
    type: SERIES_CANCELLED,
    loading: false
  }
}

export const seriesCleared = () => {
  return {
    type: SERIES_CLEARED,
    loading: false
  }
}

export const fetchSeriesSubmissionsById = seriesId => dispatch => {
  process.browser && seriesSubmissionsAbortController.abort()
  seriesSubmissionsAbortController = new AbortController()
  setTimeout(() => seriesSubmissionsAbortController.abort(), 2000)

  dispatch(seriesSubmissionSettingsLoading({ id: seriesId }))

  const fetchSeriesSubmissionsPromise = fetchSeriesSubmissionSettings(seriesId, {
    signal: seriesSubmissionsAbortController.signal
  })
    .then(seriesSubmissions => {
      return dispatch(seriesSubmissionSettingsLoaded({ id: seriesId, ...seriesSubmissions.submissions }))
    })
    .catch(error => {
      dispatch(seriesSubmissionSettingsFailed(error))
      logger.error(`Failed fetching series submission settings: ${error.message}`)
      return Promise.reject(error)
    })

  return {
    abort: () => seriesSubmissionsAbortController.abort(),
    loadDataPromise: fetchSeriesSubmissionsPromise
  }
}

export const fetchSeriesById = seriesId => dispatch => {
  process.browser && seriesAbortController.abort()
  seriesAbortController = new AbortController()

  dispatch(seriesLoading({ series: { id: seriesId }, type: 'series' }))

  const fetchSeriesPromise = fetchCatalogSeries(seriesId, { signal: seriesAbortController.signal })
    .then(series => {
      return dispatch(seriesLoaded(series))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        dispatch(seriesCancelled())
      } else {
        logger.error(`Failed fetching series: ${error.message}`)
        dispatch(seriesFailed(error))
      }
      return Promise.reject(error)
    })

  return {
    abort: () => seriesAbortController.abort(),
    loadDataPromise: fetchSeriesPromise
  }
}

export const fetchPodcastById = seriesId => dispatch => {
  process.browser && seriesAbortController.abort()
  seriesAbortController = new AbortController()

  dispatch(seriesLoading({ series: { id: seriesId }, type: 'podcast' }))

  const fetchPodcastPromise = fetchCatalogPodcast(seriesId, { signal: seriesAbortController.signal })
    .then(series => {
      return dispatch(seriesLoaded(series))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        dispatch(seriesCancelled())
      } else {
        logger.error(`Failed fetching series: ${error.message}`)
        dispatch(seriesFailed(error))
      }
      return Promise.reject(error)
    })

  return {
    abort: () => seriesAbortController.abort(),
    loadDataPromise: fetchPodcastPromise
  }
}
const seasonLoading = payload => {
  return {
    type: SEASON_LOADING,
    loading: true,
    payload
  }
}

const seasonLoaded = payload => {
  return {
    type: SEASON_LOADED,
    loading: false,
    payload
  }
}

const seasonFailed = error => {
  return {
    type: SEASON_FAILED,
    loading: false,
    error: error.toString()
  }
}

const seasonCancelled = () => {
  return {
    type: SEASON_CANCELLED,
    loading: false
  }
}

export const seasonCleared = () => {
  return {
    type: SEASON_CLEARED,
    loading: false
  }
}

export const fetchSeason = ({ href }) => dispatch => {
  process.browser && seasonAbortController.abort()
  seasonAbortController = new AbortController()
  dispatch(seasonLoading({ _links: { self: { href } } }))

  const fetchSeasonPromise = fetchCatalogHref({ href }, { signal: seasonAbortController.signal })
    .then(season => {
      return dispatch(seasonLoaded(season))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        dispatch(seasonCancelled())
      } else {
        logger.error(`Failed fetching season: ${error.message}`)
        dispatch(seasonFailed(error))
      }
      return Promise.reject(error)
    })

  return {
    loadDataPromise: fetchSeasonPromise,
    abort: () => seasonAbortController.abort()
  }
}

export const fetchSeasonById = (seriesId, seasonId) => dispatch => {
  process.browser && seasonAbortController.abort()
  seasonAbortController = new AbortController()
  dispatch(seasonLoading({ seriesId, seasonId }))

  const fetchSeasonPromise = fetchCatalogSeason(seriesId, seasonId, { signal: seasonAbortController.signal })
    .then(season => {
      return dispatch(seasonLoaded(season))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        dispatch(seasonCancelled())
      } else {
        logger.error(`Failed fetching season: ${error.message}`)
        dispatch(seasonFailed(error))
      }
      return Promise.reject(error)
    })

  return {
    loadDataPromise: fetchSeasonPromise,
    abort: () => seasonAbortController.abort()
  }
}

export const fetchPodcastSeasonById = (seriesId, seasonId) => dispatch => {
  process.browser && seasonAbortController.abort()
  seasonAbortController = new AbortController()
  dispatch(seasonLoading({ seriesId, seasonId }))

  const fetchSeasonPromise = fetchCatalogPodcastSeason(seriesId, seasonId, { signal: seasonAbortController.signal })
    .then(season => {
      return dispatch(seasonLoaded(season))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        dispatch(seasonCancelled())
      } else {
        logger.error(`Failed fetching season: ${error.message}`)
        dispatch(seasonFailed(error))
      }
      return Promise.reject(error)
    })

  return {
    loadDataPromise: fetchSeasonPromise,
    abort: () => seasonAbortController.abort()
  }
}

const seasonEpisodesReplaceLoading = payload => {
  return {
    type: SEASON_EPISODES_REPLACE_LOADING,
    loading: true,
    payload
  }
}

const seasonEpisodesReplaceLoaded = payload => {
  return {
    type: SEASON_EPISODES_REPLACE_LOADED,
    loading: false,
    payload
  }
}

const seasonEpisodesReplaceFailed = error => {
  return {
    type: SEASON_EPISODES_REPLACE_FAILED,
    loading: false,
    error: error.toString()
  }
}

const seasonEpisodesCancelled = () => {
  return {
    type: SEASON_EPISODES_REPLACE_CANCELLED,
    loading: false
  }
}

export const seasonEpisodesCleared = () => {
  return {
    type: SEASON_EPISODES_REPLACE_CLEARED,
    loading: false
  }
}

export const replaceSeasonEpisodes = ({ href }) => dispatch => {
  process.browser && seasonAbortController.abort()
  seasonAbortController = new AbortController()

  dispatch(seasonEpisodesReplaceLoading({ href }))

  const fetchSeasonEpisodesPromise = fetchCatalogHref({ href }, { signal: seasonAbortController.signal })
    .then(season => {
      return dispatch(seasonEpisodesReplaceLoaded(season))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        dispatch(seasonEpisodesCancelled())
      } else {
        logger.error(`Failed fetching season episodes: ${error.message}`)
        dispatch(seasonEpisodesReplaceFailed(error))
      }
      return Promise.reject(error)
    })

  return {
    loadDataPromise: fetchSeasonEpisodesPromise,
    abort: () => seasonAbortController.abort()
  }
}

export const replaceSeasonEpisodesById = (seriesId, seasonId, queryParams) => dispatch => {
  // seasonAbortController.abort()
  seasonAbortController = new AbortController()

  dispatch(seasonEpisodesReplaceLoading({ seriesId, seasonId }))

  const fetchSeasonEpisodesPromise = fetchCatalogSeasonEpisodes(seriesId, seasonId, queryParams, {
    signal: seasonAbortController.signal
  })
    .then(season => {
      return dispatch(seasonEpisodesReplaceLoaded({ ...season, ...queryParams }))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        dispatch(seasonEpisodesCancelled())
      } else {
        logger.error(`Failed fetching season episodes: ${error.message}`)
        dispatch(seasonEpisodesReplaceFailed(error))
      }
      return Promise.reject(error)
    })

  return {
    loadDataPromise: fetchSeasonEpisodesPromise,
    abort: () => seasonAbortController.abort()
  }
}

export const replacePodcastSeasonEpisodesById = (seriesId, seasonId, queryParams) => dispatch => {
  process.browser && seasonAbortController.abort()
  seasonAbortController = new AbortController()
  dispatch(seasonEpisodesReplaceLoading({ seriesId, seasonId }))

  const fetchSeasonEpisodesPromise = fetchCatalogPodcastSeasonEpisodes(seriesId, seasonId, queryParams, {
    signal: seasonAbortController.signal
  })
    .then(season => {
      return dispatch(seasonEpisodesReplaceLoaded({ ...season, ...queryParams }))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        dispatch(seasonEpisodesCancelled())
      } else {
        logger.error(`Failed fetching season episodes: ${error.message}`)
        dispatch(seasonEpisodesReplaceFailed(error))
      }
      return Promise.reject(error)
    })

  return {
    loadDataPromise: fetchSeasonEpisodesPromise,
    abort: () => seasonAbortController.abort()
  }
}

const latestEpisodesReplaceLoading = payload => {
  return {
    type: LATEST_EPISODES_REPLACE_LOADING,
    loading: true,
    payload
  }
}

const latestEpisodesReplaceLoaded = payload => {
  return {
    type: LATEST_EPISODES_REPLACE_LOADED,
    loading: false,
    payload
  }
}

const latestEpisodesReplaceFailed = error => {
  return {
    type: LATEST_EPISODES_REPLACE_FAILED,
    loading: false,
    error: error.toString()
  }
}

const leatestEpisodesCancelled = () => {
  return {
    type: LATEST_EPISODES_REPLACE_CANCELLED,
    loading: false
  }
}

let replaceLatestEpisodesController = noopAbortController

export const replaceLatestEpisodesById = (seriesId, queryParams) => dispatch => {
  process.browser && replaceLatestEpisodesController.abort()
  replaceLatestEpisodesController = new AbortController()

  dispatch(latestEpisodesReplaceLoading({ seriesId, queryParams }))

  const replaceLatestEpisodesPromise = fetchCatalogSeasonEpisodes(seriesId, null, queryParams, {
    signal: seasonAbortController.signal
  })
    .then(season => {
      return dispatch(latestEpisodesReplaceLoaded(season))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        dispatch(leatestEpisodesCancelled())
      } else {
        logger.error(`Failed fetching season episodes: ${error.message}`)
        dispatch(latestEpisodesReplaceFailed(error))
      }
      return Promise.reject(error)
    })

  return {
    loadDataPromise: replaceLatestEpisodesPromise,
    abort: () => replaceLatestEpisodesController.abort()
  }
}

export const replaceLatestPodcastEpisodesById = (podcastId, queryParams) => dispatch => {
  process.browser && replaceLatestEpisodesController.abort()
  replaceLatestEpisodesController = new AbortController()

  dispatch(latestEpisodesReplaceLoading({ seriesId: podcastId, queryParams }))

  const replaceMoreLatestEpisodesPromise = fetchCatalogPodcastSeasonEpisodes(podcastId, null, queryParams, {
    signal: seasonAbortController.signal
  })
    .then(season => {
      return dispatch(latestEpisodesReplaceLoaded(season))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        dispatch(leatestEpisodesCancelled())
      } else {
        logger.error(`Failed fetching season episodes: ${error.message}`)
        dispatch(latestEpisodesReplaceFailed(error))
      }
      return Promise.reject(error)
    })

  return {
    loadDataPromise: replaceMoreLatestEpisodesPromise,
    abort: () => replaceLatestEpisodesController.abort()
  }
}

const seasonPageLoading = payload => {
  return {
    type: SEASON_PAGE_LOADING,
    loading: true,
    payload
  }
}

const seasonPageLoaded = payload => {
  return {
    type: SEASON_PAGE_LOADED,
    loading: false,
    payload
  }
}

const seasonPageFailed = error => {
  return {
    type: SEASON_PAGE_FAILED,
    loading: false,
    error
  }
}

const seasonPageCancelled = () => {
  return {
    type: SEASON_PAGE_CANCELLED,
    loading: false
  }
}

let seasonPageAbortController = noopAbortController

export const fetchSeasonPage = ({ href }) => dispatch => {
  process.browser && seasonPageAbortController.abort()
  seasonPageAbortController = new AbortController()

  dispatch(seasonPageLoading({ href }))

  const fetchSeasonPagePromise = fetchCatalogHref({ href })
    .then(seasonPage => {
      return dispatch(seasonPageLoaded(seasonPage))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        dispatch(seasonPageCancelled())
      } else {
        logger.error(`Failed fetching season page: ${error.message}`)
        dispatch(seasonPageFailed(error))
      }
      return Promise.reject(error)
    })

  return {
    loadDataPromise: fetchSeasonPagePromise,
    abort: () => seasonPageAbortController.abort()
  }
}

const latestEpisodePageLoading = payload => {
  return {
    type: LATEST_EPISODES_PAGE_LOADING,
    loading: true,
    payload
  }
}

const latestEpisodePageLoaded = payload => {
  return {
    type: LATEST_EPISODES_PAGE_LOADED,
    loading: false,
    payload
  }
}

const latestEpisodePageFailed = error => {
  return {
    type: LATEST_EPISODES_PAGE_FAILED,
    loading: false,
    error
  }
}

const latestEpisodePageCancelled = () => {
  return {
    type: LATEST_EPISODES_PAGE_CANCELLED,
    loading: false
  }
}

let latestEpisodePageAbortController = noopAbortController

export const fetchLatestEpisodesPage = ({ href }) => dispatch => {
  process.browser && latestEpisodePageAbortController.abort()
  latestEpisodePageAbortController = new AbortController()

  dispatch(latestEpisodePageLoading({ href }))

  const fetchLatestEpisodesPagePromise = fetchCatalogHref({ href })
    .then(latestEpisodesPage => {
      return dispatch(latestEpisodePageLoaded(latestEpisodesPage))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        dispatch(latestEpisodePageCancelled())
      } else {
        logger.error(`Failed fetching latestEpisodes page: ${error.message}`)
        dispatch(latestEpisodePageFailed(error))
      }
      return Promise.reject(error)
    })

  return {
    loadDataPromise: fetchLatestEpisodesPagePromise,
    abort: () => latestEpisodePageAbortController.abort()
  }
}

const episodeLoading = payload => {
  return {
    type: EPISODE_LOADING,
    loading: true,
    payload
  }
}

const episodeLoaded = payload => {
  return {
    type: EPISODE_LOADED,
    loading: false,
    payload
  }
}

const episodeCancelled = () => {
  return {
    type: EPISODE_CANCELLED,
    loading: false
  }
}

export const episodeCleared = () => {
  return {
    type: EPISODE_CLEARED,
    loading: false
  }
}

export const resetEpisodes = () => {
  return { type: RESET_EPISODES }
}

export const setEpisodeLoading = loading => {
  return { type: SET_EPISODE_LOADING, loading }
}

export const fetchEpisode = ({ href }) => dispatch => {
  process.browser && episodeAbortController.abort()
  episodeAbortController = new AbortController()

  dispatch(episodeLoading({ href }))

  const fetchEpisodePromise = fetchCatalogHref({ href }, { signal: episodeAbortController.signal })
    .then(episode => {
      return dispatch(episodeLoaded(episode))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        dispatch(episodeCancelled())
      } else {
        logger.error(`Failed fetching episode: ${error.message}`)
      }
      return Promise.reject(error)
    })

  return {
    loadDataPromise: fetchEpisodePromise,
    abort: () => episodeAbortController.abort()
  }
}

export const fetchEpisodeById = episodeId => dispatch => {
  process.browser && episodeAbortController.abort()
  episodeAbortController = new AbortController()

  dispatch(episodeLoading({ episodeId }))

  const fetchEpisodePromise = fetchCatalogProgram(episodeId, { signal: episodeAbortController.signal })
    .then(episode => {
      return dispatch(episodeLoaded(episode))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        dispatch(episodeCancelled())
      } else {
        logger.error(`Failed fetching episode: ${error.message}`)
      }
      return Promise.reject(error)
    })

  return {
    loadDataPromise: fetchEpisodePromise,
    abort: () => episodeAbortController.abort()
  }
}

export const fetchPodcastEpisodeById = (seriesId, episodeId) => dispatch => {
  process.browser && episodeAbortController.abort()
  episodeAbortController = new AbortController()

  dispatch(episodeLoading({ episodeId }))

  const fetchPodcastEpisodePromise = fetchCatalogPodcastEpisode(seriesId, episodeId, {
    signal: episodeAbortController.signal
  })
    .then(episode => {
      return dispatch(episodeLoaded(episode))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        dispatch(episodeCancelled())
      } else {
        logger.error(`Failed fetching podcastEpisode: ${error.message}`)
      }
      return Promise.reject(error)
    })

  return {
    loadDataPromise: fetchPodcastEpisodePromise,
    abort: () => episodeAbortController.abort()
  }
}

export const toggleSort = () => {
  return {
    type: SORT_TOGGLE
  }
}
