import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { startPollingLivebufferNow } from './actions'
import PropTypes from 'prop-types'
import { useRouteMatch } from 'react-router-dom'
import { useIsFullscreen } from '../StickyPlayer/context/StickyPlayerContext'
import { useCurrentlyPlayingMediaType } from '../StickyPlayer/context/PlayerMetadataContext'
import { MEDIA_TYPES } from '../StickyPlayer/helpers/mediaTypes'

const LivebufferNowPoller = ({ children }) => {
  const dispatch = useDispatch()
  const rootRouteMatch = useRouteMatch({ path: '/', exact: true })
  const directChannelPageMatch = useRouteMatch({ path: '/direkte/:channelId?' })
  const searchPageMatch = useRouteMatch({ path: '/sok' })
  const playerIsFullscreen = useIsFullscreen()
  const mediaType = useCurrentlyPlayingMediaType()
  const shouldPollLivebufferNow =
    !!rootRouteMatch ||
    !!directChannelPageMatch ||
    !!searchPageMatch ||
    (playerIsFullscreen && mediaType === MEDIA_TYPES.CHANNEL)

  useEffect(() => {
    if (shouldPollLivebufferNow) {
      const { stopPollingLivebufferNow } = dispatch(startPollingLivebufferNow())
      return () => {
        stopPollingLivebufferNow()
      }
    }
  }, [dispatch, shouldPollLivebufferNow])

  return <>{children}</>
}

LivebufferNowPoller.propTypes = {
  children: PropTypes.node
}

export default LivebufferNowPoller
