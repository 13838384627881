import { FRONTPAGE_LOADING, FRONTPAGE_LOADED, FRONTPAGE_LOADING_FAILED, FRONTPAGE_CLEARED } from './actions'

const initialState = {
  sections: [],
  loading: false,
  segment: null,
  error: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FRONTPAGE_LOADING: {
      return { ...state, loading: action.loading }
    }
    case FRONTPAGE_LOADED: {
      return { ...state, loading: action.loading, ...action.payload }
    }
    case FRONTPAGE_LOADING_FAILED: {
      return { ...state, loading: action.loading, sections: [], error: action.error.toString() }
    }
    case FRONTPAGE_CLEARED: {
      return initialState
    }
    default: {
      return state
    }
  }
}
