import logger from '../../../logger'
import { fetchPodcastSeasonById, fetchSeasonById } from '../actions'
import { fetchExtraMaterial, fetchFallbackClip } from './extraMaterialActions'
const serverSideSeasonExtraMaterialFetch = async (store, { match }) => {
  const { contentType, seriesId, extraMaterialId, seasonId } = match.params
  const fetchSeries = contentType === 'serie' ? fetchSeasonById : fetchPodcastSeasonById

  try {
    const { payload: seriesPayload } = await store.dispatch(fetchSeries(seriesId, seasonId)).loadDataPromise

    if (!seriesPayload._links?.extraMaterial?.href) {
      const notFoundError = new Error('Not found')
      notFoundError.status = 404
      return Promise.reject(notFoundError)
    }

    const { payload: extraMaterialPayload } = await store.dispatch(
      fetchExtraMaterial(seriesPayload._links.extraMaterial.href)
    ).loadDataPromise
    if (extraMaterialId && !extraMaterialPayload._embedded.metadata.some(({ id }) => id === extraMaterialId)) {
      logger.info(`${extraMaterialId} is not in first page of extramaterial. Loading fallback`)
      await store.dispatch(fetchFallbackClip(extraMaterialId)).loadDataPromise
    }
    return extraMaterialPayload
  } catch (error) {
    return Promise.reject(error)
  }
}

export default serverSideSeasonExtraMaterialFetch
