import {
  PLAYER_LIVEELEMENTS_LOADING,
  PLAYER_LIVEELEMENTS_LOADED,
  PLAYER_LIVEELEMENTS_FAILED
} from './liveElementActions'

const initialState = {
  error: null,
  indexPoints: {
    loading: true,
    type: 'live',
    error: null,
    data: []
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PLAYER_LIVEELEMENTS_LOADING:
      return action.clear
        ? { ...state, indexPoints: { ...initialState.indexPoints, loading: action.loading } }
        : { ...state, indexPoints: { ...state.indexPoints, loading: action.loading, error: null } }
    case PLAYER_LIVEELEMENTS_LOADED:
      return { ...state, indexPoints: { ...state.indexPoints, loading: false, error: null, ...action.payload } }
    case PLAYER_LIVEELEMENTS_FAILED:
      return { ...state, indexPoints: { ...initialState.indexPoints, loading: false, error: action.error } }
    default: {
      return state
    }
  }
}
