import _get from 'lodash/get'
import _unionBy from 'lodash/unionBy'
import {
  PROGRAM_PROGRESS_RESET,
  PROGRAM_PROGRESS_LOADING,
  PROGRAM_PROGRESS_LOADED,
  PROGRAM_PROGRESS_FAILED,
  PROGRAM_PROGRESS_SAVING,
  PROGRAM_PROGRESS_SAVED,
  PROGRAM_PROGRESS_SAVE_FAILED,
  PROGRAMS_PROGRESS_LIST_CLEARED,
  PROGRAMS_PROGRESS_LIST_LOADING,
  PROGRAMS_PROGRESS_LIST_LOADED,
  PROGRAMS_PROGRESS_LIST_FAILED
} from './progressActions'
import secondsToIsoDurationTime from './helpers/secondsToIsoDurationTime'
import PROGRESS_STATES from './helpers/progressStates'
import { durationToSeconds } from '../../common/parseIsoDuration'

const initialState = {
  progresses: { items: [] },
  progressInPlayer: {
    currentIntervalMs: 5000,
    reportInterval: {
      notBefore: secondsToIsoDurationTime(5),
      notBeforeMs: 5000,
      interval: secondsToIsoDurationTime(30),
      intervalMs: 30000
    },
    startPlaybackPosition: secondsToIsoDurationTime(0),
    _links: { self: { href: null } }
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PROGRAM_PROGRESS_RESET: {
      return { ...state, progressInPlayer: { ...initialState.progressInPlayer } }
    }
    case PROGRAM_PROGRESS_LOADING: {
      return {
        ...state,
        progressInPlayer: { ...initialState.progressInPlayer, ...action.payload, loading: action.loading }
      }
    }
    case PROGRAM_PROGRESS_LOADED: {
      if (state.progressInPlayer.contentId === action.payload.contentId) {
        return {
          ...state,
          progressInPlayer: {
            ...state.progressInPlayer,
            currentIntervalMs: action.payload.reportInterval.notBeforeMs,
            ...action.payload,
            loading: action.loading
          },
          progresses: {
            items: [
              ...state.progresses.items.filter(item => item.episodeId !== action.payload.episodeId),
              action.payload
            ],
            ...state.progresses
          }
        }
      }
      return state
    }
    case PROGRAM_PROGRESS_FAILED: {
      if (state.progressInPlayer.contentId === action.payload.contentId) {
        return { ...state, progressInPlayer: { ...action.payload, ...state.progressInPlayer, loading: action.loading } }
      }
      return state
    }
    case PROGRAM_PROGRESS_SAVING:
    case PROGRAM_PROGRESS_SAVED:
    case PROGRAM_PROGRESS_SAVE_FAILED: {
      if (state.progressInPlayer._links.self.href === action.payload._links.self.href) {
        return {
          ...state,
          progresses: updateProgressesWithProgressFromPlayer(state, action),
          progressInPlayer: updateProgressInPlayer(state, action)
        }
      }
      return state
    }
    case PROGRAMS_PROGRESS_LIST_CLEARED: {
      return { ...state, progresses: { ...initialState.progresses } }
    }
    case PROGRAMS_PROGRESS_LIST_LOADING: {
      return { ...state, progresses: { ...state.progresses, ...action.payload, loading: action.loading, error: null } }
    }
    case PROGRAMS_PROGRESS_LIST_LOADED: {
      const newItems = _get(action, 'payload', []).map(progress => {
        const episodeHref = _get(progress, '_links.programs.href') || _get(progress, '_links.podcastEpisode.href') || ''

        const [episodeId] = episodeHref.split('/').reverse()

        if (progress.progress === PROGRESS_STATES.IN_PROGRESS) {
          const inProgress = {
            ...progress.inProgress,
            timeInSeconds: durationToSeconds(progress.inProgress.time)
          }
          return { ...progress, episodeId, inProgress }
        }

        return { ...progress, episodeId }
      })
      const nextItems = _unionBy(newItems, state.progresses.items, 'episodeId')
      return {
        ...state,
        progresses: {
          error: null,
          loading: action.loading,
          items: nextItems
        }
      }
    }
    case PROGRAMS_PROGRESS_LIST_FAILED: {
      return { ...state, progresses: { ...state.progresses, loading: action.loading, error: action.error.toString() } }
    }
    default:
      return state
  }
}

export default reducer

function updateProgressesWithProgressFromPlayer(state, action) {
  if (state.progresses.items.some(({ episodeId }) => episodeId === state.progressInPlayer.episodeId)) {
    return {
      ...state.progresses,
      items: state.progresses.items.map(item => {
        if (item.episodeId === state.progressInPlayer.episodeId) {
          return {
            ...item,
            progress: PROGRESS_STATES.IN_PROGRESS,
            inProgress: {
              percentage: action.payload.localProgress.percentage,
              time: action.payload.localProgress.time,
              timeInSeconds: durationToSeconds(action.payload.localProgress.time)
            }
          }
        }
        return item
      })
    }
  }

  return {
    ...state.progresses,
    items: [
      ...state.progresses.items,
      {
        episodeId: state.progressInPlayer.episodeId,
        progress: PROGRESS_STATES.IN_PROGRESS,
        inProgress: {
          percentage: action.payload.localProgress.percentage,
          time: action.payload.localProgress.time,
          timeInSeconds: durationToSeconds(action.payload.localProgress.time)
        }
      }
    ]
  }
}

function updateProgressInPlayer(state, action) {
  return {
    ...state.progressInPlayer,
    currentIntervalMs: state.progressInPlayer.reportInterval.intervalMs,
    ...action.payload,
    loading: action.loading
  }
}
