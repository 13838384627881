import { fetchAlphabeticIndexedCategory, fetchSearchPageByHref } from '../../../apiClients/psapi'
import logger from '../../../logger'

export const LETTERS_LOADING = 'LETTERS_LOADING'
export const LETTERS_LOADED = 'LETTERS_LOADED'
export const LETTERS_LOADING_FAILED = 'LETTERS_LOADING_FAILED'
export const LETTERS_LOADING_CANCELLED = 'LETTERS_LOADING_CANCELLED'
export const LETTERS_CLEARED = 'LETTERS_CLEARED'

export const LETTER_LOADING = 'LETTER_LOADING'
export const LETTER_LOADED = 'LETTER_LOADED'
export const LETTER_LOADING_FAILED = 'LETTER_LOADING_FAILED'
export const LETTER_LOADING_CANCELLED = 'LETTER_LOADING_CANCELLED'

export const LETTER_PAGE_LOADING = 'LETTER_PAGE_LOADING'
export const LETTER_PAGE_LOADED = 'LETTER_PAGE_LOADED'
export const LETTER_PAGE_LOADING_FAILED = 'LETTER_PAGE_LOADING_FAILED'
export const LETTER_PAGE_LOADING_CANCELLED = 'LETTER_PAGE_LOADING_CANCELLED'

const lettersLoading = payload => {
  return {
    type: LETTERS_LOADING,
    loading: true,
    payload
  }
}

const lettersLoaded = payload => {
  return {
    type: LETTERS_LOADED,
    loading: false,
    payload
  }
}

const lettersLoadingFailed = (error, payload) => {
  return {
    type: LETTERS_LOADING_FAILED,
    loading: false,
    error,
    payload
  }
}

export const clearLetters = () => {
  return {
    type: LETTERS_CLEARED
  }
}

const letterLoading = payload => {
  return {
    type: LETTER_LOADING,
    loading: true,
    payload
  }
}

const letterLoaded = payload => {
  return {
    type: LETTER_LOADED,
    loading: false,
    payload
  }
}

const letterLoadingFailed = (error, payload) => {
  return {
    type: LETTER_LOADING_FAILED,
    loading: false,
    error,
    payload
  }
}

const letterPageLoading = payload => {
  return {
    type: LETTER_PAGE_LOADING,
    loading: true,
    payload
  }
}

const letterPageLoaded = payload => {
  return {
    type: LETTER_PAGE_LOADED,
    loading: false,
    payload
  }
}

const letterPageLoadingFailed = (error, payload) => {
  return {
    type: LETTER_PAGE_LOADING_FAILED,
    loading: false,
    error,
    payload
  }
}

export const pageSize = 50

export const fetchInitialData = ({ categoryId, initialLetter }) => dispatch => {
  dispatch(lettersLoading({ categoryId }))
  const abortControllerLetters = new AbortController()
  const abortControllerLetter = new AbortController()
  const loadDataPromise = fetchAlphabeticIndexedCategory(categoryId, {
    signal: abortControllerLetters.signal,
    take: pageSize
  })
    .then(({ letters, title }) => {
      const reorderedLetters = letters[0].letter === '#' ? [...letters.slice(1), letters[0]] : letters
      dispatch(lettersLoaded({ letters: reorderedLetters, categoryId, title }))
      const foundLetter = initialLetter && reorderedLetters.find(({ letter }) => letter === initialLetter.toUpperCase())
      const letter = foundLetter || reorderedLetters[0]
      dispatch(letterLoading({ letter: letter.letter }))
      return fetchSearchPageByHref(letter.link, { signal: abortControllerLetter.signal })
        .then(({ _links, series }) => {
          dispatch(letterLoaded({ _links, series, letter: letter.letter }))
        })
        .catch(error => {
          logger.error('Loading letter failed', error)
          dispatch(letterLoadingFailed(error, { letter: letter.letter }))
        })
    })
    .catch(error => {
      logger.error('Loading letters failed', error)
      dispatch(lettersLoadingFailed(error, { categoryId }))
    })

  return {
    loadDataPromise,
    abort: () => {
      abortControllerLetters.abort()
      abortControllerLetter.abort()
    }
  }
}

export const fetchCategoryLetterByLink = ({ link, letter }) => dispatch => {
  dispatch(letterLoading({ letter }))
  const abortController = new AbortController()
  const loadDataPromise = fetchSearchPageByHref(link, {
    signal: abortController.signal
  })
    .then(({ _links, series }) => {
      dispatch(letterLoaded({ _links, series, letter }))
    })
    .catch(error => {
      dispatch(letterLoadingFailed(error, { letter }))
    })
  return {
    loadDataPromise,
    abort: () => {
      abortController.abort()
    }
  }
}

export const fetchCategoryLetterPageByHref = ({ href, letter }) => dispatch => {
  dispatch(letterPageLoading({ letter }))
  const abortController = new AbortController()
  const loadDataPromise = fetchSearchPageByHref(href, {
    signal: abortController.signal
  })
    .then(({ _links, series }) => {
      dispatch(letterPageLoaded({ _links, series, letter }))
    })
    .catch(error => {
      dispatch(letterPageLoadingFailed(error, { letter }))
    })

  return {
    loadDataPromise,
    abort: () => {
      abortController.abort()
    }
  }
}
