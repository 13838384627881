import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import FourOhFour from '../pages/404'
import { useLocation } from 'react-router-dom'

/**
 * http-status is set on staticContext. staticContext is only available serverside.
   To keep showing an error component on the client, serverStatus is injected in the html from the server and scoped globally from window
 */
const ServerSideHttpStatusHandler = props => {
  const [serverStatus, setServerStatus] = useState({})
  const location = useLocation()

  useEffect(() => {
    setServerStatus(window.__SERVERSIDE_STATUS__)
    return () => {
      setServerStatus({})
      window.__SERVERSIDE_STATUS__ = {}
    }
  }, [])

  if ((props.staticContext && props.staticContext.status >= 400) || serverStatus[location.pathname] >= 400) {
    return <FourOhFour {...props} />
  }
  return <>{props.children}</>
}

ServerSideHttpStatusHandler.propTypes = {
  staticContext: PropTypes.shape({ status: PropTypes.number }),
  children: PropTypes.node
}

export default ServerSideHttpStatusHandler
