import {
  CATEGORIES_LOADING,
  CATEGORIES_LOADED,
  CATEGORIES_LOADING_CANCELLED,
  CATEGORIES_LOADING_FAILED
} from './actions'

const initialState = {
  loading: false,
  error: null,
  cancelled: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORIES_LOADING: {
      return { ...initialState, loading: action.loading }
    }
    case CATEGORIES_LOADED: {
      return { ...initialState, loading: action.loading, ...action.payload }
    }
    case CATEGORIES_LOADING_CANCELLED: {
      return { ...initialState, cancelled: action.cancelled }
    }
    case CATEGORIES_LOADING_FAILED: {
      return { ...initialState, loading: action.loading, error: action.error.toString() }
    }
    default: {
      return state
    }
  }
}

export default reducer
