import { getInitialChannels } from '../../common/liveChannels'
import {
  LIVEBUFFER_NOW_LOADING,
  LIVEBUFFER_NOW_LOADED,
  LIVEBUFFER_NOW_FAILED,
  UPDATE_SORTED,
  LIVEBUFFER_FOR_CHANNEL_LOADING,
  LIVEBUFFER_FOR_CHANNEL_LOADED,
  LIVEBUFFER_FOR_CHANNEL_FAILED,
  LIVEBUFFER_FOR_CHANNEL_CLEARED
} from './actions'

const initialChannels = getInitialChannels('p1_oslo_akershus')

const initialState = {
  channels: initialChannels,
  loading: false,
  channel: { loading: false }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LIVEBUFFER_NOW_LOADING: {
      return { ...state, loading: action.loading }
    }
    case LIVEBUFFER_NOW_LOADED: {
      return { ...state, channels: action.payload, loading: action.loading }
    }
    case LIVEBUFFER_NOW_FAILED: {
      return { ...state, loading: action.loading }
    }
    case UPDATE_SORTED: {
      return { ...state, channels: [...state.channels] }
    }
    case LIVEBUFFER_FOR_CHANNEL_LOADING: {
      return { ...state, channel: { loading: action.loading } }
    }
    case LIVEBUFFER_FOR_CHANNEL_LOADED: {
      return { ...state, channel: { ...action.payload.channel, loading: action.loading } }
    }
    case LIVEBUFFER_FOR_CHANNEL_FAILED: {
      return { ...state, channel: { ...state.channel, loading: action.loading, error: action.error.toString() } }
    }
    case LIVEBUFFER_FOR_CHANNEL_CLEARED: {
      return { ...state, channel: initialState.channel }
    }
    default: {
      return state
    }
  }
}
