import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import Box from '../RadioUI/Box'
import Flex from '../RadioUI/Flex'
import Text from '../RadioUI/Text'
import ContentWidth from '../RadioUI/ContentWidth'
import sizes from '../RadioUI/theme/sizes'
import breakpoints from '../RadioUI/theme/breakpoints'
import typography from '../RadioUI/theme/typography'
import localeFormat from '../common/localeFormat'
import CustomButton from '../RadioUI/CustomButton'
import Icon from '../RadioUI/Icon'
import { Link } from 'react-router-dom'
import { useColors } from '../RadioUI/theme/Color'
import { useIsFullscreen } from '../components/StickyPlayer/context/StickyPlayerContext'
import { trackSnowplowEvent, spCategory } from '../clientMonitoring'

const listStyle = color => css`
  font-size: ${typography.fontSizes.sm};
  li {
    text-align: center;
    line-height: 1.5rem;
    a:hover,
    a:focus {
      color: ${color.light};
    }

    @media (min-width: ${breakpoints.md}) {
      padding-right: ${sizes[8]};
      display: inline-block;

      &:not(:first-of-type):before {
        position: relative;
        right: ${sizes[4]};
        content: '|';
        opacity: 0.2;
        font-size: ${typography.fontSizes.lg};
      }
    }
  }
`

const Footer = () => {
  const isPlayerFullscreen = useIsFullscreen()
  const colors = useColors()
  const [year, setYear] = useState()

  useEffect(() => {
    setYear(localeFormat(new Date(), 'YYYY'))
  }, [])

  return (
    <Box w="100%">
      <ContentWidth
        aria-hidden={isPlayerFullscreen}
        className="Footer"
        as="footer"
        pt="12"
        pb="32"
        borderTop="1px solid rgba(255, 255, 255, .1)"
        display="flex"
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex
          order={{ base: 1, md: 0 }}
          flexDir={{ base: 'column' }}
          alignItems={{ base: 'center', md: 'flex-start' }}
          css={css`
            & > * {
              padding-top: 0.625rem;
              padding-bottom: 0.625rem;
            }
          `}
        >
          <Box
            as="ul"
            css={css`
              font-size: ${typography.fontSizes.sm};
              a:hover,
              a:focus {
                color: ${colors.main.light};
              }
            `}
          >
            <li>
              <a href="https://www.nrk.no/etikk/opphavsrett-pa-nrk.no-1.2843522">Opphavsrett NRK © {year}</a>
            </li>
          </Box>
          <Box as="ul" opacity="0.7" css={listStyle(colors.main)}>
            <li>
              Nettsjef: <a href="https://www.nrk.no/etikk/redaktoransvar-pa-nrk.no-1.2843526">Hildegunn Soldal</a>
            </li>
            <li>
              Radiosjef: <a href="https://www.nrk.no/etikk/redaktoransvar-pa-nrk.no-1.2843526">Cathinka Rondan</a>
            </li>

            <li>
              Ansvarlig redaktør:{' '}
              <a href="https://www.nrk.no/etikk/redaktoransvar-pa-nrk.no-1.2843526">Vibeke Fürst Haugen</a>
            </li>
          </Box>

          <Box as="ul" css={listStyle(colors.main)}>
            <li>
              <a href="https://info.nrk.no/">Kontakt</a>
            </li>
            <li>
              <a href="https://www.nrk.no/informasjon/">Om NRK</a>
            </li>
            <li>
              <a href="http://www.nrk.no/retningslinjer/personvern-i-nrk-1.7765898">Personvern</a>
            </li>
            <li>
              <a href="http://www.nrk.no/retningslinjer/informasjonskapsler-_cookies_-1.11109868">
                Informasjonskapsler (cookies)
              </a>
            </li>
            <li>
              <Link to="/alle-programmer">A - Å</Link>
            </li>
          </Box>
        </Flex>
        <Box py="12" order={{ base: 0, md: 1 }}>
          <CustomButton
            as="a"
            type={null}
            href="https://info.nrk.no/radio/"
            size="sm"
            display="flex"
            alignItems="center"
            lineHeight="1rem"
            py="14px"
            px="4px"
            onClick={() => {
              trackSnowplowEvent(spCategory.Frontpage, 'HelpButtonTapped')
            }}
          >
            <Icon iconId="nrk-help" mr="1" fontSize="12px" />
            <Text as="span" fontSize="12px">
              Hjelp
            </Text>
          </CustomButton>
        </Box>
      </ContentWidth>
    </Box>
  )
}

export default Footer
