import PropTypes from 'prop-types'
import React, { createContext, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import usePageScroll from '../../../common/disablePageScroll'
// import { useIsFullscreen } from '../context/StickyPlayerContext'

const noop = () => {}

export const TogglePlaylistContext = createContext({
  isDialogHidden: true,
  showQueue: false,
  dialogRef: { current: {} },
  setShowQueue: noop,
  openDialog: noop,
  closeDialog: noop
})

const TogglePlaylistProvider = ({ children }) => {
  const [isDialogHidden, setIsDialogHidden] = useState(true)
  const [showQueue, setShowQueue] = useState(true)
  const dialogRef = useRef()
  const pageScroll = usePageScroll()

  const openDialog = useCallback(() => {
    if (dialogRef.current.showModal) {
      dialogRef.current.showModal()
      setIsDialogHidden(false)
    }
  }, [])

  const closeDialog = useCallback(() => {
    dialogRef.current.close()
    setIsDialogHidden(true)
  }, [])

  useLayoutEffect(() => {
    if (showQueue) {
      const scroller = dialogRef.current?.querySelector('.index-points-wrapper')
      if (scroller) {
        scroller.scrollTop = 0
      }
    }
  }, [showQueue, isDialogHidden])

  useLayoutEffect(() => {
    if (isDialogHidden) {
      pageScroll.enable()
    } else {
      pageScroll.disable()
    }
  }, [isDialogHidden, pageScroll])

  const value = useMemo(() => {
    return {
      dialogRef,
      isDialogHidden,
      showQueue,
      setShowQueue,
      openDialog,
      closeDialog,
      setIsDialogHidden
    }
  }, [closeDialog, isDialogHidden, openDialog, showQueue])

  return <TogglePlaylistContext.Provider value={value}>{children}</TogglePlaylistContext.Provider>
}

TogglePlaylistProvider.propTypes = {
  children: PropTypes.node
}

export default TogglePlaylistProvider
