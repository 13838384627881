import HttpAgent, { HttpsAgent } from 'agentkeepalive'
import clientConfig from '../clientConfig'

const SOCKET_TIMEOUT = 20000

const httpsAgent = new HttpsAgent({
  keepAlive: true,
  maxSockets: 10,
  maxFreeSockets: 10,
  timeout: SOCKET_TIMEOUT,
  freeSocketTimeout: SOCKET_TIMEOUT / 2
})

const httpAgent = new HttpAgent({
  keepAlive: true,
  maxSockets: 10,
  maxFreeSockets: 10,
  timeout: SOCKET_TIMEOUT,
  freeSocketTimeout: SOCKET_TIMEOUT / 2
})

const serverSideFetch = (url, options = {}) => {
  const headers = new Headers(options.headers)
  const { protocol, pathname = '' } = new URL(url)

  if (!pathname.startsWith('/psapi/')) {
    // Only add user-agent to non-proxied requests. The proxy adds its own.
    const postfix =
      clientConfig.SERVER_ENV === 'prod' || clientConfig.SERVER_ENV === 'local' ? '' : `-${clientConfig.SERVER_ENV}`
    const prevUserAgent = headers.get('user-agent') || ''
    const userAgent = `nrk-radio-web/${clientConfig.RELEASE_NUMBER}${postfix} ${prevUserAgent}`
    headers.set('user-agent', userAgent)
  }

  const serversideDefaultOptions = { agent: protocol === 'https:' ? httpsAgent : httpAgent }

  return fetch(url, {
    ...serversideDefaultOptions,
    ...options,
    ...{ headers }
  })
}

export default serverSideFetch
