import _get from 'lodash/get'
import SERIES_TYPES from './seriesTypes'

function latestEpisodesFromSeries(series) {
  const { seriesType } = series
  switch (seriesType) {
    case SERIES_TYPES.STANDARD: {
      const episodes = _get(series, '_embedded.episodes._embedded.episodes', [])
      const _links = _get(series, '_embedded.episodes._links')
      return { episodes, _links }
    }
    case SERIES_TYPES.SEQUENTIAL: {
      const episodes = _get(series, '_embedded.seasons[0].episodes._embedded.episodes', [])
      const _links = _get(series, '_embedded.seasons[0].episodes._links')
      return { episodes, _links }
    }
    case SERIES_TYPES.UMBRELLA: {
      return { episodes: [] }
    }
    default: {
      return { episodes: [] }
    }
  }
}

export default latestEpisodesFromSeries
