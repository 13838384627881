import { durationToSeconds } from '../../../common/parseIsoDuration'
import uuid from 'uuid/v4'
import differenceInSeconds from 'date-fns/difference_in_seconds'
import aspDateStringToDate from '../../../common/aspDateStringToDate'

const parseIndexPoints = metadata => {
  return (
    metadata.preplay?.indexPoints?.map(indexPoint => {
      return {
        id: uuid(),
        title: indexPoint.title,
        start: durationToSeconds(indexPoint.startPoint),
        end: durationToSeconds(indexPoint.startPoint),
        isMusic: false,
        artist: ''
      }
    }) || []
  )
}

const parsePlaylist = (playlist, metadata) => {
  return playlist.map(playlistItem => {
    const start = Math.max(
      0,
      differenceInSeconds(aspDateStringToDate(playlistItem.startTime), new Date(metadata.availability?.onDemand?.from))
    )
    return {
      id: uuid(),
      title: playlistItem.title,
      start,
      end: start + durationToSeconds(playlistItem.duration),
      isMusic: true,
      artist: playlistItem.description
    }
  })
}

export const mergeIndexPointsAndPlaylist = (metadata, playlist = []) => {
  const parsedIndexPoints = parseIndexPoints(metadata)

  if (playlist.length === 0) {
    return parsedIndexPoints
  }

  const parsedPlaylist = parsePlaylist(playlist, metadata)
  return [...parsedIndexPoints, ...parsedPlaylist].sort((a, b) => a.start - b.start)
}
