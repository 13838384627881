import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import PropTypes from 'prop-types'
import { setPersistedMuted, setPersistedVolume } from '../helpers/persistedVolume'
import logger from '../../../logger'

const initialState = {
  isPlaying: false,
  playingChangeDetail: {},
  error: null,
  isTimeRunning: false,
  mediaInfoFromPlayer: {},
  mediaRange: null,
  session: false,
  waiting: {},
  volume: 1,
  muted: false,
  rate: 1,
  hasPlayBeenTriggered: false,
  airplaySupported: false,
  airplayAvailable: false,
  airplayActive: false
}

const ACTION_TYPES = {
  IS_PLAYING_CHANGE: 'IS_PLAYING_CHANGE',
  ERROR: 'ERROR',
  MEDIA_INFO_LOADED: 'MEDIA_INFO_LOADED',
  MEDIA_RANGE_CHANGE: 'MEDIA_RANGE_CHANGE',
  TIME_RUNNING_CHANGE: 'TIME_RUNNING_CHANGE',
  CLEAR_MEDIA_RANGE: 'CLEAR_MEDIA_RANGE',
  CLEAR_PLAYER_ELEMENT_STATE: 'CLEAR_PLAYER_ELEMENT_STATE',
  SESSION_CREATED: 'SESSION_CREATED',
  SESSION_CLOSED: 'SESSION_CLOSED',
  WAITING_CHANGE: 'WAITING_CHANGE',
  VOLUME_CHANGE: 'VOLUME_CHANGE',
  MUTED_CHANGE: 'MUTED_CHANGE',
  RATE_CHANGE: 'RATE_CHANGE',
  ELEMENT_PANIC: 'ELEMENT_PANIC',
  AIRPLAY_SUPPORTED: 'AIRPLAY_SUPPORTED',
  AIRPLAY_AVAILABLE: 'AIRPLAY_AVAILABLE',
  AIRPLAY_ACTIVE: 'AIRPLAY_ACTIVE'
}

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.IS_PLAYING_CHANGE:
      return {
        ...state,
        hasPlayBeenTriggered: true,
        isPlaying: action.payload.isPlaying,
        playingChangeDetail: action.payload
      }
    case ACTION_TYPES.ERROR:
      return { ...state, error: action.payload }
    case ACTION_TYPES.MEDIA_RANGE_CHANGE:
    case ACTION_TYPES.MEDIA_INFO_LOADED:
    case ACTION_TYPES.TIME_RUNNING_CHANGE: {
      return { ...state, ...action.payload }
    }
    case ACTION_TYPES.SESSION_CREATED: {
      return { ...state, session: true }
    }
    case ACTION_TYPES.SESSION_CLOSED: {
      return { ...state, session: false }
    }
    case ACTION_TYPES.WAITING_CHANGE: {
      return { ...state, waiting: action.payload }
    }
    case ACTION_TYPES.VOLUME_CHANGE: {
      return { ...state, volume: action.payload }
    }
    case ACTION_TYPES.MUTED_CHANGE: {
      return { ...state, muted: action.payload }
    }
    case ACTION_TYPES.RATE_CHANGE: {
      return { ...state, rate: action.payload }
    }
    case ACTION_TYPES.CLEAR_MEDIA_RANGE: {
      return { ...state, mediaRange: null }
    }
    case ACTION_TYPES.CLEAR_PLAYER_ELEMENT_STATE: {
      const { muted, volume } = state
      return { ...initialState, muted, volume }
    }
    case ACTION_TYPES.AIRPLAY_SUPPORTED: {
      return { ...state, airplaySupported: true }
    }
    case ACTION_TYPES.AIRPLAY_AVAILABLE: {
      return { ...state, airplayAvailable: action.payload }
    }
    case ACTION_TYPES.AIRPLAY_ACTIVE: {
      return { ...state, airplayActive: !state.airplayActive }
    }
    default:
      return state
  }
}
const noop = () => {}
const PlayerElementStateContext = createContext({
  ...initialState,
  clearPlayerMediaRange: noop,
  clearPlayerPlayerElementState: noop,
  onPlayingChange: noop,
  onError: noop,
  onMediaInfoLoaded: noop,
  onMediaRangeChange: noop,
  onTimeRunningChange: noop,
  onSessionCreated: noop,
  onSessionClosed: noop,
  onWaitingChange: noop,
  onVolumeChange: noop,
  onMutedChange: noop,
  onRateChange: noop,
  onPanic: noop,
  onSeek: noop,
  onWebkitplaybacktargetavailabilitychanged: noop,
  onWebkitcurrentplaybacktargetiswirelesschanged: noop
})

export const PlayerElementStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const onPlayingChange = useCallback(event => {
    dispatch({ type: ACTION_TYPES.IS_PLAYING_CHANGE, payload: event.detail })
  }, [])

  const onError = useCallback(event => {
    logger.error(event.detail)
    dispatch({ type: ACTION_TYPES.ERROR, payload: event.detail })
  }, [])

  const onPanic = useCallback(event => {
    dispatch({ type: ACTION_TYPES.ELEMENT_PANIC, payload: event.detail })
  }, [])

  const onMediaInfoLoaded = useCallback(event => {
    dispatch({ type: ACTION_TYPES.MEDIA_INFO_LOADED, payload: { mediaInfoFromPlayer: event.detail } })
  }, [])

  const onMediaRangeChange = useCallback(event => {
    dispatch({ type: ACTION_TYPES.MEDIA_RANGE_CHANGE, payload: { mediaRange: event.detail } })
  }, [])

  const onTimeRunningChange = useCallback(event => {
    dispatch({ type: ACTION_TYPES.TIME_RUNNING_CHANGE, payload: event.detail })
  }, [])

  const onSessionCreated = useCallback(event => {
    dispatch({ type: ACTION_TYPES.SESSION_CREATED })
  }, [])

  const onSessionClosed = useCallback(event => {
    dispatch({ type: ACTION_TYPES.SESSION_CLOSED })
  }, [])

  const onWaitingChange = useCallback(event => {
    dispatch({ type: ACTION_TYPES.WAITING_CHANGE, payload: event.detail })
  }, [])

  const clearPlayerMediaRange = useCallback(() => {
    dispatch({ type: ACTION_TYPES.CLEAR_MEDIA_RANGE })
  }, [])

  const clearPlayerPlayerElementState = useCallback(() => {
    dispatch({ type: ACTION_TYPES.CLEAR_PLAYER_ELEMENT_STATE })
  }, [])

  const onVolumeChange = useCallback(event => {
    dispatch({ type: ACTION_TYPES.VOLUME_CHANGE, payload: event.detail })
    setPersistedVolume(event.detail)
  }, [])

  const onMutedChange = useCallback(event => {
    dispatch({ type: ACTION_TYPES.MUTED_CHANGE, payload: event.detail })
    setPersistedMuted(event.detail)
  }, [])

  const onRateChange = useCallback(event => {
    dispatch({ type: ACTION_TYPES.RATE_CHANGE, payload: event.detail })
  }, [])

  const onWebkitplaybacktargetavailabilitychanged = useCallback(event => {
    dispatch({ type: ACTION_TYPES.AIRPLAY_AVAILABLE, payload: event.availability === 'available' })
  }, [])

  const onWebkitcurrentplaybacktargetiswirelesschanged = useCallback(event => {
    dispatch({ type: ACTION_TYPES.AIRPLAY_ACTIVE })
  }, [])

  useEffect(() => {
    if (window.WebKitPlaybackTargetAvailabilityEvent) {
      dispatch({ type: ACTION_TYPES.AIRPLAY_SUPPORTED })
    }
  }, [])

  const value = useMemo(() => {
    return {
      ...state,
      clearPlayerMediaRange,
      clearPlayerPlayerElementState,
      onPlayingChange,
      onError,
      onMediaInfoLoaded,
      onMediaRangeChange,
      onTimeRunningChange,
      onSessionCreated,
      onSessionClosed,
      onWaitingChange,
      onVolumeChange,
      onMutedChange,
      onRateChange,
      onPanic,
      onWebkitplaybacktargetavailabilitychanged,
      onWebkitcurrentplaybacktargetiswirelesschanged
    }
  }, [
    clearPlayerMediaRange,
    clearPlayerPlayerElementState,
    onError,
    onMediaInfoLoaded,
    onMediaRangeChange,
    onMutedChange,
    onPanic,
    onPlayingChange,
    onRateChange,
    onSessionClosed,
    onSessionCreated,
    onTimeRunningChange,
    onVolumeChange,
    onWaitingChange,
    onWebkitcurrentplaybacktargetiswirelesschanged,
    onWebkitplaybacktargetavailabilitychanged,
    state
  ])

  return <PlayerElementStateContext.Provider value={value}>{children}</PlayerElementStateContext.Provider>
}

PlayerElementStateProvider.propTypes = {
  children: PropTypes.node
}

export const usePlayerElementState = () => {
  const context = useContext(PlayerElementStateContext)
  return context
}

export const useIsPlayerPlaying = () => {
  const { isPlaying } = useContext(PlayerElementStateContext)
  return isPlaying
}

// export const usePlaybackSpeed = () => {
//   const { rate } = useContext(PlayerElementStateContext)
//   return rate
// }

export const usePlayerMediaEnd = () => {
  const { mediaRange } = useContext(PlayerElementStateContext)
  return mediaRange?.end
}

export const useVolume = () => {
  const { volume } = useContext(PlayerElementStateContext)
  return volume
}

export const useError = () => {
  const { error } = useContext(PlayerElementStateContext)
  return error
}

export const useHasError = () => {
  const { error } = useContext(PlayerElementStateContext)
  return !!error
}

export const useHasPlayBeenTriggered = () => {
  const { hasPlayBeenTriggered } = useContext(PlayerElementStateContext)
  return hasPlayBeenTriggered
}

export const useHasMediaRange = () => {
  const { mediaRange } = useContext(PlayerElementStateContext)
  return !!mediaRange
}
