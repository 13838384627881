import FrontPage from './pages/FrontPage'
import Guide from './pages/Guide'
import Search from './pages/Search'
import CategoryRecommended from './pages/Category/Recommended'
import { LazyAlphabeticIndexAllContainer, LazyAlphabeticIndexCategoryContainer } from './pages/Category/AlphabeticIndex'
import {
  SeriesBySeriesId,
  SeriesByPodcastId,
  SeriesBySeriesIdEpisodeId,
  SeriesByPodcastIdAndPodcastEpisodeId,
  SeriesByAllSeriesIds,
  SeriesByAllPodcastIds,
  SeriesBySeriesIdAndSeasonId,
  SeriesByPodcastIdAndPodcastSeasonId,
  SingleProgramByEpisodeId
} from './pages/Series'
import Direct from './pages/Direct'
import MyContent from './pages/MyContent'
import MyContentMenuItem from './pages/MyContent/MyContentMenuItem'
import LoginError from './pages/LoginError'
import FourOhFour from './pages/404'
import { fetchFrontPage, fetchPreview as fetchPreviewNext } from './pages/FrontPage/actions'
import PreviewNext from './pages/FrontPage/Preview'
import pageDataByAllPodcastIdsServerSide from './pages/Series/FetchComponents/pageDataByAllPodcastIdsServerSide'
import pageDataByAllSeriesIdsServerSide from './pages/Series/FetchComponents/pageDataByAllSeriesIdsServerSide'
import pageDataByEpisodeIdServerSide from './pages/Series/FetchComponents/pageDataByEpisodeIdServerSide'
import pageDataByPodcastIdServerSide from './pages/Series/FetchComponents/pageDataByPodcastIdServerSide'
import pageDataByPodcastIdAndPodcastEpisodeIdServerSide from './pages/Series/FetchComponents/pageDataByPodcastIdAndPodcastEpisodeIdServerSide'
import pageDataByPodcastIdAndPodcastSeasonIdServerSide from './pages/Series/FetchComponents/pageDataByPodcastIdAndPodcastSeasonIdServerSide'
import pageDataBySeriesIdServerSide from './pages/Series/FetchComponents/pageDataBySeriesIdServerSide'
import pageDataBySeriesIdAndEpisodeIdServerSide from './pages/Series/FetchComponents/pageDataBySeriesIdAndEpisodeIdServerSide'
import pageDataBySeriesIdAndSeasonIdServerSide from './pages/Series/FetchComponents/pageDataBySeriesIdAndSeasonIdServerSide'
import { fetchCategoriesServerSide } from './pages/Category/CategoryList/actions'
import { fetchCategory } from './pages/Category/Recommended/actions'
import ExtraMaterial from './pages/Series/ExtraMaterialPage'
import serverSideExtraMaterialFetch from './pages/Series/ExtraMaterialPage/serverSideExtraMaterialFetch'
import { fetchInitialData } from './pages/Category/AlphabeticIndex/actions'
import { fetchLivebufferNow } from './components/LiveChannels/actions'
import serverSideSeasonExtraMaterialFetch from './pages/Series/ExtraMaterialPage/serverSideSeasonExtraMaterialFetch'

export const ROUTE_TYPES = {
  NAV: 'nav',
  HOME: 'home',
  SUB_PAGE: 'SUB_PAGE',
  NOT_FOUND: 'not_found',
  MOVED_PERMANENTLY: ' Moved_Permanently',
  LOGIN_ERROR: 'login_error'
}

const routes = [
  {
    path: ['/preview/:pageId', '/preview/:pageId/seksjon/:sectionId?'],
    exact: true,
    linkText: 'Hjem',
    component: PreviewNext,
    type: ROUTE_TYPES.HOME,
    maxAge: 60,
    loadData: (store, { match }) => {
      const { loadDataPromise } = store.dispatch(fetchPreviewNext({ pageId: match?.params?.pageId }))
      return loadDataPromise.then(({ payload }) => {
        if (!!match?.params?.sectionId && !payload?.sections?.some?.(({ id }) => id === match?.params?.sectionId)) {
          const error = new Error('Not found')
          error.status = 404
          return Promise.reject(error)
        }
        return payload
      })
    }
  },
  {
    path: ['/', '/seksjon/:sectionId'],
    exact: true,
    component: FrontPage,
    linkText: 'Hjem',
    testId: 'home',
    type: ROUTE_TYPES.HOME,
    maxAge: 60,
    loadData: (store, { match }) => {
      const { loadDataPromise } = store.dispatch(fetchFrontPage())
      return loadDataPromise.then(({ payload }) => {
        if (!!match?.params?.sectionId && !payload?.sections?.some?.(({ id }) => id === match?.params?.sectionId)) {
          const error = new Error('Not found')
          error.status = 404
          return Promise.reject(error)
        }
        return payload
      })
    }
  },
  {
    from: '/alle-programmer/:indexLetter?',
    to: '/kategori/alt-innhold/:indexLetter?',
    status: 301,
    type: ROUTE_TYPES.MOVED_PERMANENTLY
  },
  {
    from: '/podkast/dynga',
    to: '/podkast/karakter',
    status: 301,
    type: ROUTE_TYPES.MOVED_PERMANENTLY
  },
  {
    from: '/direkte/radioresepsjonen',
    to: '/podkast/radioresepsjonen',
    status: 301,
    type: ROUTE_TYPES.MOVED_PERMANENTLY
  },
  {
    path: '/kategori/:categoryId(alt-innhold)/:indexLetter?',
    component: LazyAlphabeticIndexAllContainer,
    type: ROUTE_TYPES.SUB_PAGE,
    loadData: (store, params) => {
      return store.dispatch(
        fetchInitialData({ categoryId: params.match.params.categoryId, initialLetter: params.match.params.indexLetter })
      )
    }
  },
  {
    path: '/kategori/:categoryId/a-aa/:indexLetter?',
    component: LazyAlphabeticIndexCategoryContainer,
    type: ROUTE_TYPES.SUB_PAGE,
    loadData: (store, params) => {
      return store.dispatch(
        fetchInitialData({ categoryId: params.match.params.categoryId, initialLetter: params.match.params.indexLetter })
      )
    }
  },
  {
    path: ['/kategori/:categoryId', '/kategori/:categoryId/seksjon/:sectionId?'],
    component: CategoryRecommended,
    type: ROUTE_TYPES.SUB_PAGE,
    loadData: (store, { match }) => {
      const { loadDataPromise } = store.dispatch(fetchCategory(match.params))
      return loadDataPromise.then(({ payload }) => {
        if (!!match?.params?.sectionId && !payload?.sections?.some?.(({ id }) => id === match?.params?.sectionId)) {
          const error = new Error('Not found')
          error.status = 404
          return Promise.reject(error)
        }
        return payload
      })
    }
  },
  {
    path: '/guide/:date?/:channelId?',
    component: Guide,
    linkText: 'Radioguide',
    testId: 'radioguide',
    type: ROUTE_TYPES.SUB_PAGE
  },
  {
    path: '/sok',
    component: Search,
    linkText: 'Utforsk',
    testId: 'sok',
    iconId: 'nrk-search',
    type: ROUTE_TYPES.NAV,
    loadData: fetchCategoriesServerSide
  },
  {
    path: '/guide',
    component: Guide,
    type: ROUTE_TYPES.SUB_PAGE
  },
  {
    path: '/direkte/:channelId?',
    component: Direct,
    loadData: store => {
      return store.dispatch(fetchLivebufferNow())
    },
    type: ROUTE_TYPES.SUB_PAGE
  },
  {
    path: '/direkte',
    type: ROUTE_TYPES.NAV,
    linkText: 'Direkte',
    iconId: 'nrk-hardware-radio'
  },
  {
    path: '/mittinnhold/nye-episoder',
    component: MyContent,
    type: ROUTE_TYPES.SUB_PAGE
  },
  {
    path: '/mittinnhold/historikk',
    component: MyContent,
    type: ROUTE_TYPES.SUB_PAGE
  },
  {
    path: '/mittinnhold/:notFound',
    component: FourOhFour,
    type: ROUTE_TYPES.NOT_FOUND
  },
  {
    path: '/mittinnhold',
    component: MyContent,
    MenuItemComponent: MyContentMenuItem,
    wrapperClassname: 'list-item-mycontent',
    type: ROUTE_TYPES.NAV
  },
  {
    path: '/:contentType(podkast|serie)/:seriesId/ekstramateriale/:extraMaterialId?',
    component: ExtraMaterial,
    exact: true,
    maxAge: 300,
    loadData: serverSideExtraMaterialFetch
  },
  {
    path: '/:contentType(podkast|serie)/:seriesId/sesong/:seasonId/ekstramateriale/:extraMaterialId?',
    component: ExtraMaterial,
    exact: true,
    maxAge: 300,
    loadData: serverSideSeasonExtraMaterialFetch
  },
  {
    path: '/:contentType(podkast)/:seriesId/sesong/:seasonId/:programId/:legacy?',
    component: SeriesByAllPodcastIds,
    type: ROUTE_TYPES.SUB_PAGE,
    exact: true,
    maxAge: 300,
    loadData: pageDataByAllPodcastIdsServerSide
  },
  {
    path: '/:contentType(serie)/:seriesId/sesong/:seasonId/:programId/:legacy?',
    component: SeriesByAllSeriesIds,
    type: ROUTE_TYPES.SUB_PAGE,
    exact: true,
    maxAge: 300,
    loadData: pageDataByAllSeriesIdsServerSide
  },
  {
    path: '/:contentType(podkast)/:seriesId/sesong/:seasonId',
    component: SeriesByPodcastIdAndPodcastSeasonId,
    type: ROUTE_TYPES.SUB_PAGE,
    exact: true,
    maxAge: 300,
    loadData: pageDataByPodcastIdAndPodcastSeasonIdServerSide
  },
  {
    path: '/:contentType(serie)/:seriesId/sesong/:seasonId',
    component: SeriesBySeriesIdAndSeasonId,
    type: ROUTE_TYPES.SUB_PAGE,
    exact: true,
    maxAge: 300,
    loadData: pageDataBySeriesIdAndSeasonIdServerSide
  },
  {
    path: '/:contentType(podkast)/:seriesId/:programId/:legacy?',
    component: SeriesByPodcastIdAndPodcastEpisodeId,
    type: ROUTE_TYPES.SUB_PAGE,
    exact: true,
    maxAge: 300,
    loadData: pageDataByPodcastIdAndPodcastEpisodeIdServerSide
  },
  {
    path: '/:contentType(serie)/:seriesId/:programId/:legacy?',
    component: SeriesBySeriesIdEpisodeId,
    type: ROUTE_TYPES.SUB_PAGE,
    exact: true,
    maxAge: 300,
    loadData: pageDataBySeriesIdAndEpisodeIdServerSide
  },
  {
    path: '/:contentType(podkast)/:seriesId',
    component: SeriesByPodcastId,
    type: ROUTE_TYPES.SUB_PAGE,
    exact: true,
    maxAge: 300,
    loadData: pageDataByPodcastIdServerSide
  },
  {
    path: '/:contentType(serie)/:seriesId',
    component: SeriesBySeriesId,
    type: ROUTE_TYPES.SUB_PAGE,
    exact: true,
    maxAge: 300,
    loadData: pageDataBySeriesIdServerSide
  },
  {
    from: '/podcast/:podcastId/:episodeId?',
    to: '/podkast/:podcastId/:episodeId?',
    status: 301,
    type: ROUTE_TYPES.MOVED_PERMANENTLY
  },
  {
    path: '/program/:programId',
    component: SingleProgramByEpisodeId,
    type: ROUTE_TYPES.SUB_PAGE,
    maxAge: 300,
    loadData: pageDataByEpisodeIdServerSide
  },
  {
    path: '/innloggingsfeil',
    component: LoginError,
    type: ROUTE_TYPES.LOGIN_ERROR
  },
  {
    component: FourOhFour,
    type: ROUTE_TYPES.NOT_FOUND
  }
]

export default routes
