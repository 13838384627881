import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ContentWidth from '../../RadioUI/ContentWidth'

export default class LoginError extends Component {
  static propTypes = {
    loginHasErrored: PropTypes.func.isRequired,
    history: PropTypes.shape({ replace: PropTypes.func.isRequired }).isRequired
  }

  componentDidMount() {
    const { loginHasErrored, history } = this.props
    loginHasErrored()
    this.timer = setTimeout(() => {
      history.replace('/')
    }, 2000)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  render() {
    return (
      <ContentWidth>
        <div className="container">
          <div className="error-message">
            <hgroup>
              <h1>Vi beklager</h1>
              <h2>Det ser ut som vi har problemer innlogginsfunksjonaliteten vår</h2>
            </hgroup>

            <div className="content-secondary">
              <p>Du blir videresendt straks.</p>
            </div>
          </div>
        </div>
      </ContentWidth>
    )
  }
}
