/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { ThemeContext, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import theme from '../theme'
import { useColors } from '../theme/Color'

const ThemeProvider = ({ theme, children }) => {
  const colors = useColors()
  return <EmotionThemeProvider theme={{ ...theme, colors }}>{children}</EmotionThemeProvider>
}

ThemeProvider.defaultProps = {
  theme
}

const useTheme = () => {
  const colors = useColors()
  const theme = useContext(ThemeContext)
  if (theme === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return { ...theme, colors }
}

export default ThemeProvider
export { useTheme }
