import settleAll from './settleAll'

const ponyfillAbortController = () => {
  if (!('AbortController' in window)) {
    return import(/* webpackChunkName: "fetch" */ 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only')
  }

  return Promise.resolve()
}

const ponyfillFetch = () => {
  if (!window.fetch) {
    return import(/* webpackChunkName: "fetch" */ 'isomorphic-fetch')
  }
  return Promise.resolve()
}

const ponyfillPicture = () => {
  const imgSrcset = typeof document !== 'undefined' && document.createElement('img')
  const hasSrcset = imgSrcset && 'srcset' in imgSrcset && 'sizes' in imgSrcset
  if (!hasSrcset) {
    return import('picturefill')
  }
  return Promise.resolve()
}

const ponyfillIntersectionObserver = () => {
  if (typeof window.IntersectionObserver === 'undefined') {
    return import('intersection-observer')
  }
  return Promise.resolve()
}

const ponyfillSmoothScroll = () => {
  if (!('scrollBehavior' in document.documentElement.style)) {
    return import('smoothscroll-polyfill').then(smoothscroll => smoothscroll.polyfill())
  }
}

const polyfillChildNodeRemove = () => {
  const elements = [Element.prototype, CharacterData.prototype, DocumentType.prototype]

  elements.forEach(item => {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty('remove')) {
      return
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode === null) {
          return
        }
        this.parentNode.removeChild(this)
      }
    })
  })
}

export default () =>
  settleAll([
    ponyfillAbortController(),
    ponyfillFetch(),
    ponyfillPicture(),
    ponyfillIntersectionObserver(),
    ponyfillSmoothScroll(),
    polyfillChildNodeRemove()
  ])
