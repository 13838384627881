import facepaint from 'facepaint'

const breakpoints = ['30em', '48em', '62em', '80em', '120em']
// aliases
breakpoints.sm = breakpoints[0] // 480
breakpoints.md = breakpoints[1] // 768
breakpoints.lg = breakpoints[2] // 992
breakpoints.xl = breakpoints[3] // 1280
breakpoints.xxl = breakpoints[4] // 1920

export default breakpoints

export const mq = facepaint([
  `@media(min-width: ${breakpoints.sm})`,
  `@media(min-width: ${breakpoints.md})`,
  `@media(min-width: ${breakpoints.lg})`,
  `@media(min-width: ${breakpoints.xl})`,
  `@media(min-width: ${breakpoints.xxl})`
])
