import React from 'react'
import loadable from '@loadable/component'
import withLoadableComponentErrorBoundry from '../../components/HOCs/withLoadableComponentErrorBoundry'

const LazyPageDataBySeriesId = withLoadableComponentErrorBoundry(
  loadable(() => import('./FetchComponents/PageDataBySeriesId'))
)
const LazyPageDataByPodcastId = withLoadableComponentErrorBoundry(
  loadable(() => import('./FetchComponents/PageDataByPodcastId'))
)
const LazyPageDataBySeriesIdAndEpisodeId = withLoadableComponentErrorBoundry(
  loadable(() => import('./FetchComponents/PageDataBySeriesIdAndEpisodeId'))
)
const LazyPageDataByPodcastIdAndPodcastEpisodeId = withLoadableComponentErrorBoundry(
  loadable(() => import('./FetchComponents/PageDataByPodcastIdAndPodcastEpisodeId'))
)
const LazyPageDataByAllSeriesIds = withLoadableComponentErrorBoundry(
  loadable(() => import('./FetchComponents/PageDataByAllSeriesIds'))
)
const LazyPageDataByAllPodcastIds = withLoadableComponentErrorBoundry(
  loadable(() => import('./FetchComponents/PageDataByAllPodcastIds'))
)
const LazyPageDataBySeriesIdAndSeasonId = withLoadableComponentErrorBoundry(
  loadable(() => import('./FetchComponents/PageDataBySeriesIdAndSeasonId'))
)

const LazyPageDataByPodcastIdAndPodcastSeasonId = withLoadableComponentErrorBoundry(
  loadable(() => import('./FetchComponents/PageDataByPodcastIdAndPodcastSeasonId'))
)

const LazyPageDataByEpisodeId = withLoadableComponentErrorBoundry(
  loadable(() => import('./FetchComponents/PageDataByEpisodeId'))
)

const LazySeries = withLoadableComponentErrorBoundry(loadable(() => import('./Series')))

const LazySingleProgram = withLoadableComponentErrorBoundry(loadable(() => import('./SingleProgram')))

export const SeriesBySeriesId = () => {
  console.log('SeriesBySeriesId')
  return (
    <LazyPageDataBySeriesId>
      <LazySeries />
    </LazyPageDataBySeriesId>
  )
}

export const SeriesByPodcastId = () => {
  console.log('SeriesByPodcastId')
  return (
    <LazyPageDataByPodcastId>
      <LazySeries />
    </LazyPageDataByPodcastId>
  )
}

export const SeriesBySeriesIdEpisodeId = () => {
  console.log('SeriesBySeriesIdEpisodeId')
  return (
    <LazyPageDataBySeriesIdAndEpisodeId>
      <LazySeries />
    </LazyPageDataBySeriesIdAndEpisodeId>
  )
}

export const SeriesByPodcastIdAndPodcastEpisodeId = () => {
  console.log('SeriesByPodcastIdAndPodcastEpisodeId')
  return (
    <LazyPageDataByPodcastIdAndPodcastEpisodeId>
      <LazySeries />
    </LazyPageDataByPodcastIdAndPodcastEpisodeId>
  )
}

export const SeriesByAllSeriesIds = () => {
  console.log('SeriesByAllSeriesIds')
  return (
    <LazyPageDataByAllSeriesIds>
      <LazySeries />
    </LazyPageDataByAllSeriesIds>
  )
}

export const SeriesByAllPodcastIds = () => {
  console.log('SeriesByAllPodcastIds')
  return (
    <LazyPageDataByAllPodcastIds>
      <LazySeries />
    </LazyPageDataByAllPodcastIds>
  )
}

export const SeriesBySeriesIdAndSeasonId = () => {
  console.log('SeriesBySeriesIdAndSeasonId')
  return (
    <LazyPageDataBySeriesIdAndSeasonId>
      <LazySeries />
    </LazyPageDataBySeriesIdAndSeasonId>
  )
}

export const SeriesByPodcastIdAndPodcastSeasonId = () => {
  console.log('SeriesByPodcastIdAndPodcastSeasonId')
  return (
    <LazyPageDataByPodcastIdAndPodcastSeasonId>
      <LazySeries />
    </LazyPageDataByPodcastIdAndPodcastSeasonId>
  )
}

export const SingleProgramByEpisodeId = () => {
  console.log('SingleProgramByProgramId')
  return (
    <LazyPageDataByEpisodeId>
      <LazySingleProgram />
    </LazyPageDataByEpisodeId>
  )
}
