import invariant from 'invariant'

export default function settleAll(promises) {
  invariant(Array.isArray(promises), 'parameter "promises" should be an array')

  const resolvedPromises = promises.map(promise => {
    return Promise.resolve(promise)
      .then(result => ({ state: 'fulfilled', value: result }))
      .catch(error => ({ state: 'rejected', reason: error }))
  })

  return Promise.all(resolvedPromises)
}
