export const CoreEvent = {
  SessionCreated: 'playercore.sessioncreated',
  SessionClosed: 'playercore.sessionclosed',
  PlayingChange: 'playercore.playingchange',
  WaitingChange: 'playercore.waitingchange',
  TimeRunningChange: 'playercore.timerunningchange',
  Broken: 'playercore.broken',
  MediaInfoLoaded: 'playercore.mediainfoloaded',
  LiveStreamEnded: 'playercore.livestreamended',
  RateChange: 'playercore.ratechange',
  VolumeChange: 'playercore.volumechange',
  MutedChange: 'playercore.mutedchange',
  TimeUpdate: 'playercore.timeupdate',
  ScrubberUpdate: 'playercore.scrubberupdate',
  MediaRangeChange: 'playercore.mediarangechange',
  QualityChange: 'playercore.qualitychange',
  FullscreenStateChange: 'playercore.fullscreenstatechange',
  PiPStateChange: 'playercore.pipstatechange',
  RemotePlaybackStateChange: 'playercore.remoteplaybackstatechange',
  SubtitlesChange: 'playercore.subtitleschange',
  SubtitlesDisplayChange: 'playercore.subtitlesdisplaychange',
  SubtitlesCueUpdate: 'playercore.subtitlescueupdate'
}
