import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import CoreIcons from './CoreIcons'
import { trackException } from '../clientMonitoring'

class RouteErrorBoundry extends Component {
  static propTypes = {
    children: PropTypes.node
  }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, errorInfo) {
    this.setState(() => ({
      hasError: true
    }))

    trackException(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div id="error" className="box">
          <div className="container">
            <div className="error-message">
              <hgroup>
                <h1>Vi beklager. Noe gikk galt.</h1>
                <h2>Vi har logget feilen, og våre beste folk er på saken.</h2>
              </hgroup>

              <h2>Du kan forsøke følgende:</h2>
              <ul className="content-secondary">
                <li>Undersøk om nettadressen er riktig.</li>
                <li>
                  Gjøre et søk, eller se igjennom kategoriene våre:{' '}
                  <Link style={{ paddingLeft: '0.5rem' }} to="/sok">
                    <strong>
                      <CoreIcons id="NrkSearch" />
                      <span style={{ paddingLeft: '0.5rem', fontSize: '1rem' }}>Utforsk</span>
                    </strong>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

export default RouteErrorBoundry
