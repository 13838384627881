import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { usePlayerElementState } from '../hooks/useAudioAndVideoPlayerElementState'
import { usePlayerElement } from '../context/PlayerElementContext'
import { useVideoPlayerElementState } from '../context/VideoPlayerElementStateContext'
import { useVideoPlayerElement } from '../context/VideoPlayerElementContext'
const noop = () => {}

const DEFAULT_SPEED = 1.0

const getNextSpeed = currentSpeed => {
  if (currentSpeed >= 2) {
    return 0.8
  } else if (currentSpeed === 0.8) {
    return 1
  }
  const nextSpeed = currentSpeed + 0.1
  return Number.parseFloat(nextSpeed.toFixed(1))
}

const PlaybackSpeedContext = createContext({
  updateSpeed: noop,
  currentSpeed: DEFAULT_SPEED,
  nextSpeed: getNextSpeed(DEFAULT_SPEED)
})

export const PlaybackSpeedProvider = ({ children }) => {
  const [currentSpeed, setCurrentSpeed] = useState(DEFAULT_SPEED)

  const {
    isPlaying: isAudioPlaying,
    isTimeRunning: isAudioTimeRunning,
    mediaRange: audioMediaRange
  } = usePlayerElementState()

  const {
    isPlaying: isVideoPlaying,
    isTimeRunning: isVideoTimeRunning,
    mediaRange: videoMediaRange
  } = useVideoPlayerElementState()

  const { setPlaybackRate: setAudioPlaybackRate } = usePlayerElement()
  const { setPlaybackRate: setVideoPlaybackRate } = useVideoPlayerElement()

  useEffect(() => {
    if (!!audioMediaRange && (isAudioPlaying || isAudioTimeRunning)) {
      setAudioPlaybackRate(currentSpeed)
    }
  }, [audioMediaRange, currentSpeed, isAudioPlaying, isAudioTimeRunning, setAudioPlaybackRate])

  useEffect(() => {
    if (!!videoMediaRange && (isVideoPlaying || isVideoTimeRunning)) {
      setVideoPlaybackRate(currentSpeed)
    }
  }, [currentSpeed, isVideoPlaying, isVideoTimeRunning, setVideoPlaybackRate, videoMediaRange])

  const value = useMemo(() => {
    return {
      updateSpeed: () => {
        const nextSpeed = getNextSpeed(currentSpeed)
        setCurrentSpeed(nextSpeed)
        return nextSpeed
      },
      nextSpeed: getNextSpeed(currentSpeed),
      currentSpeed
    }
  }, [currentSpeed])

  return <PlaybackSpeedContext.Provider value={value}>{children}</PlaybackSpeedContext.Provider>
}

PlaybackSpeedProvider.propTypes = { children: PropTypes.node }

export const usePlaybackSpeed = () => {
  const playbackSpeed = useContext(PlaybackSpeedContext)
  return playbackSpeed
}
