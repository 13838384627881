import React, { useState, useEffect, createContext } from 'react'
import PropTypes from 'prop-types'
import { fetchQueue } from '../../apiClients/personalisation'
import { useIsLoggedIn } from '../Auth/AuthContext'

export const QueueContext = createContext({
  queue: [],
  setQueue: () => {}
})

export const QueueProvider = ({ children }) => {
  const [queue, setQueue] = useState(null)
  const isLoggedIn = useIsLoggedIn()

  useEffect(() => {
    if (isLoggedIn) {
      fetchQueue().then(e => {
        setQueue(e.queue)
      })
    } else {
      setQueue(null)
    }
  }, [isLoggedIn])

  return <QueueContext.Provider value={{ queue, setQueue }}>{children}</QueueContext.Provider>
}

QueueProvider.propTypes = {
  children: PropTypes.node
}

export default QueueProvider
