import { get, set } from 'idb-keyval'
import logger from '../../../logger'

const IDB_VOLUME_KEY = 'player_volume'
const IDB_MUTED_KEY = 'player_muted'
const DEFAULT_VOLUME = 1
const DEFAULT_MUTED = false

export const setPersistedVolume = async (volume = DEFAULT_VOLUME) => {
  try {
    await set(IDB_VOLUME_KEY, volume)
  } catch (error) {
    logger.warn('Could not persist volume in browser', error)
  }
}

export const getPersistedVolume = async () => {
  try {
    const persistedVolume = await get(IDB_VOLUME_KEY)
    return persistedVolume || DEFAULT_VOLUME
  } catch (error) {
    logger.warn('Could not get persisted volume from browser', error)
    return Promise.resolve(DEFAULT_VOLUME)
  }
}

export const setPersistedMuted = async (muted = DEFAULT_MUTED) => {
  try {
    await set(IDB_MUTED_KEY, muted)
  } catch (error) {
    logger.warn('Could not persist mute state in browser', error)
  }
}

export const getPersistedMuted = async () => {
  try {
    const persistedMuted = await get(IDB_MUTED_KEY)
    return typeof persistedMuted === 'boolean' ? persistedMuted : DEFAULT_MUTED
  } catch (error) {
    logger.warn('Cold not get persisted mute state from browser', error)
    return Promise.resolve(DEFAULT_MUTED)
  }
}
