import React from 'react'
import { Global, css } from '@emotion/react'
import preflight from './preflight'
import { useColors } from '../theme/Color'

const CSSReset = () => {
  const colors = useColors()
  const configCSS = theme => {
    return css`
      html {
        line-height: 1.5;
        color: ${colors.main.contrastLight};
        background-color: ${colors.main.medium};
      }

      /**
      * Allow adding a border to an element by just adding a border-width.
      */

      ul {
        list-style: none;
      }

      *,
      *::before,
      *::after {
        border-width: 0;
        border-style: solid;
        border-color: ${colors.main.dark};
      }

      input:-ms-input-placeholder,
      textarea:-ms-input-placeholder {
        color: ${colors.main.contrastLight};
      }

      input::-ms-input-placeholder,
      textarea::-ms-input-placeholder {
        color: ${colors.main.contrastLight};
      }

      input::placeholder,
      textarea::placeholder {
        color: ${colors.main.contrastLight};
      }
    `
  }

  return <Global styles={theme => css([preflight, configCSS(theme)])} />
}

export default CSSReset
