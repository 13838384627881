import { createSelector } from 'reselect'
import { useDispatch } from 'react-redux'
import { useCallback, useMemo } from 'react'
import useShallowSelector from '../../components/hooks/useShallowSelector'
import { useAudioOrVideoPlayerElement } from '../../components/StickyPlayer/hooks/useAudioOrVideoPlayerElement'
import { usePlayerMediaEnd } from '../../components/StickyPlayer/hooks/useAudioAndVideoPlayerElementState'
import logger from '../../logger'
import secondsToIsoDurationTime from './helpers/secondsToIsoDurationTime'
import { saveProgress } from './progressActions'
import { durationToSeconds } from '../../common/parseIsoDuration'
import { useIsLoggedIn } from '../../components/Auth/AuthContext'

export const progressRootSelector = createSelector([state => state.progress], progress => {
  return progress
})

const progressesRootSelector = createSelector([progressRootSelector], ({ progresses }) => progresses)

export const progressesSelector = createSelector([progressesRootSelector], progressesRoot => progressesRoot.items)

export const progressInPlayerSelector = createSelector([progressRootSelector], progress => {
  return progress.progressInPlayer
})

export const useProgressInPlayer = () => {
  const progressInPlayer = useShallowSelector(progressInPlayerSelector)
  return progressInPlayer
}

export const useProgressInPlayerSeconds = () => {
  const progressInPlayer = useShallowSelector(progressInPlayerSelector)
  const progresString = progressInPlayer.inProgress?.time

  if (!progresString) return null

  return durationToSeconds(progresString)
}

export const useSaveProgress = () => {
  const { getCurrentTime } = useAudioOrVideoPlayerElement()
  const duration = usePlayerMediaEnd()
  const dispatch = useDispatch()
  const { reportInterval, startPlaybackPosition, _links } = useProgressInPlayer()
  const { notBeforeMs } = reportInterval
  const progressUrl = _links.self.href
  const isLoggedIn = useIsLoggedIn()

  const savePositionAndStartPosition = useCallback(() => {
    const currentTime = getCurrentTime()

    const percentage = (currentTime / duration) * 100

    if (currentTime < notBeforeMs / 1000) {
      logger.info('Too soon to report progress. Aborting...', currentTime)
      return
    }

    const nextPosition = secondsToIsoDurationTime(currentTime)

    const nextLocalProgress = {
      time: nextPosition,
      percentage,
      seconds: currentTime
    }

    logger.info('saving progress', currentTime)
    dispatch(
      saveProgress({
        url: progressUrl,
        localProgress: nextLocalProgress,
        startPlaybackPosition: nextPosition,
        isLoggedIn
      })
    )
  }, [dispatch, duration, getCurrentTime, isLoggedIn, notBeforeMs, progressUrl])

  const savePosition = useCallback(() => {
    const currentTime = getCurrentTime()
    const percentage = (currentTime / duration) * 100
    const localProgress = {
      time: secondsToIsoDurationTime(currentTime),
      percentage,
      seconds: currentTime
    }

    if (currentTime < notBeforeMs / 1000) {
      logger.info('Too soon to report progress. Aborting...', currentTime)
      return
    }
    logger.info('saving progress', currentTime)
    dispatch(saveProgress({ url: progressUrl, localProgress, startPlaybackPosition, isLoggedIn }))
  }, [dispatch, duration, getCurrentTime, isLoggedIn, notBeforeMs, progressUrl, startPlaybackPosition])

  const returnValue = useMemo(() => {
    return { savePositionAndStartPosition, savePosition }
  }, [savePosition, savePositionAndStartPosition])

  return returnValue
}
