import React from 'react'
import PropTypes from 'prop-types'
import Flex from '../../RadioUI/Flex'
import Text from '../../RadioUI/Text'
import Button from '../../RadioUI/Button'

const ErrorMessage = ({
  onErrorClick = () => {
    document.location.reload()
  },
  message = 'Noe gikk galt. Siden kan være utdatert.',
  buttonText = 'Oppdater siden',
  disabled,
  ...restProps
}) => {
  return (
    <Flex
      as="article"
      direction="column"
      alignItems="center"
      justifyContent="center"
      p={{ base: 3, md: 6 }}
      {...restProps}
    >
      {message && <Text pb={{ base: 3, md: 6 }}>{message}</Text>}
      {buttonText && onErrorClick && (
        <Button onClick={onErrorClick} disabled={disabled}>
          {buttonText}
        </Button>
      )}
    </Flex>
  )
}

ErrorMessage.propTypes = {
  onErrorClick: PropTypes.func,
  message: PropTypes.string,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool
}

export default ErrorMessage
