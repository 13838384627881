import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '../RadioUI/Tooltip'

const TooltipLiveGuide = ({
  text,
  localStorageKey,
  localStorageKeyTimeSince,
  header = '',
  arrowDir = 'bottom',
  toPath = '',
  ...props
}) => {
  const [hasSeenTooltip, setHasSeenTooltip] = useState(true)

  useEffect(() => {
    const hasSeen = localStorage.getItem(localStorageKey)
    const timeSinceFirst = localStorage.getItem(localStorageKeyTimeSince)
    if (!hasSeen) {
      if (timeSinceFirst) {
        const daysPassed = Math.floor(Math.ceil(new Date() - new Date(timeSinceFirst)) / (1000 * 60 * 60 * 24))
        if (daysPassed >= 10) {
          localStorage.setItem(localStorageKey, true)
          setHasSeenTooltip(true)
        } else {
          setHasSeenTooltip(false)
        }
      }
    }
    if (!timeSinceFirst) {
      localStorage.setItem(localStorageKeyTimeSince, new Date())
      if (!hasSeen) {
        setHasSeenTooltip(false)
      }
    }
  }, [localStorageKey, localStorageKeyTimeSince])

  return (
    <Tooltip
      header={header}
      text={text}
      arrowDir={arrowDir}
      open={!hasSeenTooltip}
      onClose={() => {
        localStorage.setItem(localStorageKey, true)
        setHasSeenTooltip(true)
      }}
      onClick={() => {
        localStorage.setItem(localStorageKey, true)
        setHasSeenTooltip(true)
      }}
      toPath={toPath}
      {...props}
    />
  )
}

TooltipLiveGuide.propTypes = {
  text: PropTypes.string.isRequired,
  localStorageKey: PropTypes.string.isRequired,
  localStorageKeyTimeSince: PropTypes.string.isRequired,
  header: PropTypes.string,
  arrowDir: PropTypes.string,
  toPath: PropTypes.string
}

export default TooltipLiveGuide
