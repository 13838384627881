import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Text from '../Text'
import PseudoBox from '../PseudoBox'
import IconButton from '../IconButton'
import { arrowStyle, style } from './style'
import { useColors } from '../theme/Color'

const noop = () => {}

const Tooltip = ({
  text,
  header = '',
  open = true,
  onClose = noop,
  onClick = noop,
  arrowDir = 'bottom',
  toPath = '',
  ...props
}) => {
  const colors = useColors()
  if (!open) return null

  return (
    <PseudoBox className={`tooltip ${arrowDir}`} css={style(colors.main)} {...props}>
      <PseudoBox mr="1rem" p="1rem" d="flex" flexDir="column" as={Link} to={toPath} onClick={onClick}>
        {header && (
          <Text className="tooltip-header" size="md" weight="bold">
            {header}
          </Text>
        )}
        <Text className="tooltip-text" size="sm" weight="normal">
          {text}
        </Text>
      </PseudoBox>
      <IconButton
        aria-label="Lukk"
        variant="transparent"
        color={colors.main.dark}
        size="sm"
        iconId="nrk-close"
        position="absolute"
        top="5px"
        right="0"
        height="26px"
        zIndex={2000}
        onClick={onClose}
      />
      <PseudoBox className={`arrow ${arrowDir}`} css={arrowStyle(colors.main)} />
    </PseudoBox>
  )
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  header: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  arrowDir: PropTypes.string,
  toPath: PropTypes.string
}

export default Tooltip
