import React, { lazy, Suspense, useState, useEffect } from 'react'
import invariant from 'invariant'
import PropTypes from 'prop-types'
import LoadableComponentErrorBoundry from '../LoadableComponentErrorBoundry'
import ErrorMessage from '../LoadableComponentErrorBoundry/ErrorMessage'

const DefaultLoadingComponent = () => null

const componentLoader = options => {
  const { loader, LoadingComponent = DefaultLoadingComponent, ErrorMessageComponent = ErrorMessage } = options

  invariant(loader, 'loader function i required')
  const LazyComponent = lazy(loader)
  const ComponentLoader = ({ showMessageOnError, ...props }) => {
    const [isBrowser, setIsBrowser] = useState(false)
    useEffect(() => {
      setIsBrowser(true)
    }, [])

    if (!isBrowser) return <LoadingComponent />

    return (
      <LoadableComponentErrorBoundry
        showMessageOnError={showMessageOnError}
        ErrorMessageComponent={ErrorMessageComponent}
      >
        <Suspense fallback={<LoadingComponent />}>
          <LazyComponent {...props} />
        </Suspense>
      </LoadableComponentErrorBoundry>
    )
  }

  ComponentLoader.propTypes = {
    showMessageOnError: PropTypes.bool,
    ErrorMessageComponent: PropTypes.elementType
  }

  return ComponentLoader
}

export default componentLoader
