import PropTypes from 'prop-types'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import clientConfig from '../../../clientConfig'
import logger from '../../../logger'
import { CoreEvent } from '../helpers/coreEvents'
import { MEDIA_TYPES } from '../helpers/mediaTypes'
import { setPersistedMedia } from '../helpers/persistedMedia'
import { setPersistedMuted, setPersistedVolume } from '../helpers/persistedVolume'
import { toSrc } from '../helpers/toSrc'
import { usePlayerElementState } from './PlayerElementStateContext'
import { usePlayerMetadata } from './PlayerMetadataContext'
import { usePlayerTime } from './PlayerTimeContext'

const noop = () => {}

const PlayerElementContext = createContext({
  playerElementReady: false,
  playerCoreInitializerReady: false,
  isPlayerLoading: true,
  $playerElement: undefined,
  loadPlayer: noop,
  loadAndStartPlayer: noop,
  loadAndStartOrTogglePlayPause: noop,
  play: noop,
  pause: noop,
  togglePlayPause: noop,
  loadAndStartOrPlayFrom: noop,
  loadAndStartOrTogglePlayFrom: noop,
  updateToNextPlaybackSpeed: noop,
  skipBack: noop,
  skipForward: noop,
  seekTo: noop,
  seekToPosition: noop,
  setVolume: noop,
  toggleMute: noop,
  setMuted: noop,
  setPlaybackRate: noop,
  positionToLiveTime: noop,
  liveTimeToPosition: noop,
  getCurrentTime: noop,
  setSeekToState: noop,
  airplay: noop,
  nextSpeed: 1
})

export const PlayerElementProvider = ({ children }) => {
  const [radioWebPlayerElementReady, setRadioWebPlayerElementReady] = useState(false)
  const [playerCoreInitializerReady, setPlayerCoreInitializerReady] = useState(false)
  const [playerElementReady, setPlayerElementReady] = useState(false)
  const playerCoreInitializerRef = useRef()
  const playerElementRef = useRef()
  const [seekToState, setSeekToState] = useState(null)

  const {
    onPlayingChange,
    onError,
    onMediaInfoLoaded,
    onMediaRangeChange,
    onTimeRunningChange,
    onSessionCreated,
    onSessionClosed,
    onWaitingChange,
    onVolumeChange,
    onMutedChange,
    onRateChange,
    clearPlayerPlayerElementState,
    onPanic,
    onWebkitcurrentplaybacktargetiswirelesschanged,
    onWebkitplaybacktargetavailabilitychanged,
    isTimeRunning,
    isPlaying,
    mediaRange,
    airplaySupported
  } = usePlayerElementState()

  const { loadMetadata, loading: playerMetadataLoading } = usePlayerMetadata()

  const { onTimeUpdate, onScrubberUpdate } = usePlayerTime()

  useEffect(() => {
    import('hls.js')
      .then(() => {
        return import('@nrk/radio-web-player/index.js')
      })
      .then(() => {
        setRadioWebPlayerElementReady(true)
      })
  }, [])

  useEffect(() => {
    import('@nrk/radio-web-player/radio-web-player-dependencies.esm.js').then(module => {
      const { ConfiguredAdapter, HlsJsAdapter, NativeAdapter, CastPlayerCore, NRKCastSenderAdapter, platform } = module

      const nativeAdapter = new ConfiguredAdapter(NativeAdapter)
      const hlsJsAdapter = new ConfiguredAdapter(HlsJsAdapter, () => import('hls.js'), { immediateQualityJumps: true })
      const adapters = platform.isApple ? [nativeAdapter, hlsJsAdapter] : [hlsJsAdapter, nativeAdapter]

      const playerCoreInitializer = playerElement => {
        return new CastPlayerCore(playerElement, adapters, {
          mediaElementOptions: { type: 'audio' },
          cast: {
            adapter: new ConfiguredAdapter(NRKCastSenderAdapter),
            receiverAppId: clientConfig.CHROMECAST_ID
          }
        })
      }
      playerCoreInitializerRef.current = playerCoreInitializer
      setPlayerCoreInitializerReady(true)
    })
  }, [])

  useEffect(() => {
    if (radioWebPlayerElementReady && playerCoreInitializerReady) {
      playerElementRef.current = document.createElement('radio-web-player')
      playerElementRef.current.setAttribute('type', 'player')
      playerElementRef.current.setAttribute('ga', clientConfig.GA_ACCOUNT)
      playerElementRef.current.setAttribute('snowplowurl', clientConfig.SNOWPLOW_COLLECTOR_ENDPOINT)
      playerElementRef.current.setAttribute('psapiorigin', clientConfig.PS_API_BASE_URL)
      playerElementRef.current.playerCoreInitializer = playerCoreInitializerRef.current
      playerElementRef.current.addEventListener(CoreEvent.PlayingChange, onPlayingChange)
      playerElementRef.current.addEventListener(CoreEvent.Broken, onError)
      playerElementRef.current.addEventListener(CoreEvent.MediaInfoLoaded, onMediaInfoLoaded)
      playerElementRef.current.addEventListener(CoreEvent.MediaRangeChange, onMediaRangeChange)
      playerElementRef.current.addEventListener(CoreEvent.TimeRunningChange, onTimeRunningChange)
      playerElementRef.current.addEventListener(CoreEvent.TimeUpdate, onTimeUpdate)
      playerElementRef.current.addEventListener(CoreEvent.ScrubberUpdate, onScrubberUpdate)
      playerElementRef.current.addEventListener(CoreEvent.SessionCreated, onSessionCreated)
      playerElementRef.current.addEventListener(CoreEvent.SessionClosed, onSessionClosed)
      playerElementRef.current.addEventListener(CoreEvent.WaitingChange, onWaitingChange)
      playerElementRef.current.addEventListener(CoreEvent.VolumeChange, onVolumeChange)
      playerElementRef.current.addEventListener(CoreEvent.MutedChange, onMutedChange)
      playerElementRef.current.addEventListener(CoreEvent.RateChange, onRateChange)

      playerElementRef.current.addEventListener('ElementPanic', onPanic)

      window.playerElement = playerElementRef.current
      setPlayerElementReady(true)

      return () => {
        logger.warn('Hmm. Why did this happen?')
        setPlayerElementReady(false)
        playerElementRef.current.removeEventListener(CoreEvent.PlayingChange, onPlayingChange)
        playerElementRef.current.removeEventListener(CoreEvent.Broken, onError)
        playerElementRef.current.removeEventListener(CoreEvent.MediaInfoLoaded, onMediaInfoLoaded)
        playerElementRef.current.removeEventListener(CoreEvent.MediaRangeChange, onMediaRangeChange)
        playerElementRef.current.removeEventListener(CoreEvent.TimeRunningChange, onTimeRunningChange)
        playerElementRef.current.removeEventListener(CoreEvent.TimeUpdate, onTimeUpdate)
        playerElementRef.current.removeEventListener(CoreEvent.ScrubberUpdate, onScrubberUpdate)
        playerElementRef.current.removeEventListener(CoreEvent.SessionCreated, onSessionCreated)
        playerElementRef.current.removeEventListener(CoreEvent.SessionClosed, onSessionClosed)
        playerElementRef.current.removeEventListener(CoreEvent.WaitingChange, onWaitingChange)
        playerElementRef.current.removeEventListener(CoreEvent.VolumeChange, onVolumeChange)
        playerElementRef.current.removeEventListener(CoreEvent.MutedChange, onMutedChange)
        playerElementRef.current.removeEventListener(CoreEvent.RateChange, onRateChange)
        playerElementRef.current.removeEventListener('ElementPanic', onPanic)
      }
    }
  }, [
    onError,
    onMediaInfoLoaded,
    onMediaRangeChange,
    onMutedChange,
    onPanic,
    onPlayingChange,
    onRateChange,
    onScrubberUpdate,
    onSessionClosed,
    onSessionCreated,
    onTimeRunningChange,
    onTimeUpdate,
    onVolumeChange,
    onWaitingChange,
    playerCoreInitializerReady,
    radioWebPlayerElementReady
  ])

  useEffect(() => {
    if (playerElementReady && airplaySupported) {
      playerElementRef.current.addEventListener(
        'webkitplaybacktargetavailabilitychanged',
        onWebkitplaybacktargetavailabilitychanged
      )

      playerElementRef.current.addEventListener(
        'webkitcurrentplaybacktargetiswirelesschanged',
        onWebkitcurrentplaybacktargetiswirelesschanged
      )

      return () => {
        playerElementRef.current.removeEventListener(
          'webkitplaybacktargetavailabilitychanged',
          onWebkitplaybacktargetavailabilitychanged
        )

        playerElementRef.current.removeEventListener(
          'webkitcurrentplaybacktargetiswirelesschanged',
          onWebkitcurrentplaybacktargetiswirelesschanged
        )
      }
    }
  }, [
    airplaySupported,
    onWebkitcurrentplaybacktargetiswirelesschanged,
    onWebkitplaybacktargetavailabilitychanged,
    playerElementReady
  ])

  const seekToByType = useCallback(({ seekTo, mediaType }) => {
    if (mediaType === MEDIA_TYPES.CHANNEL && seekTo instanceof Date) {
      playerElementRef.current.activeSession.seekToLiveTime(seekTo)
    } else {
      playerElementRef.current.seekTo(seekTo)
    }
  }, [])

  // Workaround for not being able to seek before media has been played
  useEffect(() => {
    if (!!seekToState && (isTimeRunning || isPlaying) && !!mediaRange) {
      seekToByType(seekToState)
      setSeekToState(null)
    }
  }, [isPlaying, isTimeRunning, mediaRange, seekToByType, seekToState])

  const clearPlayer = useCallback(() => {
    playerElementRef.current.src = null
    playerElementRef.current.removeAttribute('snowplowoverridecontentkind')
  }, [])

  const loadPlayer = useCallback(
    ({ episodeId, seasonId, seriesId, channelId, mediaType }) => {
      clearPlayerPlayerElementState()
      clearPlayer()
      loadMetadata({ episodeId, seasonId, seriesId, channelId, mediaType })
      playerElementRef.current.src = toSrc({ episodeId, seasonId, seriesId, channelId, mediaType })
      playerElementRef.current.setAttribute('snowplowoverridecontentkind', 'audio')
      setPersistedMedia({ episodeId, seasonId, seriesId, channelId, mediaType })
    },
    [clearPlayer, clearPlayerPlayerElementState, loadMetadata]
  )

  const loadAndStartPlayer = useCallback(
    ({ episodeId, seasonId, seriesId, channelId, mediaType, seekTo = null }) => {
      loadPlayer({ episodeId, seasonId, seriesId, channelId, mediaType })
      seekTo && setSeekToState({ mediaType, seekTo })
      return playerElementRef.current.play()
    },
    [loadPlayer]
  )

  const play = useCallback(() => {
    return playerElementRef.current.play()
  }, [])

  const pause = useCallback(() => {
    return playerElementRef.current.pause()
  }, [])

  const togglePlayPause = useCallback(() => {
    return playerElementRef.current.togglePlay()
  }, [])

  const loadAndStartOrTogglePlayPause = useCallback(
    ({ episodeId, seasonId, seriesId, channelId, mediaType, seekTo = null }) => {
      if (
        (episodeId && episodeId === playerElementRef.current?.metadata?.id) ||
        (channelId && channelId === playerElementRef.current?.metadata?.id)
      ) {
        if (seekTo < playerElementRef.current?.metadata?.duration) {
          setSeekToState({ seekTo, mediaType })
        }
        return togglePlayPause()
      }

      return loadAndStartPlayer({ episodeId, seasonId, seriesId, channelId, mediaType, seekTo })
    },
    [loadAndStartPlayer, togglePlayPause]
  )
  const loadAndStartOrTogglePlayFrom = useCallback(
    ({ episodeId, seasonId, seriesId, channelId, mediaType, seekTo = null }) => {
      if (
        (episodeId && episodeId !== playerElementRef.current?.metadata?.id) ||
        (channelId && channelId !== playerElementRef.current?.metadata?.id)
      ) {
        return loadAndStartPlayer({ episodeId, seasonId, seriesId, channelId, mediaType, seekTo })
      }
      if (isPlaying) {
        return pause()
      } else {
        play()
        // return !!seekTo && seekToByType({ seekTo, mediaType })
        seekTo && setSeekToState({ seekTo, mediaType })
      }
    },
    [isPlaying, loadAndStartPlayer, pause, play]
  )

  const loadAndStartOrPlayFrom = useCallback(
    ({ episodeId, seasonId, seriesId, channelId, mediaType, seekTo = null }) => {
      if (
        (episodeId && episodeId === playerElementRef.current?.metadata?.id) ||
        (channelId && channelId === playerElementRef.current?.metadata?.id)
      ) {
        loadMetadata({ episodeId, seasonId, seriesId, channelId, mediaType })
        if (isPlaying) {
          seekTo && setSeekToState({ seekTo, mediaType })
          return
        } else {
          play()
          seekTo && setSeekToState({ seekTo, mediaType })
          return
        }
      }

      return loadAndStartPlayer({ episodeId, seasonId, seriesId, channelId, mediaType, seekTo })
    },
    [isPlaying, loadAndStartPlayer, loadMetadata, play]
  )

  const skipBack = useCallback((relativeTime = -15) => {
    playerElementRef.current.seekToRelativeTime(relativeTime)
  }, [])

  const skipForward = useCallback((relativeTime = 15) => {
    playerElementRef.current.seekToRelativeTime(relativeTime)
  }, [])

  const seekTo = useCallback(seekToSeconds => {
    if (!Number.isFinite(seekToSeconds)) {
      logger.warn('invalid seekToSecond', seekToSeconds)
      return
    }
    playerElementRef.current.seekTo(seekToSeconds)
  }, [])

  const seekToPosition = useCallback(position => {
    playerElementRef.current.seekToPosition(position)
  }, [])

  const getCurrentTimeLive = useCallback(() => {
    return playerElementRef.current?.activeSession?.getCurrentTimeLive?.()
  }, [])

  const positionToLiveTime = useCallback(position => {
    return playerElementRef.current?.activeSession?.positionToLiveTime?.(position)
  }, [])

  const liveTimeToPosition = useCallback(liveTime => {
    return playerElementRef.current?.activeSession?.liveTimeToPosition?.(liveTime)
  }, [])

  const setVolume = useCallback(volume => {
    playerElementRef.current.volume = volume
    setPersistedVolume(volume)
  }, [])

  const toggleMute = useCallback(() => {
    playerElementRef.current.toggleMute()
    setPersistedMuted(playerElementRef.current.mute)
  }, [])

  const setMuted = useCallback(mute => {
    playerElementRef.current.muted = mute
    setPersistedMuted(playerElementRef.current.mute)
  }, [])

  const setPlaybackRate = useCallback(rate => {
    // playerElementRef.current.playbackRate = rate
    const $audioElement = playerElementRef.current.querySelector('audio')
    if ($audioElement) {
      $audioElement.playbackRate = rate
    }
  }, [])

  const getCurrentTime = useCallback(() => {
    return playerElementRef.current.currentTime
  }, [])

  const airplay = useCallback(() => {
    return playerElementRef.current.querySelector('audio').webkitShowPlaybackTargetPicker()
  }, [])

  const value = useMemo(() => {
    return {
      $playerElement: playerElementRef.current,
      playerElementReady,
      isPlayerLoading: !playerElementReady || playerMetadataLoading,
      loadPlayer,
      loadAndStartPlayer,
      loadAndStartOrTogglePlayPause,
      loadAndStartOrPlayFrom,
      loadAndStartOrTogglePlayFrom,
      play,
      pause,
      togglePlayPause,
      skipBack,
      skipForward,
      seekTo,
      seekToPosition,
      setSeekToState,
      setVolume,
      toggleMute,
      setMuted,
      setPlaybackRate,
      getCurrentTimeLive,
      positionToLiveTime,
      liveTimeToPosition,
      getCurrentTime,
      airplay
    }
  }, [
    playerElementReady,
    playerMetadataLoading,
    loadPlayer,
    loadAndStartPlayer,
    loadAndStartOrTogglePlayPause,
    loadAndStartOrPlayFrom,
    loadAndStartOrTogglePlayFrom,
    play,
    pause,
    togglePlayPause,
    skipBack,
    skipForward,
    seekTo,
    seekToPosition,
    setVolume,
    toggleMute,
    setMuted,
    setPlaybackRate,
    getCurrentTimeLive,
    positionToLiveTime,
    liveTimeToPosition,
    getCurrentTime,
    airplay
  ])

  return <PlayerElementContext.Provider value={value}>{children}</PlayerElementContext.Provider>
}

PlayerElementProvider.propTypes = {
  children: PropTypes.node
}

export const usePlayerElement = () => {
  const context = useContext(PlayerElementContext)
  return context
}

export const useIsPlayerLoading = () => {
  const { isPlayerLoading } = useContext(PlayerElementContext)
  return isPlayerLoading
}
