import url from 'url'
import querystring from 'querystring'

export default function appendQueryToPath(queryParams, path) {
  // eslint-disable-next-line n/no-deprecated-api
  const { pathname, query } = url.parse(path)
  const queryParamsFromPath = querystring.parse(query)
  const queryString = querystring.stringify({ ...queryParamsFromPath, ...queryParams })
  const nextApiPath = `${pathname}?${queryString}`

  return nextApiPath
}
