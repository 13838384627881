import styled from '@emotion/styled'
import { createShouldForwardProp, props } from '@styled-system/should-forward-prop'
import memoize from '@emotion/memoize'
import extraConfig from './config'
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
  compose,
  system
} from 'styled-system'
import { baseSizes } from '../theme/sizes'

const systemProps = compose(
  layout,
  color,
  space,
  background,
  border,
  grid,
  position,
  shadow,
  typography,
  flexbox,
  extraConfig
)

const shouldForwardProp = createShouldForwardProp([
  ...props,
  'd',
  'textDecoration',
  'pointerEvents',
  'visibility',
  'transform',
  'cursor',
  'fill',
  'stroke'
])

const nativeHTMLPropAlias = ['htmlWidth', 'htmlHeight']

const knownPropsToForward = ['delay', 'popup', 'forwardRef']

const shouldForwardPropCustom = memoize(prop => {
  if (knownPropsToForward.includes(prop)) {
    return true
  } else if (nativeHTMLPropAlias.includes(prop)) {
    return true
  } else {
    return shouldForwardProp(prop)
  }
})

const gapDefaultScale = Object.keys(baseSizes)

const Box = styled('div', {
  shouldForwardProp: shouldForwardPropCustom
})(
  systemProps,
  system({
    gap: { property: 'gap', scale: 'space', defaultScale: gapDefaultScale },
    rowGap: { property: 'rowGap', scale: 'space', defaultScale: gapDefaultScale },
    columnGap: { property: 'columnGap', scale: 'space', defaultScale: gapDefaultScale },
    aspectRatio: { property: 'aspectRatio' }
  })
)

export default Box
