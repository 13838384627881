import clientConfig from '../clientConfig'
import logger from '../logger'
import serverSideFetch from '../common/serverSideFetch'
import { useAuthContext, useLoginSession } from '../components/Auth/AuthContext'
import { useCallback } from 'react'

const fetchFunc = (...params) => {
  return process.browser ? fetch(...params) : serverSideFetch(...params)
}

export function fetchSeriesSubmissionSettings(seriesId, options = {}) {
  const url = `${clientConfig.RADIO_INTERACTION_API_URL}/settings/${seriesId}`
  const begin = Date.now()
  return fetchFunc(url, {
    useProxy: false,
    ...options
  })
    .then(response => {
      const { status, statusText } = response
      logger.info(`Fetched from url ${url} ${status} ${Date.now() - begin} ms`)
      if (!response.ok) {
        const error = new Error(`Failed fetching ${url}`)
        error.status = status
        error.statusText = statusText
        error.url = url
        return Promise.reject(error)
      }
      return response
    })
    .then(response => response.json())
}

export function usePostSeriesSubmission() {
  const { isLoggedIn, isExpired, accessToken } = useLoginSession()
  const { getIdToken } = useAuthContext()

  const postSeriesSubmission = useCallback(
    async (seriesId, { acceptTerms, anonymous, phone, message }, options = {}) => {
      if (!isLoggedIn || isExpired) {
        logger.error('Missing accessToken')
        return
      }

      const url = `${clientConfig.RADIO_INTERACTION_API_URL}/submit/${seriesId}`
      const begin = Date.now()
      return fetchFunc(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'id-token': await getIdToken(),
          'Content-Type': 'application/json'
        },
        useProxy: false,
        method: 'POST',
        body: JSON.stringify({
          acceptTerms,
          anonymous,
          ...(phone && { phone }),
          message
          //   media: []
        }),
        ...options
      }).then(async response => {
        const { status, statusText, ok } = response
        logger.info(`Fetched from url ${url} ${status} ${Date.now() - begin} ms`)
        if (!ok) {
          const error = new Error(`Failed fetching ${url}`)
          error.status = status
          error.statusText = statusText
          error.url = url

          if (status === 400) {
            const body = await response.json()
            return { ok, errors: [...body?.errors] }
          }
          return { ok, errors: [] }
        }
        return { ok }
      })
    },
    [isLoggedIn, isExpired, accessToken, getIdToken]
  )

  return postSeriesSubmission
}
