const ISO_DURATION_PREFIX = 'PT'
const secondsToIsoDurationTime = (seconds = 0) => {
  if (typeof seconds !== 'number' || seconds < 0) return null

  if (seconds === 0) return `${ISO_DURATION_PREFIX}0S`

  const time = new Date(0, 0, 0, 0, 0, 0, seconds * 1000)

  const splitTime = [
    { postfix: 'H', number: time.getHours() },
    { postfix: 'M', number: time.getMinutes() },
    { postfix: 'S', number: Math.round((time.getSeconds() + time.getMilliseconds() / 1000) * 10) / 10 }
  ]

  return splitTime.reduce((isoDuration, { postfix, number }) => {
    return number > 0 ? `${isoDuration}${number}${postfix}` : isoDuration
  }, ISO_DURATION_PREFIX)
}

export default secondsToIsoDurationTime
