import { fixedEncodeURIComponent } from '../../../common/fixedEncodeURIComponent'
import { fetchSeriesById, fetchEpisodeById, fetchSeason, fetchSeriesSubmissionsById } from '../actions'
import latestEpisodesFromSeries from '../helpers/latestEpisodesFromSeries'

const pageDataBySeriesIdAndEpisodeIdServerSide = (store, { match }) => {
  const seriesLoadDataPromise = Promise.all([
    store.dispatch(fetchSeriesById(fixedEncodeURIComponent(match.params.seriesId))).loadDataPromise,
    store.dispatch(fetchEpisodeById(fixedEncodeURIComponent(match.params.programId))).loadDataPromise
  ]).then(([seriesActionRes, episodeActionRes]) => {
    const latestEpisodes = latestEpisodesFromSeries(seriesActionRes.payload)
    const foundEpisodeInLatestEpisodes = latestEpisodes.episodes.find(
      episode => episode.episodeId === match.params.programId
    )
    if (!foundEpisodeInLatestEpisodes) {
      return (
        episodeActionRes.payload._links.season &&
        store.dispatch(fetchSeason(episodeActionRes.payload._links.season)).loadDataPromise
      )
    }
  })

  const seriesSubmissionsLoadDataPromise = store.dispatch(
    fetchSeriesSubmissionsById(fixedEncodeURIComponent(match.params.seriesId))
  ).loadDataPromise

  return Promise.allSettled([seriesLoadDataPromise, seriesSubmissionsLoadDataPromise]).then(([seriesFetchResult]) => {
    const { status, value, reason } = seriesFetchResult
    return status === 'fulfilled' ? value : Promise.reject(reason)
  })
}

export default pageDataBySeriesIdAndEpisodeIdServerSide
