import { fixedEncodeURIComponent } from '../../../common/fixedEncodeURIComponent'
import {
  fetchPodcastById,
  fetchPodcastSeasonById,
  fetchPodcastEpisodeById,
  fetchSeriesSubmissionsById
} from '../actions'

const pageDataByAllPodcastIdsServerSide = (store, { match }) => {
  const seriesLoadDataPromise = Promise.all([
    store.dispatch(fetchPodcastById(fixedEncodeURIComponent(match.params.seriesId))).loadDataPromise,
    store.dispatch(
      fetchPodcastSeasonById(
        fixedEncodeURIComponent(match.params.seriesId),
        fixedEncodeURIComponent(match.params.seasonId)
      )
    ).loadDataPromise,
    store.dispatch(
      fetchPodcastEpisodeById(
        fixedEncodeURIComponent(match.params.seriesId),
        fixedEncodeURIComponent(match.params.programId)
      )
    ).loadDataPromise
  ])

  const seriesSubmissionsLoadDataPromise = store.dispatch(
    fetchSeriesSubmissionsById(fixedEncodeURIComponent(match.params.seriesId))
  ).loadDataPromise

  return Promise.allSettled([seriesLoadDataPromise, seriesSubmissionsLoadDataPromise]).then(([seriesFetchResult]) => {
    const { status, value, reason } = seriesFetchResult
    return status === 'fulfilled' ? value : Promise.reject(reason)
  })
}

export default pageDataByAllPodcastIdsServerSide
