import loadable from '@loadable/component'
import withLoadableComponentErrorBoundry from '../../../components/HOCs/withLoadableComponentErrorBoundry'

export const LazyAlphabeticIndexCategoryContainer = withLoadableComponentErrorBoundry(
  loadable(() => import('./AlphabeticIndexCategoryContainer'))
)

export const LazyAlphabeticIndexAllContainer = withLoadableComponentErrorBoundry(
  loadable(() => import('./AlphabeticIndexAllContainer'))
)
