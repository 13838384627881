/* eslint-disable react/no-unknown-property */
import React from 'react'
import { Helmet } from 'react-helmet-async'
import _get from 'lodash/get'
import clientConfig from '../clientConfig'
import { useColors } from '../RadioUI/theme/Color'

const defaultHost = 'https://radio.nrk.no'

const HtmlHead = props => {
  const path = _get(props, 'location.pathname')
  const colors = useColors()
  return (
    <Helmet defaultTitle="NRK Radio">
      <html lang="no" prefix="og: http://ogp.me/ns#" />
      <link rel="apple-touch-icon" sizes="180x180" href={`${clientConfig.DIST_PATH}/favicons/apple-touch-icon.png`} />
      <link rel="icon" type="image/png" sizes="32x32" href={`${clientConfig.DIST_PATH}/favicons/favicon-32x32.png`} />
      <link rel="icon" type="image/png" sizes="16x16" href={`${clientConfig.DIST_PATH}/favicons/favicon-16x16.png`} />
      <link rel="mask-icon" href={`${clientConfig.DIST_PATH}/favicons/safari-pinned-tab.svg`} color="#6e6e6e" />
      <link rel="shortcut icon" href={`${clientConfig.DIST_PATH}/favicons/favicon.ico`} />
      <meta name="google-site-verification" content="ZMmc5oy_1flrfEmOS93W5-oJEKv3gc5_XKXBYRliYLw" />
      <meta name="msapplication-TileColor" content={colors.main.medium} />
      <meta name="msapplication-config" content={`${clientConfig.DIST_PATH}/favicons/browserconfig.xml`} />
      <meta name="theme-color" content={colors.main.medium} />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta charSet="UTF-8" />
      <meta name="apple-itunes-app" content={`app-id=401928833, app-argument=${defaultHost}${path}`} />

      <meta property="og:type" content="website" />

      <meta property="og:site_name" content="NRK Radio" />
      {path && <meta property="og:url" content={`${defaultHost}${path}`} />}
      <meta property="og:title" content="NRK Radio" />

      <meta
        name="description"
        content="Hør hele NRK Radio sitt store utvalg av podkast, serier, dokumentarer, underholdning, film, sport og nyheter."
      />
      <meta
        property="og:description"
        content="Hør hele NRK Radio sitt store utvalg av podkast, serier, dokumentarer, underholdning, film, sport og nyheter."
      />

      <meta property="og:image" content={`${clientConfig.DIST_PATH}/favicons/android-chrome-512x512.png`} />

      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="stylesheet" href="https://static.nrk.no/core-css/major/1/core-css.min.css" />
    </Helmet>
  )
}

export default HtmlHead
