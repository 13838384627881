import React, { createContext, useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

const noop = () => {}

const EMPTY_SCRUBBER_VALUE_OBJECT = {
  startPosition: null,
  startLiveTime: null,
  startDisplayTime: null,
  endPosition: null,
  endLiveTime: null,
  endDisplayTime: null,
  playheadPosition: null,
  playheadLiveTime: null,
  playheadDisplayTime: null
}
const PlayerTimeContext = createContext({
  time: 0,
  onTimeUpdate: noop,
  scrubberValues: EMPTY_SCRUBBER_VALUE_OBJECT,
  onScrubberUpdate: noop
})

export const PlayerTimeProvider = ({ children }) => {
  const [time, setTime] = useState(0)
  const [scrubberValues, setScrubberValues] = useState(EMPTY_SCRUBBER_VALUE_OBJECT)

  const onTimeUpdate = useCallback(event => {
    setTime(Math.trunc(event.detail))
  }, [])

  const onScrubberUpdate = useCallback(event => {
    setScrubberValues(prevScrubberValues => {
      if (!prevScrubberValues.playheadPosition) return event.detail
      return Math.trunc(prevScrubberValues.playheadPosition) === Math.trunc(event.detail.playheadPosition)
        ? prevScrubberValues
        : event.detail
    })
  }, [])

  const value = useMemo(() => {
    return {
      time,
      scrubberValues,
      onTimeUpdate,
      onScrubberUpdate
    }
  }, [onScrubberUpdate, onTimeUpdate, scrubberValues, time])

  return <PlayerTimeContext.Provider value={value}>{children}</PlayerTimeContext.Provider>
}

PlayerTimeProvider.propTypes = {
  children: PropTypes.node
}

export const usePlayerTime = () => {
  const context = useContext(PlayerTimeContext)
  return context
}

export const useOnTimeUpdate = () => {
  const { onTimeUpdate } = useContext(PlayerTimeContext)
  return onTimeUpdate
}

export const useEndPosition = () => {
  const { scrubberValues } = useContext(PlayerTimeContext)
  return scrubberValues.endPosition
}

export const usePlayheadPosition = () => {
  const { scrubberValues } = useContext(PlayerTimeContext)
  return scrubberValues.playheadPosition
}

/**
 * 
 {
  "startPosition": 0,
  "startDisplayTime": 0,
  "endPosition": 1346.952,
  "endDisplayTime": 1346.952,
  "playheadPosition": 562.239828,
  "playheadDisplayTime": 562.239828
}
 */

export const useScrubberValues = () => {
  const { scrubberValues } = useContext(PlayerTimeContext)
  return scrubberValues
}
