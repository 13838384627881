import {
  CATEGORY_LOADING,
  CATEGORY_LOADED,
  CATEGORY_LOADING_CANCELLED,
  CATEDORY_LOADING_FAILED,
  CATEGORY_RESET
} from './actions'

const initialState = {
  loading: false,
  error: null,
  cancelled: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CATEGORY_LOADING: {
      return { ...initialState, loading: action.loading, ...action.payload }
    }
    case CATEGORY_LOADED: {
      return { ...initialState, loading: action.loading, ...action.payload }
    }
    case CATEGORY_LOADING_CANCELLED: {
      return { ...initialState, loading: action.loading, cancelled: action.cancelled }
    }
    case CATEDORY_LOADING_FAILED: {
      return { ...initialState, loading: action.loading, error: action.error }
    }
    case CATEGORY_RESET: {
      return { ...initialState }
    }
    default: {
      return state
    }
  }
}
