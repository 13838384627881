import sizes, { baseSizes } from './sizes'
import typography from './typography'
import breakpoints from './breakpoints'
import radii from './radii'
import zIndices from './zIndices'
import shadows from './shadows'

const space = baseSizes

const opacity = {
  '0': '0',
  '20%': '0.2',
  '40%': '0.4',
  '60%': '0.6',
  '80%': '0.8',
  '100%': '1'
}

const borders = {
  none: 0,
  '1px': '1px solid',
  '2px': '2px solid',
  '4px': '4px solid'
}

// const borderWidths = {
//   xl: "2rem",
//   lg: "1rem",
//   md: "0.5rem",
//   sm: "0.25rem",
//   xs: "0.125rem",
//   "2xs": "0.0625rem",
//   none: 0
// };

const theme = {
  breakpoints,
  zIndices,
  radii,
  opacity,
  borders,
  ...typography,
  sizes,
  shadows,
  space
}

export default theme
