const typography = {
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em'
  },
  lineHeights: {
    normal: 'normal',
    none: '1',
    shorter: '1.25',
    short: '1.375',
    base: '1.5',
    tall: '1.625',
    taller: '2'
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
  },
  fonts: {
    heading: `'NRK Sans Variable', 'LFT Etica', -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `'NRK Sans Variable', 'LFT Etica', -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`
  },
  fontSizes: {
    xs: '0.625rem',
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1rem',
    xl: '1.25rem',
    '2xl': '1.75rem',
    '3xl': '1.75rem',
    '4xl': '3rem'
  },
  extraLargeTitle: {
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '48px'
  },
  largeTitle: {
    fontWeight: '700',
    fontSize: '38px',
    lineHeight: '44px'
  },
  mediumTitle: {
    fontWeight: '800',
    fontSize: '32px',
    lineHeight: '38px'
  },
  title1: {
    fontWeight: '890',
    fontSize: '27px',
    lineHeight: '34px'
  },
  title2: {
    fontWeight: '890',
    fontSize: '22px',
    lineHeight: '28px',
    letterSpacing: '0.0125em'
  },
  title3: {
    fontWeight: '890',
    fontSize: '19px',
    lineHeight: '24px',
    letterSpacing: '0.012em'
  },
  preamble: {
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '28px'
  },
  headline: {
    fontWeight: '700',
    fontSize: '17px',
    lineHeight: '22px'
  },
  headlineExtraBold: {
    fontWeight: '800',
    fontSize: '17px',
    lineHeight: '22px'
  },
  body: {
    fontWeight: '400',
    fontSize: '17px',
    lineHeight: '24px'
  },
  callout: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '20px'
  },
  subhead: {
    fontWeight: '700',
    fontSize: '15px',
    lineHeight: '18px'
  },
  subheadRegular: {
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '18px'
  },
  footnote: {
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '18px'
  },
  caption1: {
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '16px'
  },
  caption2: {
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: '14px'
  },
  label: {
    fontWeight: '700',
    fontSize: '11px',
    lineHeight: '14px',
    letterSpacing: '0.01em',
    textTransform: 'uppercase'
  }
}

export default typography
