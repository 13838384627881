const TIME_DESIGNATOR = 'T'

export function getTimeUnit(dirtyDuration, unit) {
  const timeComponent = dirtyDuration.split(TIME_DESIGNATOR)[1] || ''
  const matchedUnit = timeComponent.toUpperCase().match(new RegExp('[+,-]?[0-9]+(\\.[0-9]+)?' + unit))

  if (!matchedUnit) {
    return 0
  }
  return parseFloat(matchedUnit[0].slice(0, -1))
}

export function toTimeObject(duration) {
  return {
    hours: getTimeUnit(duration, 'H'),
    minutes: getTimeUnit(duration, 'M'),
    seconds: getTimeUnit(duration, 'S')
  }
}

export function durationToSeconds(duration) {
  const { hours, minutes, seconds } = toTimeObject(duration)
  return hours * (60 * 60) + minutes * 60 + seconds
}

export function durationToMilliseconds(duration) {
  return durationToSeconds(duration) * 1000
}
