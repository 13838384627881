/* eslint-disable react/display-name */
import React from 'react'
import PropTypes from 'prop-types'
import typography from '../theme/typography'
import PseudoBox from '../PseudoBox'

const Text = React.forwardRef(({ size, weight, align, noWrap, variant, ...props }, ref) => {
  const noWrapProps = noWrap ? { whiteSpace: 'nowrap' } : {}
  if (variant) {
    return (
      <PseudoBox
        ref={ref}
        as="p"
        fontSize={typography[variant].fontSize}
        fontWeight={typography[variant].fontWeight}
        lineHeight={typography[variant].lineHeight}
        letterSpacing={typography[variant].letterSpacing || 0}
        textTransform={typography[variant].textTransform || 'none'}
        textAlign={align}
        verticalAlign="baseline"
        {...noWrapProps}
        {...props}
      />
    )
  }
  return (
    <PseudoBox
      ref={ref}
      as="p"
      fontSize={size}
      fontWeight={weight}
      textAlign={align}
      verticalAlign="baseline"
      {...noWrapProps}
      {...props}
    />
  )
})

Text.propTypes = {
  size: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array, PropTypes.oneOf(Object.keys(typography.fontSizes))]),
  weight: PropTypes.oneOf(Object.keys(typography.fontWeights)),
  align: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.oneOf(['left', 'center', 'right', 'start', 'end', 'justify'])
  ]),
  noWrap: PropTypes.bool,
  variant: PropTypes.oneOf([
    'extraLargeTitle',
    'largeTitle',
    'mediumTitle',
    'title1',
    'title2',
    'title3',
    'preamble',
    'headline',
    'headlineExtraBold',
    'body',
    'callout',
    'subhead',
    'subheadRegular',
    'footnote',
    'caption1',
    'caption2',
    'label'
  ])
}

Text.defaultProps = {
  size: undefined,
  weight: undefined,
  align: undefined,
  variant: undefined
}

export default Text
