import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'
import Header from './layout/Header'
import Main from './layout/Main'
import HtmlHead from './layout/HtmlHead'
import Footer from './layout/Footer'
import StickyPlayer from './components/StickyPlayer'
import routes, { ROUTE_TYPES } from './routes'
import PageView from './components/PageView/PageView'
import ScrollHandler from './components/ScrollHandler'
import LivebufferNowPoller from './components/LiveChannels/LivebufferNowPoller'
import RouteErrorBoundry from './components/RouteErrorBoundry'
import ThemeProvider from './RadioUI/ThemeProvider'
import CSSReset from './RadioUI/CSSReset'
import ServerSideHttpStatusHandler from './components/ServerSideHttpStatusHandler'
import BrowserDeprecationNotificationContainer from './components/BrowserDeprecationNotification'
import ProgressReporter from './redux/personalisation/ProgressReporter'
import AddUserToSnowplowContext from './components/AddUserToSnowplowContext'

const App = appProps => {
  return (
    <ThemeProvider>
      <CSSReset />
      <ScrollHandler />
      <AddUserToSnowplowContext />
      <LivebufferNowPoller />
      <HtmlHead {...appProps} />
      <div className="app">
        <BrowserDeprecationNotificationContainer />
        <Header />
        <StickyPlayer />
        <ProgressReporter />
        <Main>
          <Switch>
            {routes
              .filter(({ component }) => !!component || ROUTE_TYPES.MOVED_PERMANENTLY)
              .map(({ component: Component, exact, path, type, from, to, loadRouteData }) => {
                return type === ROUTE_TYPES.MOVED_PERMANENTLY ? (
                  <Redirect key={`${from}_${to}`} from={from} to={to} />
                ) : (
                  <Route
                    key={path || 404}
                    exact={exact}
                    path={path}
                    render={props => {
                      return (
                        <RouteErrorBoundry>
                          <PageView>
                            <ServerSideHttpStatusHandler {...props}>
                              <Component {...props} showMessageOnError loadRouteData={loadRouteData} />
                            </ServerSideHttpStatusHandler>
                          </PageView>
                        </RouteErrorBoundry>
                      )
                    }}
                  />
                )
              })}
          </Switch>
        </Main>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

App.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  history: PropTypes.shape({}),
  match: PropTypes.shape({})
}

export default withRouter(App)
