/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react'
import Icon from '../Icon'
import useButtonStyle from './styles'
import PseudoBox from '../PseudoBox'
import Text from '../Text'
import Flex from '../Flex'

const ButtonIcon = ({ iconId, ...props }) => {
  if (typeof iconId === 'string') {
    return <Icon fontSize="" focusable="false" iconId={iconId} color="currentColor" {...props} />
  }
  return null
}

const Button = forwardRef(
  (
    {
      disabled,
      active,
      children,
      as: Comp = 'button',
      variantColor = 'main',
      leftIcon,
      rightIcon,
      variant = 'solid',
      type = 'button',
      size = 'md',
      ...rest
    },
    ref
  ) => {
    const iconSize = size === 'md' ? 'lg' : '10px'
    const hasIcon = rightIcon || leftIcon

    const buttonStyleProps = useButtonStyle({
      variantColor,
      variant,
      size,
      hasIcon,
      active
    })

    const iconSpacing = size === 'md' ? 2 : 1
    const typeProp = type ? { type } : {}
    return (
      <PseudoBox
        disabled={disabled}
        ref={ref}
        as={Comp}
        {...typeProp}
        borderRadius="md"
        fontWeight="semibold"
        verticalAlign="middle"
        {...buttonStyleProps}
        {...rest}
      >
        <Flex as="span" maxW="100%" h="100%" align="center" justify="inherit">
          {leftIcon && (
            <ButtonIcon fontSize={iconSize} ml={-iconSpacing} mt="-1px" mr={iconSpacing} iconId={leftIcon} />
          )}
          <Text as="span" maxW="100%" d="inline-block">
            {children}
          </Text>
          {rightIcon && (
            <ButtonIcon fontSize={iconSize} mr={-iconSpacing} mt="-1px" ml={iconSpacing} iconId={rightIcon} />
          )}
        </Flex>
      </PseudoBox>
    )
  }
)

Button.displayName = 'ForwardedButton'

export default Button
