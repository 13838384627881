import logger from '../../../logger'
import { MEDIA_TYPES } from './mediaTypes'

export const toSrc = ({ episodeId, seriesId, channelId, clipId, mediaType }) => {
  switch (mediaType) {
    case MEDIA_TYPES.PODCAST: {
      return `nrk:${mediaType}:${seriesId}:${episodeId}`
    }
    case MEDIA_TYPES.PROGRAM: {
      return `nrk:${mediaType}:${episodeId}`
    }
    case MEDIA_TYPES.CHANNEL: {
      return `nrk:${mediaType}:${channelId}`
    }
    case MEDIA_TYPES.CLIP: {
      return `nrk:clip:${clipId}`
    }
    default:
      logger.error(`Unknown media type ${mediaType}`)
      throw new Error(`Unknown mediaType: ${mediaType}`)
  }
}
