import { css } from '@emotion/react'

export const linkStyle = colors => {
  return css`
    background: transparent;
    color: ${colors.contrastLight};
    &:hover,
    &:focus-visible {
      color: ${colors.light};
      outline: none;
    }

    &:disabled {
      opacity: 0.3;
    }
  `
}
