import _get from 'lodash/get'
import { fetchSeriesById, fetchSeasonById, fetchEpisode, fetchSeriesSubmissionsById } from '../actions'
import AVAILABILITY_STATUSES from '../helpers/availabilityStatuses'
import logger from '../../../logger'
import { fixedEncodeURIComponent } from '../../../common/fixedEncodeURIComponent'

const pageDataBySeriesIdAndSeasonIdServerSide = (store, { match }) => {
  const seriesLoadDataPromise = Promise.all([
    store.dispatch(fetchSeriesById(fixedEncodeURIComponent(match.params.seriesId))).loadDataPromise,
    store
      .dispatch(
        fetchSeasonById(fixedEncodeURIComponent(match.params.seriesId), fixedEncodeURIComponent(match.params.seasonId))
      )
      .loadDataPromise.catch(error => {
        if (error.status === 404) {
          const errorText = `Couldn't find season ${match.params.seasonId} for ${match.params.seriesId}`
          const notFoundError = new Error(errorText)
          notFoundError.redirectTo = `/serie/${fixedEncodeURIComponent(match.params.seriesId)}`

          logger.info(errorText)
          return Promise.reject(notFoundError)
        }
        return Promise.reject(error)
      })
  ]).then(([seriesActionRes, seasonActionRes]) => {
    const episodes = _get(seasonActionRes, 'payload._embedded.episodes._embedded.episodes', [])
    const episode =
      episodes.find(episode => episode.availability.status === AVAILABILITY_STATUSES.AVAILABLE) ||
      _get(episodes, '[0]', {})
    const episodeHref = _get(episode, '_links.self.href')

    if (episodeHref) {
      return store.dispatch(fetchEpisode({ href: episodeHref })).loadDataPromise
    }
  })

  const seriesSubmissionsLoadDataPromise = store.dispatch(
    fetchSeriesSubmissionsById(fixedEncodeURIComponent(match.params.seriesId))
  ).loadDataPromise

  return Promise.allSettled([seriesLoadDataPromise, seriesSubmissionsLoadDataPromise]).then(([seriesFetchResult]) => {
    const { status, value, reason } = seriesFetchResult
    return status === 'fulfilled' ? value : Promise.reject(reason)
  })
}

export default pageDataBySeriesIdAndSeasonIdServerSide
