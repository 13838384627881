import { combineReducers } from 'redux'
import frontPage from './pages/FrontPage/reducer'
import series from './pages/Series/reducer'
import category from './pages/Category/Recommended/reducer'
import categoryAlphabeticIndex from './pages/Category/AlphabeticIndex/reducer'
import categories from './pages/Category/CategoryList/reducer'
import liveBuffer from './components/LiveChannels/reducer'
import player from './components/LiveChannels/liveElementsReducer'
import progress from './redux/personalisation/progressReducer'
import upNext from './components/Queues/UpNext/upNextReducer'
import loginApiStatus from './pages/LoginError/reducer'

export default combineReducers({
  frontPage,
  series,
  categories,
  category,
  categoryAlphabeticIndex,
  liveBuffer,
  player,
  progress,
  upNext,
  loginApiStatus
})
