import clientConfig from '../clientConfig'

const PERSONALISATION_BASE_PATH = '/radio/userdata'
const FAVOURITES_PATH = '/favourites'
const PROGRESS_PATH = '/progress'
const NEW_EPISODES_PATH = '/newepisodes'
const NEW_EPISODES_COUNT_PATH = '/newepisodes/count'
const UP_NEXT_PATH = '/upnext'
const QUEUE_PATH = '/queue'

const defaultOptions = {
  method: 'get'
}

export async function fetchPersonalisation(path, options = {}) {
  // TODO Handle auth check another way in the future
  const { isLoggedIn, isExpired, accessToken } = window.loginSession || {}
  try {
    if (!isLoggedIn || isExpired) {
      return Promise.reject(createClientError(401))
    }

    const url = `${clientConfig.PERSONALISATION_API}${path}`

    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${accessToken}`,
      ...options.headers
    }

    const actualOptions = {
      ...defaultOptions,
      ...options,
      headers
    }

    const response = await fetch(url, actualOptions)

    if (!response.ok) {
      return Promise.reject(createClientError(response.status))
    }
    return response.status === 200 || response.status === 201 ? response.json() : null
  } catch (error) {
    return Promise.reject(error)
  }
}

function createBasePath() {
  const loginSession = window.loginSession || {}
  if (!loginSession?.user?.sub) return null

  return `${PERSONALISATION_BASE_PATH}/${loginSession.user.sub}`
}

function createFavouritesBaseUrl() {
  const basePath = createBasePath()
  return `${basePath}${FAVOURITES_PATH}`
}

function createProgressBaseUrl() {
  const basePath = createBasePath()
  return `${basePath}${PROGRESS_PATH}`
}

function createNewEpisodesBaseUrl() {
  const basePath = createBasePath()
  return `${basePath}${NEW_EPISODES_PATH}`
}

function createNewEpisodesCountBaseUrl() {
  const basePath = createBasePath()
  return `${basePath}${NEW_EPISODES_COUNT_PATH}`
}

function createUpNextBaseUrl() {
  const basePath = createBasePath()
  return `${basePath}${UP_NEXT_PATH}`
}

function createQueueBaseUrl() {
  const basePath = createBasePath()
  return `${basePath}${QUEUE_PATH}`
}

function createClientError(status) {
  const STATUS_CODES = {
    401: 'Unauthorized',
    404: 'Not found'
  }

  const message = STATUS_CODES[status] || 'Client error'
  const error = new Error(message)
  error.status = status
  error.statusMessage = message
  return error
}

function addContentIdAndFavouriteId(favourite) {
  const contentId = favourite._links.self.href.split('/').slice(-1)[0]
  const favouriteId = favourite._links.deleteFavourite.href.split('/').slice(-1)[0]
  return { ...favourite, contentId, favouriteId }
}

export const fetchFavourites = async pageUrl => {
  const favouritesUrl = pageUrl || `${createFavouritesBaseUrl()}/manual`
  const favourites = await fetchPersonalisation(favouritesUrl)
  return {
    ...favourites,
    favourites: favourites.favourites.map(addContentIdAndFavouriteId)
  }
}

export const addFavourite = (contentId, favouriteContentType) => {
  const url = `${createFavouritesBaseUrl()}/${favouriteContentType}/${contentId}`
  return fetchPersonalisation(url, { method: 'put', body: JSON.stringify({ when: null }) }).then(
    addContentIdAndFavouriteId
  )
}

export const removeFavourite = deleteFavouritePath => {
  return fetchPersonalisation(`${deleteFavouritePath}`, { method: 'delete' })
}

export const checkFavourite = (contentId, favouriteContentType) => {
  const url = `${createFavouritesBaseUrl()}/${favouriteContentType}/${contentId}`
  return fetchPersonalisation(url)
}

export const lookupFavourites = list => {
  const url = `${createFavouritesBaseUrl()}`
  return fetchPersonalisation(url, { method: 'post', body: JSON.stringify(list) })
}

export const fetchMyContent = (options = {}) => {
  const url = createFavouritesBaseUrl()
  return fetchPersonalisation(url, options)
}

export const fetchProgramProgressById = ({ contentId, progressContentType }, options) => {
  const url = `${createProgressBaseUrl()}/${progressContentType}/${contentId}`
  return fetchPersonalisation(url, options)
}

export const fetchProgramProgressByHref = (href, options) => {
  return fetchPersonalisation(href, options)
}

export const saveProgramProgress = ({ url, position, startPlaybackPosition }, options) => {
  const body = JSON.stringify({ position, startPlaybackPosition })
  return fetchPersonalisation(url, { method: 'put', body }, options)
}

export const saveProgramProgressById = (
  { contentId, progressContentType, position, startPlaybackPosition },
  options
) => {
  const body = JSON.stringify({ position, startPlaybackPosition })
  const url = `${createProgressBaseUrl()}/${progressContentType}/${contentId}`

  return fetchPersonalisation(url, { method: 'put', body }, options)
}

export const getUpNextForContent = ({ upNextContentType, contentId, queryString }) => {
  const url = `${createUpNextBaseUrl()}/${upNextContentType}/${contentId}?${queryString}`
  return fetchPersonalisation(url)
}

export const fetchProgressListForUser = (options = {}) => {
  const url = createProgressBaseUrl()
  return fetchPersonalisation(url, options)
}

export const deleteProgressByHref = removeHref => {
  return fetchPersonalisation(removeHref, {
    method: 'delete'
  })
}

export const fetchNewEpisodesForUser = (options = {}) => {
  const url = createNewEpisodesBaseUrl()
  const withQuery = options.queryParams ? '?' + new URLSearchParams(options.queryParams) : ''
  return fetchPersonalisation(url + withQuery, options)
}

export const fetchNewEpisodesCountForUser = (options = {}) => {
  const url = createNewEpisodesCountBaseUrl()
  const withQuery = options.queryParams ? '?' + new URLSearchParams(options.queryParams) : ''
  return fetchPersonalisation(url + withQuery, options)
}

export const fetchQueue = (options = {}) => {
  const url = createQueueBaseUrl()
  return fetchPersonalisation(url, options)
}

export const addToQueue = (id, type, where, after = null) => {
  const position = after
    ? {
        where,
        after
      }
    : {
        where
      }

  return fetchPersonalisation(`${createQueueBaseUrl()}/add`, {
    method: 'put',
    body: JSON.stringify({
      id,
      type,
      position
    })
  })
}

export const removeFromQueue = (id, type) => {
  return fetchPersonalisation(`${createQueueBaseUrl()}/delete`, {
    method: 'put',
    body: JSON.stringify({ id, type })
  })
}

export const addToQueueWithHref = (addHref, id, type, where) => {
  return fetchPersonalisation(addHref, {
    method: 'put',
    body: JSON.stringify({
      id,
      type,
      position: {
        where
      }
    })
  })
}

export const removeFromQueueWithHref = (removeHref, id, type) => {
  return fetchPersonalisation(removeHref, {
    method: 'delete',
    body: JSON.stringify({ id, type })
  })
}
