import * as psapi from '../../apiClients/psapi'
import { localStorage } from '../../common/storage'
import { liveChannelsNonLocal } from '../../common/liveChannels'
import isDistrictId from '../../common/isDistrictId'
import logger from '../../logger'

export const LIVEBUFFER_NOW_LOADED = 'LIVEBUFFER_NOW_LOADED'
export const LIVEBUFFER_NOW_LOADING = 'LIVEBUFFER_NOW_LOADING'
export const LIVEBUFFER_NOW_FAILED = 'LIVEBUFFER_NOW_FAILED'
export const UPDATE_SORTED = 'UPDATE_SORTED'
export const STORE_LIVEBUFFER_POLLID = 'STORE_LIVEBUFFER_POLLID'
export const CLEAR_LIVEBUFFER_POLLID = 'CLEAR_LIVEBUFFER_POLLID'
export const LIVEBUFFER_FOR_CHANNEL_LOADED = 'LIVEBUFFER_FOR_CHANNEL_LOADED'
export const LIVEBUFFER_FOR_CHANNEL_LOADING = 'LIVEBUFFER_FOR_CHANNEL_LOADING'
export const LIVEBUFFER_FOR_CHANNEL_FAILED = 'LIVEBUFFER_FOR_CHANNEL_FAILED'
export const LIVEBUFFER_FOR_CHANNEL_CLEARED = 'LIVEBUFFER_FOR_CHANNEL_CLEARED'

function liveBufferNowStarted() {
  return {
    type: LIVEBUFFER_NOW_LOADING,
    loading: true
  }
}

function liveBufferNowDone(payload) {
  return {
    type: LIVEBUFFER_NOW_LOADED,
    payload,
    loading: false
  }
}

function liveBufferNowFailed(error) {
  return {
    type: LIVEBUFFER_NOW_FAILED,
    loading: false,
    error
  }
}

function updateSorted() {
  return {
    type: UPDATE_SORTED
  }
}

function livebufferForChannelLoading() {
  return {
    type: LIVEBUFFER_FOR_CHANNEL_LOADING,
    loading: true
  }
}

function livebufferForChannelLoaded(payload) {
  return {
    type: LIVEBUFFER_FOR_CHANNEL_LOADED,
    loading: false,
    payload
  }
}

function livebufferForChannelFailed(error) {
  return {
    type: LIVEBUFFER_FOR_CHANNEL_FAILED,
    loading: false,
    error
  }
}

function livebufferForChannelCleared() {
  return {
    type: LIVEBUFFER_FOR_CHANNEL_CLEARED,
    loading: false
  }
}

export const fetchLiveChannels = () => async dispatch => {
  dispatch(liveBufferNowStarted())
  try {
    const channels = await psapi.fetchLiveChannels()
    return dispatch(liveBufferNowDone(channels))
  } catch (error) {
    dispatch(liveBufferNowFailed(error))
  }
}

export const fetchLivebufferNow = () => dispatch => {
  const abortController = new AbortController()
  dispatch(liveBufferNowStarted())
  const loadDataPromise = psapi
    .fetchChannelsLivebufferNow({ signal: abortController.signal })
    .then(({ channels }) => dispatch(liveBufferNowDone(channels)))
    .catch(error => {
      logger.error('fetchLivebufferNow failed', error)
      dispatch(liveBufferNowFailed(error))
    })

  return {
    loadDataPromise,
    abort: () => {
      abortController.abort()
    }
  }
}

export const startPollingLivebufferNow = () => dispatch => {
  logger.info('startPollingLivebufferNow')
  dispatch(liveBufferNowStarted())
  psapi
    .fetchChannelsLivebufferNow()
    .then(({ channels }) => dispatch(liveBufferNowDone(channels)))
    .catch(error => {
      dispatch(liveBufferNowFailed(error))
    })

  const pollingId = setInterval(() => {
    psapi
      .fetchChannelsLivebufferNow()
      .then(({ channels }) => dispatch(liveBufferNowDone(channels)))
      .catch(error => {
        dispatch(liveBufferNowFailed(error))
        clearInterval(pollingId)
      })
  }, 30000)

  return {
    stopPollingLivebufferNow: () => {
      logger.info('stopPollingLivebufferNow')
      clearInterval(pollingId)
    }
  }
}
export const startPollingLivebufferForChannel = channelId => dispatch => {
  logger.info('startPollingLivebufferForChannel')
  dispatch(livebufferForChannelCleared())
  dispatch(livebufferForChannelLoading())
  const abortController = new AbortController()
  const loadDataPromise = psapi
    .fetchChannelLiveBufferForChannel(channelId, { signal: abortController.signal })
    .then(channel => dispatch(livebufferForChannelLoaded(channel)))
    .catch(error => {
      dispatch(livebufferForChannelFailed(error))
    })
  const pollingId = setInterval(() => {
    psapi
      .fetchChannelLiveBufferForChannel(channelId, { signal: abortController.signal })
      .then(channel => dispatch(livebufferForChannelLoaded(channel)))
      .catch(error => {
        dispatch(livebufferForChannelFailed(error))
        clearInterval(pollingId)
      })
  }, 30000)

  return {
    loadDataPromise,
    stop: () => {
      logger.info('stopPollingLivebufferForChannel')
      abortController.abort()
      clearInterval(pollingId)
      dispatch(livebufferForChannelCleared())
    }
  }
}
// TODO: Change this to use context
export const persistLatestChannels = channelId => {
  const lastViewedFromLocalStorage = localStorage.getItem('rad-lastViewed')

  const channelIds = lastViewedFromLocalStorage
    ? lastViewedFromLocalStorage.split(',')
    : liveChannelsNonLocal.map(channel => channel.id)
  const newId = isDistrictId(channelId) ? 'p1' : channelId
  const reSortedChannelIds = [newId, ...channelIds.filter(id => id !== newId && !id.startsWith('p1_'))]
  localStorage.setItem('rad-lastViewed', reSortedChannelIds)
  return updateSorted()
}
