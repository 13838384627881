/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react'
import useButtonStyle from '../Button/styles'
import PseudoBox from '../PseudoBox'

const CustomButton = forwardRef(
  (
    {
      disabled,
      active,
      children,
      as: Comp = 'button',
      variantColor = 'main',
      variant = 'solid',
      type = 'button',
      size = 'md',
      ...rest
    },
    ref
  ) => {
    const buttonStyleProps = useButtonStyle({
      variantColor,
      variant,
      size,
      active
    })

    const typeProp = type ? { type } : {}
    return (
      <PseudoBox
        disabled={disabled}
        ref={ref}
        as={Comp}
        {...typeProp}
        borderRadius="md"
        fontWeight="semibold"
        verticalAlign="middle"
        {...buttonStyleProps}
        {...rest}
      >
        {children}
      </PseudoBox>
    )
  }
)

CustomButton.displayName = 'ForwardedCustomButton'

export default CustomButton
