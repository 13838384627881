import { UP_NEXT_LOADING, UP_NEXT_LOADED, UP_NEXT_FAILED, UP_NEXT_CLEARED } from './upNextActions'

const initialState = {
  loading: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UP_NEXT_LOADING: {
      return { ...action.payload, loading: action.loading }
    }
    case UP_NEXT_LOADED: {
      return { ...action.payload, loading: action.loading }
    }
    case UP_NEXT_FAILED: {
      return { ...action.payload, loading: action.loading, error: action.error.toString() }
    }
    case UP_NEXT_CLEARED: {
      return initialState
    }
    default: {
      return state
    }
  }
}

export default reducer
