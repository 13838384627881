import React, { useState, createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

const blue = {
  dark90: '#051425',
  dark: '#061629',
  medium: '#0A2343',
  mediumLight5: '#09203C',
  mediumLight10: '#0C366C',
  mediumLight20: '#0B3161',
  mediumLight40: '#071E3B',
  light: '#8BB5EB',
  contrastDark: '#000000',
  contrastLight: '#FFFFFF'
}

const live = {
  dark90: '#1B0103',
  dark: '#290103',
  medium: '#450502',
  mediumLight5: '#A00E02',
  mediumLight10: '#F34531',
  mediumLight20: '#C53828',
  mediumLight40: '#47140E',
  light: '#FF9E8F',
  contrastDark: '#000000',
  contrastLight: '#FFFFFF'
}

const warning = {
  dark90: '#151100',
  dark: '#1A1500',
  medium: '#332A00',
  mediumLight5: '#685B00',
  mediumLight10: '#FFF36E',
  mediumLight20: '#CFC559',
  mediumLight40: '#4B4720',
  light: '#FFF7A3',
  contrastDark: '#000000',
  contrastLight: '#FFFFFF'
}

const personal = {
  dark90: '#051425',
  dark: '#061629',
  medium: '#061629',
  mediumLight5: '#051425',
  mediumLight10: '#0C366C',
  mediumLight20: '#0B3161',
  mediumLight40: '#061D3A',
  light: '#D9E9FF',
  contrastDark: '#000000',
  contrastLight: '#FFFFFF'
}

const shades = {
  shade30: '4D', // 30%
  shade70: 'B3' // 70%
}

const fetchColor = {
  blue,
  live,
  warning,
  personal
}

const darkContrastUsers = ['warning']

export const ColorContext = createContext({
  mainColor: blue,
  setMainColor: () => {},
  liveColor: live,
  setLiveColor: () => {},
  warningColor: warning,
  setWarningColor: () => {},
  personalColor: personal,
  setPersonalColor: () => {},
  colors: {
    main: blue,
    live,
    warning,
    personal
  },
  colorContrasts: {
    main: blue.contrastLight,
    live: live.contrastLight,
    warning: warning.contrastDark,
    personal: personal.contrastDark
  }
})

export const ColorProvider = ({ children }) => {
  const [mainColor, setMainColor] = useState('blue')
  const [liveColor, setLiveColor] = useState('live')
  const [warningColor, setWarningColor] = useState('warning')
  const [personalColor, setPersonalColor] = useState('personal')
  const [colors, setColors] = useState({
    main: blue,
    live,
    warning,
    personal
  })

  const [colorContrasts, setColorContrasts] = useState({
    main: darkContrastUsers.includes(mainColor)
      ? fetchColor[mainColor].contrastDark
      : fetchColor[mainColor].contrastLight,
    live: darkContrastUsers.includes(liveColor)
      ? fetchColor[liveColor].contrastDark
      : fetchColor[liveColor].contrastLight,
    warning: darkContrastUsers.includes(warningColor)
      ? fetchColor[warningColor].contrastDark
      : fetchColor[warningColor].contrastLight,
    personal: darkContrastUsers.includes(personalColor)
      ? fetchColor[personalColor].contrastDark
      : fetchColor[personalColor].contrastLight
  })

  useMemo(() => {
    setColors({
      main: fetchColor[mainColor],
      live: fetchColor[liveColor],
      warning: fetchColor[warningColor],
      personal: fetchColor[personalColor]
    })

    setColorContrasts({
      main: darkContrastUsers.includes(mainColor)
        ? fetchColor[mainColor].contrastDark
        : fetchColor[mainColor].contrastLight,
      live: darkContrastUsers.includes(liveColor)
        ? fetchColor[liveColor].contrastDark
        : fetchColor[liveColor].contrastLight,
      warning: darkContrastUsers.includes(warningColor)
        ? fetchColor[warningColor].contrastDark
        : fetchColor[warningColor].contrastLight,
      personal: darkContrastUsers.includes(personalColor)
        ? fetchColor[personalColor].contrastDark
        : fetchColor[personalColor].contrastLight
    })
  }, [mainColor, liveColor, warningColor, personalColor])

  return (
    <ColorContext.Provider
      value={{
        mainColor: fetchColor[mainColor],
        setMainColor,
        liveColor: fetchColor[liveColor],
        setLiveColor,
        warningColor: fetchColor[warningColor],
        setWarningColor,
        personalColor: fetchColor[personalColor],
        setPersonalColor,

        colors,
        colorContrasts,
        shades
      }}
    >
      {children}
    </ColorContext.Provider>
  )
}

ColorProvider.propTypes = {
  children: PropTypes.node
}

export default ColorProvider

export const useColors = () => {
  const { colors } = useContext(ColorContext)
  return colors
}

export const useColorContrasts = () => {
  const { colorContrasts } = useContext(ColorContext)
  return colorContrasts
}

export const useShades = () => {
  const { shades } = useContext(ColorContext)
  return shades
}
