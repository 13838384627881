import { useMemo } from 'react'
import { usePlayerElement } from '../context/PlayerElementContext'
import { useCurrentlyPlayingMediaType } from '../context/PlayerMetadataContext'
import { useVideoPlayerElement } from '../context/VideoPlayerElementContext'
import { MEDIA_TYPES } from '../helpers/mediaTypes'

export const useAudioOrVideoPlayerElement = () => {
  const audioPlayerElement = usePlayerElement()

  const videoPlayerElement = useVideoPlayerElement()
  const mediaType = useCurrentlyPlayingMediaType()

  const playerElement = useMemo(() => {
    const {
      loadPlayer: loadVideoPlayer,
      loadAndStartPlayer: loadAndStartVideoPlayer,
      loadAndStartOrPlayFrom: loadAndStartOrPlayVideoFrom,
      loadAndStartOrTogglePlayFrom: loadAndStartOrTogglePlayVideoFrom,
      loadAndStartOrTogglePlayPause: loadAndStartOrTogglePlayPauseVideo,
      setVolume: setVideoVolume,
      toggleMute: toggleVideoMute,
      setMuted: setVideoMuted,
      getCurrentTime: getCurrentVideoTime,
      videoPlayerElementReady,
      $videoPlayerElement,
      ...restVideoPlayerElement
    } = videoPlayerElement

    const {
      loadPlayer: loadAudioPlayer,
      loadAndStartPlayer: loadAndStartAudioPlayer,
      loadAndStartOrPlayFrom: loadAndStartOrPlayAudioFrom,
      loadAndStartOrTogglePlayFrom: loadAndStartOrTogglePlayAudioFrom,
      loadAndStartOrTogglePlayPause: loadAndStartOrTogglePlayPauseAudio,
      setVolume: setAudioVolume,
      toggleMute: toggleAudioMute,
      setMuted: setAudioMuted,
      getCurrentTime: getCurrentAudioTime,
      playerElementReady,
      $playerElement,
      ...restAudioPlayerElement
    } = audioPlayerElement

    const loadPlayer = ({ clipId, episodeId, seasonId, seriesId, channelId, mediaType }) => {
      if (mediaType === MEDIA_TYPES.CLIP) {
        restAudioPlayerElement.pause()
        return loadVideoPlayer({ clipId, episodeId, seasonId, seriesId, channelId, mediaType })
      } else {
        return loadAudioPlayer({ clipId, episodeId, seasonId, seriesId, channelId, mediaType })
      }
    }

    const loadAndStartPlayer = ({ clipId, episodeId, seasonId, seriesId, channelId, mediaType, seekTo = null }) => {
      if (mediaType === MEDIA_TYPES.CLIP) {
        restAudioPlayerElement.pause()
        return loadAndStartVideoPlayer({ clipId, episodeId, seasonId, seriesId, channelId, mediaType, seekTo })
      } else {
        restVideoPlayerElement.pause()
        return loadAndStartAudioPlayer({ clipId, episodeId, seasonId, seriesId, channelId, mediaType, seekTo })
      }
    }

    const loadAndStartOrPlayFrom = ({ clipId, episodeId, seasonId, seriesId, channelId, mediaType, seekTo = null }) => {
      if (mediaType === MEDIA_TYPES.CLIP) {
        restAudioPlayerElement.pause()
        return loadAndStartOrPlayVideoFrom({ clipId, episodeId, seasonId, seriesId, channelId, mediaType, seekTo })
      } else {
        restVideoPlayerElement.pause()
        return loadAndStartOrPlayAudioFrom({ clipId, episodeId, seasonId, seriesId, channelId, mediaType, seekTo })
      }
    }

    const setVolume = volume => {
      setAudioVolume(volume)
      setVideoVolume(volume)
    }

    const toggleMute = () => {
      toggleAudioMute()
      toggleVideoMute()
    }

    const setMuted = mute => {
      setAudioMuted(mute)
      setVideoMuted(mute)
    }

    const getCurrentTime = () => {
      if (mediaType === MEDIA_TYPES.CLIP) {
        return getCurrentVideoTime()
      } else {
        return getCurrentAudioTime()
      }
    }

    const loadAndStartOrTogglePlayFrom = ({
      clipId,
      episodeId,
      seasonId,
      seriesId,
      channelId,
      mediaType,
      seekTo = null
    }) => {
      if (mediaType === MEDIA_TYPES.CLIP) {
        restAudioPlayerElement.pause()
        return loadAndStartOrTogglePlayVideoFrom({
          clipId,
          episodeId,
          seasonId,
          seriesId,
          channelId,
          mediaType,
          seekTo
        })
      } else {
        restVideoPlayerElement.pause()
        return loadAndStartOrTogglePlayAudioFrom({
          clipId,
          episodeId,
          seasonId,
          seriesId,
          channelId,
          mediaType,
          seekTo
        })
      }
    }

    const loadAndStartOrTogglePlayPause = ({
      clipId,
      episodeId,
      seasonId,
      seriesId,
      channelId,
      mediaType,
      seekTo = null
    }) => {
      if (mediaType === MEDIA_TYPES.CLIP) {
        restAudioPlayerElement.pause()
        return loadAndStartOrTogglePlayPauseVideo({
          clipId,
          episodeId,
          seasonId,
          seriesId,
          channelId,
          mediaType,
          seekTo
        })
      } else {
        restVideoPlayerElement.pause()
        return loadAndStartOrTogglePlayPauseAudio({
          clipId,
          episodeId,
          seasonId,
          seriesId,
          channelId,
          mediaType,
          seekTo
        })
      }
    }

    if (mediaType === MEDIA_TYPES.CLIP) {
      return {
        loadPlayer,
        loadAndStartPlayer,
        loadAndStartOrPlayFrom,
        loadAndStartOrTogglePlayFrom,
        loadAndStartOrTogglePlayPause,
        setVolume,
        toggleMute,
        setMuted,
        getCurrentTime,
        playerElementReady,
        $playerElement,
        videoPlayerElementReady,
        $videoPlayerElement,
        ...restVideoPlayerElement
      }
    } else {
      return {
        loadPlayer,
        loadAndStartPlayer,
        loadAndStartOrPlayFrom,
        loadAndStartOrTogglePlayFrom,
        loadAndStartOrTogglePlayPause,
        setVolume,
        toggleMute,
        setMuted,
        getCurrentTime,
        playerElementReady,
        $playerElement,
        videoPlayerElementReady,
        $videoPlayerElement,
        ...restAudioPlayerElement
      }
    }
  }, [audioPlayerElement, mediaType, videoPlayerElement])

  return playerElement
}

export const useIsPlayerLoading = () => {
  const { isPlayerLoading } = useAudioOrVideoPlayerElement()
  return isPlayerLoading
}
