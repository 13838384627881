import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Box from '../Box'

const Grid = forwardRef(
  (
    {
      gap,
      rowGap,
      columnGap,
      autoFlow,
      autoRows,
      autoColumns,
      templateRows,
      templateColumns,
      templateAreas,
      area,
      column,
      row,
      ...props
    },
    ref
  ) => (
    <Box
      display="grid"
      ref={ref}
      gridArea={area}
      gridTemplateAreas={templateAreas}
      gridGap={gap}
      gridRowGap={rowGap}
      gridColumnGap={columnGap}
      gridAutoColumns={autoColumns}
      gridColumn={column}
      gridRow={row}
      gridAutoFlow={autoFlow}
      gridAutoRows={autoRows}
      gridTemplateRows={templateRows}
      gridTemplateColumns={templateColumns}
      {...props}
    />
  )
)

Grid.displayName = 'Grid'
Grid.propTypes = {
  /**
   * Shorthand for Styled-System `gridTemplateColumns` prop
   */
  templateColumns: PropTypes.any,
  gap: PropTypes.any,
  rowGap: PropTypes.any,
  columnGap: PropTypes.any,
  autoFlow: PropTypes.any,
  autoRows: PropTypes.any,
  autoColumns: PropTypes.any,
  templateRows: PropTypes.any,
  templateAreas: PropTypes.any,
  area: PropTypes.any,
  column: PropTypes.any,
  row: PropTypes.any
}

export default Grid
