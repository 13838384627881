import { useEffect, useContext, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import logger from '../../../logger'
import { fetchUpNext, upNextCleared } from './upNextActions'
import { QueueContext } from '../../Queues/QueueContext'
import { fetchProgramProgressById, removeFromQueue } from '../../../apiClients/personalisation'
import {
  useCurrentlyPlayingMediaType,
  useCurrentlyPlayingSeriesId,
  usePlayerMetadata
} from '../../StickyPlayer/context/PlayerMetadataContext'
import { MEDIA_TYPES } from '../../StickyPlayer/helpers/mediaTypes'
import { CoreEvent } from '../../StickyPlayer/helpers/coreEvents'
import PROGRESS_STATES from '../../../redux/personalisation/helpers/progressStates'
import { durationToSeconds } from '../../../common/parseIsoDuration'
import { useAudioOrVideoPlayerElement } from '../../StickyPlayer/hooks/useAudioOrVideoPlayerElement'
import { usePlayerElement } from '../../StickyPlayer/context/PlayerElementContext'
import { useVideoPlayerElement } from '../../StickyPlayer/context/VideoPlayerElementContext'
import { useIsLoggedIn } from '../../Auth/AuthContext'

const UP_NEXT_CONTENT_TYPES = {
  PROGRAMS: 'programs',
  PODCAST_EPISODE: 'podcastepisode'
}

const useCurrentContentType = () => {
  const mediaType = useCurrentlyPlayingMediaType()
  // Remapping type to contentType used in api
  if (mediaType === MEDIA_TYPES.PROGRAM) {
    return UP_NEXT_CONTENT_TYPES.PROGRAMS
  } else if (mediaType === MEDIA_TYPES.PODCAST) {
    return UP_NEXT_CONTENT_TYPES.PODCAST_EPISODE
  }
  return null
}

const useCurrentContentId = () => {
  const { episodeId, seriesId: currentSeriesId, mediaType } = usePlayerMetadata()

  if (!currentSeriesId) {
    return null
  }
  return mediaType === MEDIA_TYPES.PODCAST ? `${currentSeriesId}|${episodeId}` : episodeId
}

export const useOnEndedUpNextListeners = (upNextContentContext, upNextFavoriteLevel) => {
  const inPlayerContentId = useCurrentContentId()
  const inPlayerContentType = useCurrentContentType()
  const inPlayerSeriesId = useCurrentlyPlayingSeriesId()
  const dispatch = useDispatch()
  const { loadAndStartPlayer } = useAudioOrVideoPlayerElement()
  const { $playerElement, isPlayerLoading: isAudioPlayerLoading } = usePlayerElement()
  const { $videoPlayerElement, isPlayerLoading: isVideoPlayerLoading } = useVideoPlayerElement()
  const { queue, setQueue } = useContext(QueueContext)
  const isLoggedIn = useIsLoggedIn()

  const onEnded = useCallback(
    async event => {
      if (event.detail.isPlaying || event.detail.pausedReason !== 'ended') return

      if (queue?.length > 0) {
        const next = queue[0]
        const seekTo = await fetchProgramProgressById({
          contentId: next.id,
          progressContentType: next.type
        })
          .then(({ progress, inProgress }) => {
            if (progress === PROGRESS_STATES.IN_PROGRESS) {
              return durationToSeconds(inProgress.time)
            }
          })
          .catch(() => {
            // Do nothing.
          })

        if (next.type === UP_NEXT_CONTENT_TYPES.PODCAST_EPISODE) {
          const [seriesId, episodeId] = next.id.split('|')
          loadAndStartPlayer({ episodeId, seriesId, mediaType: MEDIA_TYPES.PODCAST, seekTo })
        } else if (next.type === UP_NEXT_CONTENT_TYPES.PROGRAMS) {
          loadAndStartPlayer({
            episodeId: next.id,
            seriesId: next.id.split('|')[0],
            mediaType: MEDIA_TYPES.PROGRAM,
            seekTo
          }) // TODO: Spør Julie om serieId her
        } else {
          logger.warn('Unknown UP_NEXT_CONTENT_TYPE. upNext will not be fetched')
        }
        removeFromQueue(next.id, next.type).then(e => setQueue(e.queue))
        dispatch(upNextCleared())
      } else {
        const queryString = {}
        if (upNextContentContext) queryString.context = upNextContentContext
        if (upNextFavoriteLevel) queryString.favouriteLevel = upNextFavoriteLevel

        dispatch(
          fetchUpNext({
            contentId: inPlayerContentId,
            upNextContentType: inPlayerContentType,
            upNextSeriesId: inPlayerSeriesId,
            queryString: new URLSearchParams(queryString)
          })
        )
          .then(({ payload }) => {
            const seekTo =
              payload._embedded.progress.progress === PROGRESS_STATES.IN_PROGRESS &&
              durationToSeconds(payload._embedded.progress.inProgress.time)

            if (payload.upNextContentType === UP_NEXT_CONTENT_TYPES.PODCAST_EPISODE) {
              const [seriesId, episodeId] = payload.contentId.split('|')
              loadAndStartPlayer({ episodeId, seriesId, mediaType: MEDIA_TYPES.PODCAST, seekTo })
            } else if (payload.upNextContentType === UP_NEXT_CONTENT_TYPES.PROGRAMS) {
              loadAndStartPlayer({
                episodeId: payload.contentId,
                seriesId: payload.upNextSeriesId,
                mediaType: MEDIA_TYPES.PROGRAM,
                seekTo
              })
            } else {
              logger.warn('Unknown UP_NEXT_CONTENT_TYPE. upNext will not be fetched')
            }
            dispatch(upNextCleared())
          })
          .catch(error => {
            logger.error(error)
          })
      }
    },
    [
      dispatch,
      inPlayerContentId,
      inPlayerContentType,
      inPlayerSeriesId,
      loadAndStartPlayer,
      queue,
      setQueue,
      upNextContentContext,
      upNextFavoriteLevel
    ]
  )

  useEffect(() => {
    if (isLoggedIn && !isAudioPlayerLoading && !isVideoPlayerLoading && !!inPlayerContentId && !!inPlayerContentType) {
      logger.info('Adding ended eventlistener for upNext')
      $playerElement.addEventListener(CoreEvent.PlayingChange, onEnded)
      $videoPlayerElement.addEventListener(CoreEvent.PlayingChange, onEnded)

      return () => {
        logger.info('Removing ended eventlistener for upNext')
        $playerElement.removeEventListener(CoreEvent.PlayingChange, onEnded)
        $videoPlayerElement.removeEventListener(CoreEvent.PlayingChange, onEnded)
      }
    }
  }, [
    $playerElement,
    $videoPlayerElement,
    dispatch,
    inPlayerContentId,
    inPlayerContentType,
    inPlayerSeriesId,
    isAudioPlayerLoading,
    isLoggedIn,
    isVideoPlayerLoading,
    loadAndStartPlayer,
    onEnded,
    queue,
    setQueue
  ])
}
