import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

const ScrollHandler = ({ history, children }) => {
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'PUSH') {
        // window.scrollTo(0, 0)
      }
    })

    return unlisten
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{children}</>
}

ScrollHandler.propTypes = {
  children: PropTypes.node,
  history: PropTypes.shape({ action: PropTypes.string, listen: PropTypes.func.isRequired }).isRequired
}

export default withRouter(ScrollHandler)
