import '@webcomponents/custom-elements'
import '@webcomponents/custom-elements/src/native-shim'
import applyPonyfills from './common/ponyfills'
import React from 'react'
import { hydrateRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider as ReduxProvider } from 'react-redux'
import thunk from 'redux-thunk'
import { loadableReady } from '@loadable/component'
import * as clientMonitoring from './clientMonitoring'
import rootReducer from './rootReducer'
import App from './App'
import './style/main.nrk.less'
import { HelmetProvider } from 'react-helmet-async'
import { CacheProvider as EmotionCacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import QueueProvider from './components/Queues/QueueContext'
import TogglePlaylistProvider from './components/StickyPlayer/context/TooglePlaylistContext'
import UpNextProvider from './components/Queues/UpNext/UpNextContext'
import PreviouslyHeardProvider from './components/PreviouslyHeard/PreviouslyHeardContext'
import { DistrictChannelProvider } from './pages/Direct/DistrictChannelContext'
import ColorProvider from './RadioUI/theme/Color'
import { PlayerElementStateProvider } from './components/StickyPlayer/context/PlayerElementStateContext'
import { PlayerElementProvider } from './components/StickyPlayer/context/PlayerElementContext'
import { PlayerMetadataProvider } from './components/StickyPlayer/context/PlayerMetadataContext'
import { PlayerTimeProvider } from './components/StickyPlayer/context/PlayerTimeContext'
import { StickyPlayerProvider } from './components/StickyPlayer/context/StickyPlayerContext'
import { VideoPlayerElementStateProvider } from './components/StickyPlayer/context/VideoPlayerElementStateContext'
import { VideoPlayerElementProvider } from './components/StickyPlayer/context/VideoPlayerElementContext'
import { AuthContextProvider } from './components/Auth/AuthContext'
import { LoginNotificationProvider } from './components/Auth/LoginNotificationContext'
import { PlaybackSpeedProvider } from './components/StickyPlayer/PlayerUI/PlaybackSpeedContext'

const init = async () => {
  await applyPonyfills()

  clientMonitoring.init()

  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
          actionsBlacklist: ['PLAYER_UPDATE_CURRENT', 'LIVEBUFFER_LOADED']
        })
      : compose

  const enhancer = composeEnhancers(
    applyMiddleware(thunk)
    // other store enhancers if any
  )

  const preloadedState = window.__PRELOADED_STATE__
  delete window.__PRELOADED_STATE__

  const store = createStore(rootReducer, preloadedState, enhancer)
  const cache = createCache({ key: 'rec' })

  // eslint-disable-next-line react/prop-types
  const Providers = ({ children }) => {
    return (
      <HelmetProvider>
        <ReduxProvider store={store}>
          <BrowserRouter>
            <EmotionCacheProvider value={cache}>
              <AuthContextProvider>
                <ColorProvider>
                  <LoginNotificationProvider>
                    <PlayerTimeProvider>
                      <PlayerMetadataProvider>
                        <PlayerElementStateProvider>
                          <PlayerElementProvider>
                            <VideoPlayerElementStateProvider>
                              <VideoPlayerElementProvider>
                                <PlaybackSpeedProvider>
                                  <DistrictChannelProvider>
                                    <StickyPlayerProvider>
                                      <QueueProvider>
                                        <TogglePlaylistProvider>
                                          <UpNextProvider>
                                            <PreviouslyHeardProvider>{children}</PreviouslyHeardProvider>
                                          </UpNextProvider>
                                        </TogglePlaylistProvider>
                                      </QueueProvider>
                                    </StickyPlayerProvider>
                                  </DistrictChannelProvider>
                                </PlaybackSpeedProvider>
                              </VideoPlayerElementProvider>
                            </VideoPlayerElementStateProvider>
                          </PlayerElementProvider>
                        </PlayerElementStateProvider>
                      </PlayerMetadataProvider>
                    </PlayerTimeProvider>
                  </LoginNotificationProvider>
                </ColorProvider>
              </AuthContextProvider>
            </EmotionCacheProvider>
          </BrowserRouter>
        </ReduxProvider>
      </HelmetProvider>
    )
  }

  const $root = document.getElementById('root')

  loadableReady(() => {
    hydrateRoot(
      $root,
      // <StrictMode> Temporarily disable strict mode to get stuff out
      <Providers>
        <App />
      </Providers>
      // </StrictMode>
    )
  })
}

init()
