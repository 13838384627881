import { get, set } from 'idb-keyval'
import logger from '../../../logger'
import { MEDIA_TYPES } from '../helpers/mediaTypes'

const PERSISTED_MEDIA_KEY = 'mediaItem'

export const setPersistedMedia = async ({ episodeId, seriesId, channelId, clipId, mediaType }) => {
  try {
    await set(PERSISTED_MEDIA_KEY, { episodeId, seriesId, channelId, clipId, mediaType })
  } catch (error) {
    logger.error(error)
  }
}

const typeToMediaType = type => {
  switch (type) {
    case 'live':
      return MEDIA_TYPES.CHANNEL
    case 'podcast':
      return MEDIA_TYPES.PODCAST
    case 'onDemand':
      return MEDIA_TYPES.PROGRAM
    default:
      logger.error(`Cannot map type to mediaType from persistedMedia: ${type}`)
      return null
  }
}

export const getPersistedMedia = async () => {
  try {
    const media = await get(PERSISTED_MEDIA_KEY)
    if (media) {
      return media
    }

    const program = await get('program')
    if (program) {
      const { id, seriesId, type } = program
      const mediaType = typeToMediaType(type)
      if (!mediaType || !id) return null

      return mediaType === MEDIA_TYPES.CHANNEL ? { channelId: id, mediaType } : { episodeId: id, seriesId, mediaType }
    }

    return null
  } catch (error) {
    logger.error(error)
  }
}
