import { useMemo } from 'react'

const usePageScroll = () => {
  return useMemo(() => {
    return {
      enable: () => {
        if (document.body) {
          document.body.style.overflow = 'auto'
        }
      },
      disable: () => {
        if (document.body) {
          document.body.style.overflow = 'hidden'
        }
      }
    }
  }, [])
}

export default usePageScroll
