import subtractHours from 'date-fns/sub_hours'
import addSeconds from 'date-fns/add_seconds'
import uuid from 'uuid/v4'
import * as psapi from '../../apiClients/psapi'
import logger from '../../logger'
import { durationToSeconds } from '../../common/parseIsoDuration'
import aspDateStringToDate from '../../common/aspDateStringToDate'

export const LUDO_READY = 'LUDO_READY'
export const PLAYER_LOADING_STARTED = 'PLAYER_LOADING_STARTED'
export const PLAYER_LOADING_FINISHED = 'PLAYER_LOADING_FINISHED'
export const PLAYER_BUFFERING = 'PLAYER_BUFFERING'
export const PLAYER_SEEKING = 'PLAYER_SEEKING'
export const PLAYER_SEEKED = 'PLAYER_SEEKED'
export const PLAYER_LOADING_FAILED = 'PLAYER_LOADING_FAILED'
export const PLAYER_LIVEELEMENTS_LOADING = 'PLAYER_LIVEELEMENTS_LOADING'
export const PLAYER_LIVEELEMENTS_LOADED = 'PLAYER_LIVEELEMENTS_LOADED'
export const PLAYER_LIVEELEMENTS_FAILED = 'PLAYER_LIVEELEMENTS_FAILED'
export const PLAYER_PLAYING_STATE = 'PLAYER_PLAYING_STATE'
export const PLAYER_IS_FULLSCREEN = 'PLAYER_IS_FULLSCREEN'
export const PLAYER_SHOW_INDEXPOINTS = 'PLAYER_SHOW_INDEXPOINTS'
export const PLAYER_SHOW_CHANNELS = 'PLAYER_SHOW_CHANNELS'
export const PLAYER_UPDATE_CURRENT = 'PLAYER_UPDATE_CURRENT'
export const PLAYER_OVERWRITE_CURRENT = 'PLAYER_OVERWRITE_CURRENT'
export const PLAYER_UPDATE_VOLUME = 'PLAYER_UPDATE_VOLUME'
export const PLAYER_TOGGLE_MUTED = 'PLAYER_TOGGLE_MUTED'
export const PLAYER_UPDATE_SPEED = 'PLAYER_UPDATE_SPEED'
export const LIVE_ELEMENTS_POLLING_STARTED = 'LIVE_ELEMENTS_POLLING_STARTED'
export const LIVE_ELEMENTS_POLLING_STOPPED = 'LIVE_ELEMENTS_POLLING_STOPPED'
export const LUDO_WORKAROUND = 'LUDO_WORKAROUND'
export const LUDO_WORKAROUND_FAILED = 'LUDO_WORKAROUND_FAILED'
export const AIRPLAY_SUPPORTED = 'AIRPLAY_SUPPORTED'
export const AIRPLAY_AVAILABLE = 'AIRPLAY_AVAILABLE'
export const AIRPLAY_ACTIVE_TOGGLE = 'AIRPLAY_ACTIVE_TOGGLE'

export const TRANSITION_TIME = 350

function liveElementsStarted(clearLiveElements = false) {
  return {
    type: PLAYER_LIVEELEMENTS_LOADING,
    loading: true,
    clear: clearLiveElements
  }
}

function liveElementsFinished(payload) {
  return {
    payload,
    type: PLAYER_LIVEELEMENTS_LOADED
  }
}

function liveElementsFailed(error) {
  return {
    type: PLAYER_LIVEELEMENTS_FAILED,
    payload: error
  }
}

export const fetchLiveElements = (channelId, options = { clearLiveElements: false }) => async (dispatch, getState) => {
  const { clearLiveElements = false } = options
  dispatch(liveElementsStarted(clearLiveElements))
  try {
    const actualChannelId = channelId === 'p1' ? 'p1_oslo_akershus' : channelId
    const liveElements = await psapi.fetchLiveElements(actualChannelId, options)
    const livebuffer = getState().liveBuffer
    const channel = livebuffer?.channel?.id === actualChannelId && livebuffer.channel

    if (!channel) {
      return dispatch(liveElementsFailed('No channel found'))
    }
    const transformedLiveElements = liveElements.map(element => ({
      id: uuid(),
      start: aspDateStringToDate(element.startTime),
      end: addSeconds(aspDateStringToDate(element.startTime), durationToSeconds(element.duration)),
      image: element.imageUrl,
      programId: element.programId,
      programTitle: element.programTitle,
      title: element.title,
      artist: element.description,
      isMusic: element.type === 'Music'
    }))

    dispatch(liveElementsFinished({ type: 'live', data: addLiveElementsToPrograms(channel, transformedLiveElements) }))
  } catch (error) {
    logger.error(error)
    dispatch(liveElementsFailed(error))
  }
}

export const startPollLiveElements = channelId => dispatch => {
  logger.info('Start polling LiveElements')
  const abortController = new AbortController()
  const loadDataPromise = dispatch(
    fetchLiveElements(channelId, { signal: abortController.signal, clearLiveElements: true })
  )
  dispatch({ type: LIVE_ELEMENTS_POLLING_STARTED })
  const intervalId = setInterval(
    () => dispatch(fetchLiveElements(channelId, { signal: abortController.signal })),
    30000
  )

  return {
    loadDataPromise,
    stop: () => {
      logger.info('Stop polling LiveElements')
      clearInterval(intervalId)
      abortController.abort()
      dispatch({
        type: LIVE_ELEMENTS_POLLING_STOPPED
      })
    }
  }
}

function addLiveElementsToPrograms(channel, liveElements) {
  if (!channel.entries || channel.entries.length < 1) {
    return [wrapOrphanedLiveElements(channel, liveElements)]
  }
  const [hours, minutes, seconds] = channel.liveBufferDuration.split(':').map(str => parseInt(str, 10))
  const livebufferInMs = (hours * 60 * 60 + minutes * 60 + seconds) * 1000
  const livebufferStartTimeMs = Date.now() - livebufferInMs
  return channel.entries
    .filter(entry => {
      const actualEndInMs = new Date(entry.actualEnd).getTime()
      return actualEndInMs > livebufferStartTimeMs
    })
    .map(entry => ({
      ...entry,
      start: new Date(entry.actualStart),
      indexPoints: liveElements.filter(element => {
        const entryStart = new Date(entry.actualStart)
        const entryEnd = new Date(entry.actualEnd)
        return element.programId === entry.programId && element.start >= entryStart && element.start < entryEnd
      })
    }))
}

function wrapOrphanedLiveElements(channel, liveElements) {
  const dateNow = new Date()
  const dateLiveBufferStart = subtractHours(dateNow, 3)
  return {
    title: channel.title,
    programId: channel.id,
    start: dateLiveBufferStart,
    actualStart: dateLiveBufferStart.toISOString(),
    duration: 'PT3H',
    indexPoints: liveElements.filter(element => {
      const entryStart = dateLiveBufferStart
      const entryEnd = addSeconds(dateLiveBufferStart, dateNow)
      return element.start >= entryStart && element.start < entryEnd
    })
  }
}
