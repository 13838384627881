import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import Box from '../RadioUI/Box'
import { useIsFullscreen } from '../components/StickyPlayer/context/StickyPlayerContext'

const Main = ({ children, className = '' }) => {
  const isPlayerFullscreen = useIsFullscreen()
  return (
    <Box
      as="main"
      id="main"
      hidden={isPlayerFullscreen}
      aria-hidden={isPlayerFullscreen}
      className={`Main ${className}`}
      css={css`
        @media all and (min-width: 62em) {
          margin-top: 144px;
          min-height: calc(100vh - 305px - 154px);
        }
      `}
    >
      {children}
    </Box>
  )
}

export default Main

Main.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}
