import React, { useState, createContext, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { UP_NEXT_CONTENT_TYPES } from '../../../common/types'
import { fetchCatalogProgram, fetchCatalogPodcastEpisode } from '../../../apiClients/psapi'
import { fetchUpNext } from './upNextActions'
import logger from '../../../logger'
import { useOnEndedUpNextListeners } from './upNextHooks'
import {
  useCurrentlyPlayingId,
  useCurrentlyPlayingMediaType,
  useCurrentlyPlayingSeriesId
} from '../../StickyPlayer/context/PlayerMetadataContext'
import { MEDIA_TYPES } from '../../StickyPlayer/helpers/mediaTypes'
import { useIsPlayerLoading } from '../../StickyPlayer/hooks/useAudioOrVideoPlayerElement'
import { useIsLoggedIn } from '../../Auth/AuthContext'

const useCurrentContentType = () => {
  const mediaType = useCurrentlyPlayingMediaType()
  // Remapping type to contentType used in api
  if (mediaType === MEDIA_TYPES.PROGRAM) {
    return UP_NEXT_CONTENT_TYPES.PROGRAMS
  } else if (mediaType === MEDIA_TYPES.PODCAST) {
    return UP_NEXT_CONTENT_TYPES.PODCAST_EPISODE
  }
  return null
}

const useCurrentContentId = () => {
  const currentId = useCurrentlyPlayingId()
  const currentSeriesId = useCurrentlyPlayingSeriesId()
  const mediaType = useCurrentlyPlayingMediaType()
  if (!currentSeriesId) {
    return null
  }
  return mediaType === MEDIA_TYPES.PODCAST ? `${currentSeriesId}|${currentId}` : currentId
}

export const UpNextContext = createContext({
  upNextContent: null,
  setUpNextContent: () => {},
  upNextEpisode: null,
  setUpNextEpisode: () => {},
  upNextContentContext: null,
  setUpNextContentContext: () => {},
  upNextFavoriteLevel: null,
  setUpNextFavoriteLevel: () => {}
})

export const CONTENT_CONTEXTS = {
  SERIES: 'series',
  NEW_EPISODES: 'new_episodes'
}

export const FAVOURITE_LEVELS = {
  WITH_NOTIFICATIONS: 'favourites_with_push_notifications',
  MANUAL: 'manual_favourites',
  CONSUMED: 'consumed_favourites'
}

const UpNextProvider = ({ children }) => {
  const isLoggedIn = useIsLoggedIn()
  const dispatch = useDispatch()
  const inPlayerContentId = useCurrentContentId()
  const inPlayerContentType = useCurrentContentType()
  const inPlayerSeriesId = useCurrentlyPlayingSeriesId()
  const isPlayerLoading = useIsPlayerLoading()
  const [upNextContent, setUpNextContent] = useState(null)
  const [upNextEpisode, setUpNextEpisode] = useState(null)
  const [upNextContentContext, setUpNextContentContext] = useState(null)
  const [upNextFavoriteLevel, setUpNextFavoriteLevel] = useState(null)

  useEffect(() => {
    setUpNextEpisode(null)
    setUpNextContent(null)
    if (isLoggedIn && !isPlayerLoading && inPlayerContentId && inPlayerContentType && inPlayerSeriesId) {
      const queryString = {}
      if (upNextContentContext) queryString.context = upNextContentContext
      if (upNextFavoriteLevel) queryString.favouriteLevel = upNextFavoriteLevel

      dispatch(
        fetchUpNext({
          contentId: inPlayerContentId,
          upNextContentType: inPlayerContentType,
          upNextSeriesId: inPlayerSeriesId,
          queryString: new URLSearchParams(queryString)
        })
      )
        .then(res => {
          setUpNextContent(res.payload)
          if (res.payload.upNextContentType === UP_NEXT_CONTENT_TYPES.PODCAST_EPISODE) {
            fetchCatalogPodcastEpisode(res.payload.upNextSeriesId, res.payload.contentId.split('|')[1]).then(
              context => {
                setUpNextEpisode(context)
              }
            )
          } else {
            fetchCatalogProgram(res.payload.contentId).then(context => {
              setUpNextEpisode(context)
            })
          }
        })
        .catch(error => {
          if (error.status !== 404) {
            logger.error(error)
          }
        })
    }
  }, [
    isLoggedIn,
    inPlayerContentId,
    inPlayerContentType,
    inPlayerSeriesId,
    dispatch,
    isPlayerLoading,
    upNextContentContext,
    upNextFavoriteLevel
  ])

  useOnEndedUpNextListeners(upNextContentContext, upNextFavoriteLevel)

  return (
    <UpNextContext.Provider
      value={{
        upNextContent,
        setUpNextContent,
        upNextEpisode,
        setUpNextEpisode,
        upNextContentContext,
        setUpNextContentContext,
        upNextFavoriteLevel,
        setUpNextFavoriteLevel
      }}
    >
      {children}
    </UpNextContext.Provider>
  )
}

UpNextProvider.propTypes = {
  children: PropTypes.node
}

export const useSetUpNextContentContext = () => {
  const { setUpNextContentContext } = useContext(UpNextContext)
  return setUpNextContentContext
}

export const useSetUpNextFavoriteLevel = () => {
  const { setUpNextFavoriteLevel } = useContext(UpNextContext)
  return setUpNextFavoriteLevel
}

export default UpNextProvider
