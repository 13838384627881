import PropTypes from 'prop-types'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import NavItem from '../RadioUI/NavItem'
import clientConfig from '../clientConfig'
import Box from '../RadioUI/Box'
import { linkStyle } from '../RadioUI/theme/link'
import { useColors } from '../RadioUI/theme/Color'

const noop = () => {}

const Home = ({ location, onClick = noop }) => {
  const ariaCurrentPage = '/'.startsWith(location.pathname) ? 'page' : null
  const colors = useColors()

  return (
    <Link
      data-test="home-logo"
      className="nav-link nav-link-home"
      aria-label="Hjem"
      aria-current={ariaCurrentPage}
      onClick={onClick}
      to="/"
      css={linkStyle(colors.main)}
    >
      <Box
        as="img"
        size="24px"
        d={{ base: 'none', md: 'block' }}
        p={{ base: 2, md: 4 }}
        mr={{ base: 0, md: 4 }}
        pl={{ base: 0, md: 0 }}
        w={{ base: '108px', lg: '144px' }}
        h="auto"
        src={`${clientConfig.DIST_PATH}/images/channel-logos/nrk-logo-nrk-radio.svg`}
        alt=""
        aria-hidden="true"
      />

      <NavItem iconId="nrk-home" text="Forside" d={{ base: 'flex', md: 'none' }} />
    </Link>
  )
}

Home.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  onClick: PropTypes.func
}

export default withRouter(Home)
