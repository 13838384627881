import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { loginHasErrored } from './actions'
import LoginError from './LoginError'

const withRedux = connect(null, dispatch => {
  return {
    loginHasErrored: bindActionCreators(loginHasErrored, dispatch)
  }
})

export default compose(withRouter, withRedux)(LoginError)
