import clientConfig from './clientConfig'

const LOG_LEVEL_MAP = {
  // emerg: 0,
  // alert: 1,
  // crit: 2,
  error: 3,
  // warning: 4,
  warn: 4,
  // notice: 5,
  info: 6,
  debug: 7
}

const noop = () => {}

let logger

function createBrowserLogger() {
  const BROWSER_LOG_LEVEL = clientConfig.BROWSER_LOG_LEVEL
  const numericLogLevel = LOG_LEVEL_MAP[BROWSER_LOG_LEVEL]

  if (window && window.console && Number.isInteger(numericLogLevel)) {
    return {
      error: numericLogLevel >= LOG_LEVEL_MAP.error ? console.error.bind(console) : noop,
      warn: numericLogLevel >= LOG_LEVEL_MAP.warn ? console.warn.bind(console) : noop,
      info: numericLogLevel >= LOG_LEVEL_MAP.info ? console.info.bind(console) : noop,
      debug: numericLogLevel >= LOG_LEVEL_MAP.debug ? console.debug.bind(console) : noop
    }
  }

  return {
    error: noop,
    warn: noop,
    info: noop,
    debug: noop
  }
}

function initLogger() {
  if (process.browser) {
    logger = createBrowserLogger()
  } else {
    const winston = require('winston')
    logger = winston.createLogger({
      transports: [new winston.transports.Console()],
      format: winston.format.simple()
    })
  }
}

export default (() => {
  if (!logger) {
    initLogger()
  }

  return logger
})()
