import React from 'react'
import { Link } from 'react-router-dom'
import Box from '../../RadioUI/Box'
import Grid from '../../RadioUI/Grid'
import { linkStyle } from '../../RadioUI/theme/link'
import { useColors } from '../../RadioUI/theme/Color'
import { css } from '@emotion/react'
import clientConfig from '../../clientConfig'

const HeaderTop = () => {
  const colors = useColors()

  return (
    <Grid p={4} w="full" alignItems="center" templateColumns={{ base: '1fr auto 1fr', md: '1fr 1fr' }}>
      <Box>
        <Box
          as="a"
          data-ga-category="external-link"
          data-ga-action="other-header"
          href="https://www.nrk.no/"
          css={[
            linkStyle(colors.main),
            css`
              opacity: 0.3;
              font-size: 12px;
            `
          ]}
        >
          NRK.no
        </Box>
      </Box>
      <Box d={{ base: 'block', md: 'none' }}>
        <Link to="/" className="visible-small" css={linkStyle(colors.main)}>
          <Box
            as="img"
            w="108px"
            h="18px"
            src={`${clientConfig.DIST_PATH}/images/channel-logos/nrk-logo-nrk-radio.svg`}
            alt=""
            aria-hidden="true"
          />
        </Link>
      </Box>
      <Box justifySelf="end"></Box>
    </Grid>
  )
}

export default HeaderTop
