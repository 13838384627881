import * as psapi from '../../apiClients/psapi'
import logger from '../../logger'

export const FRONTPAGE_LOADED = 'FRONTPAGE_LOADED'
export const FRONTPAGE_LOADING = 'FRONTPAGE_LOADING'
export const FRONTPAGE_LOADING_FAILED = 'FRONTPAGE_LOADING_FAILED'
export const FRONTPAGE_CLEARED = 'FRONTPAGE_CLEARED'

function loadSectionsStarted() {
  return {
    type: FRONTPAGE_LOADING,
    loading: true
  }
}

function loadSectionsDone(payload) {
  return {
    type: FRONTPAGE_LOADED,
    payload,
    loading: false
  }
}

function loadSectionsFailed(error = new Error('Failed fetching discover')) {
  return {
    type: FRONTPAGE_LOADING_FAILED,
    loading: false,
    error
  }
}

export const fetchFrontPage = () => dispatch => {
  const abortController = new AbortController()
  dispatch(loadSectionsStarted())
  const loadDataPromise = psapi
    .fetchFrontPage({ signal: abortController.signal })
    .then(data => {
      return dispatch(loadSectionsDone(data))
    })
    .catch(error => {
      dispatch(loadSectionsFailed(error))
      return Promise.reject(error)
    })
  return {
    abort: () => abortController.abort(),
    loadDataPromise
  }
}

export const fetchSegmentedFrontPage = (abortController = new AbortController()) => async dispatch => {
  dispatch(loadSectionsStarted())
  try {
    const segment = await psapi.fetchSegment({ signal: abortController.signal })
    const segmentedFrontPage = await psapi.fetchSegmentedPage('discover', segment, { signal: abortController.signal })
    return dispatch(loadSectionsDone({ ...segmentedFrontPage, segment }))
  } catch (error) {
    logger.error(`Failed fetching segmented frontpage. Fallback to regular frontpage`, error)
    dispatch(loadSectionsFailed(error))
    return dispatch(fetchFrontPage())
  }
}

export const fetchPreview = ({ pageId }) => dispatch => {
  const abortController = new AbortController()
  dispatch(loadSectionsStarted())
  const loadDataPromise = psapi
    .fetchPageV3(pageId, { signal: abortController.signal })
    .then(data => {
      return dispatch(loadSectionsDone(data))
    })
    .catch(error => {
      dispatch(loadSectionsFailed(error))
      return Promise.reject(error)
    })
  return {
    abort: () => abortController.abort(),
    loadDataPromise
  }
}

export const clearFrontPage = () => {
  return {
    type: FRONTPAGE_CLEARED,
    loading: false
  }
}
