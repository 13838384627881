import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSub } from './Auth/AuthContext'
import { addUserToSnowplowContext } from '../clientMonitoring'

const AddUserToSnowplowContext = props => {
  const sub = useSub()
  useEffect(() => {
    if (sub) {
      addUserToSnowplowContext(sub)
    }
  }, [sub])
  return <>{props.children}</>
}

AddUserToSnowplowContext.propTypes = {
  children: PropTypes.node
}

export default AddUserToSnowplowContext
