import * as psapi from '../../../apiClients/psapi'
import logger from '../../../logger'

export const CATEGORY_LOADING = 'CATEGORY_LOADING'
export const CATEGORY_LOADED = 'CATEGORY_LOADED'
export const CATEGORY_LOADING_CANCELLED = 'CATEGORY_LOADING_CANCELLED'
export const CATEDORY_LOADING_FAILED = 'CATEDORY_LOADING_FAILED'
export const CATEGORY_RESET = 'CATEGORY_RESET'

function categoryLoading(payload) {
  return {
    type: CATEGORY_LOADING,
    loading: true,
    payload
  }
}

function categoryLoaded(payload) {
  return {
    type: CATEGORY_LOADED,
    loading: false,
    payload
  }
}

function categoryLoadingCancelled() {
  return {
    type: CATEGORY_LOADING_CANCELLED,
    loading: false,
    cancelled: true
  }
}

function categoryLoadingFailed(error, payload) {
  return {
    type: CATEDORY_LOADING_FAILED,
    loading: false,
    error,
    payload
  }
}

export function resetCategory() {
  return {
    type: CATEGORY_RESET
  }
}

export const fetchCategory = ({ categoryId }) => dispatch => {
  dispatch(categoryLoading({ categoryId }))
  const abortController = new AbortController()
  const loadDataPromise = psapi
    .fetchCategory(categoryId, { signal: abortController.signal, useProxy: false })
    .then(payload => {
      return dispatch(categoryLoaded({ ...payload, categoryId }))
    })
    .catch(error => {
      if (error.name === 'AbortError') {
        dispatch(categoryLoadingCancelled())
      } else {
        logger.error(`Failed fetching category: ${error.message}`)
        dispatch(categoryLoadingFailed(error, { categoryId }))
      }
      return Promise.reject(error)
    })

  return { loadDataPromise, abort: () => abortController.abort() }
}
