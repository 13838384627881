import _get from 'lodash/get'
import { fetchPodcastById, fetchEpisode, fetchSeriesSubmissionsById } from '../actions'
import latestEpisodesFromSeries from '../helpers/latestEpisodesFromSeries'
import AVAILABILITY_STATUSES from '../helpers/availabilityStatuses'
import SERIES_TYPES from '../helpers/seriesTypes'
import { fixedEncodeURIComponent } from '../../../common/fixedEncodeURIComponent'
import { catalogSeriesLinkToPath, preferredOverQueryKey } from '../../../common/preferredSeries'

const pageDataByPodcastIdServerSide = (store, { match, redirect, path }) => {
  const seriesLoadDataPromise = store
    .dispatch(fetchPodcastById(fixedEncodeURIComponent(match.params.seriesId)))
    .loadDataPromise.then(seriesActionRes => {
      if (seriesActionRes.payload._links?.preferred) {
        redirect(
          `${catalogSeriesLinkToPath(seriesActionRes.payload._links?.preferred.href)}?${new URLSearchParams({
            [preferredOverQueryKey]: path
          })}`
        )
      }
      const latestEpisodes = latestEpisodesFromSeries(seriesActionRes.payload)
      const latestEpisode = latestEpisodes.episodes.find(
        episode => episode.availability.status === AVAILABILITY_STATUSES.AVAILABLE
      )
      const firstSeasonId = _get(seriesActionRes, 'payload._links.seasons[0].name')
      const seriesType = _get(seriesActionRes, 'payload.seriesType')
      const episodeHref = _get(latestEpisode, '_links.self.href')

      if (episodeHref) {
        return store.dispatch(fetchEpisode({ href: episodeHref })).loadDataPromise
      } else if (firstSeasonId && seriesType !== SERIES_TYPES.UMBRELLA) {
        const error = new Error(`Series is missing latest episodes: ${match.params.seriesId}`)
        error.redirectTo = `/podkast/${fixedEncodeURIComponent(match.params.seriesId)}/sesong/${firstSeasonId}`
        return Promise.reject(error)
      }
    })

  const seriesSubmissionsLoadDataPromise = store.dispatch(
    fetchSeriesSubmissionsById(fixedEncodeURIComponent(match.params.seriesId))
  ).loadDataPromise

  return Promise.allSettled([seriesLoadDataPromise, seriesSubmissionsLoadDataPromise]).then(([seriesFetchResult]) => {
    const { status, value, reason } = seriesFetchResult
    return status === 'fulfilled' ? value : Promise.reject(reason)
  })
}

export default pageDataByPodcastIdServerSide
