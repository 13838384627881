import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { localStorage } from '../../common/storage'
import isDistrictId from '../../common/isDistrictId'
import logger from '../../logger'

const USER_DISTRICT_CHANNEL_STORAGE_KEY = 'userDistrictChannelId'
const noop = () => {}
const DistrictChannelContext = createContext({
  userDistrictChannelId: null,
  setUserDistrictChannelId: noop,
  openDistrictChannelSelect: noop,
  closeDistrictChannelSelect: noop,
  selectDialogRef: null
})

export const DistrictChannelProvider = ({ children }) => {
  const [userDistrictChannelId, setUserDistrictChannelIdState] = useState()

  useEffect(() => {
    setUserDistrictChannelIdState(localStorage.getItem(USER_DISTRICT_CHANNEL_STORAGE_KEY))
  }, [])

  const value = useMemo(() => {
    return {
      userDistrictChannelId,
      setUserDistrictChannelId: channelId => {
        if (isDistrictId(channelId)) {
          localStorage.setItem(USER_DISTRICT_CHANNEL_STORAGE_KEY, channelId)
          setUserDistrictChannelIdState(channelId)
        } else {
          logger.warn(`ChannelId ${channelId} is not a valid district id`)
        }
      }
    }
  }, [userDistrictChannelId])

  return <DistrictChannelContext.Provider value={value}>{children}</DistrictChannelContext.Provider>
}

DistrictChannelProvider.propTypes = {
  children: PropTypes.node
}

export const useUserDistrictChannelId = () => {
  const context = useContext(DistrictChannelContext)
  return context
}
