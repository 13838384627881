/* Wrapper for localstorage and sessionstorage to prevent error when not available (e.g. in node) */

const hasWindowGlobal = typeof window !== 'undefined'
const localStorageExists = hasWindowGlobal && typeof window.localStorage !== 'undefined'
const sessionStorageExists = hasWindowGlobal && typeof window.sessionStorage !== 'undefined'
const noop = () => {}

export const localStorage = !localStorageExists
  ? { getItem: noop, setItem: noop, removeItem: noop }
  : {
      getItem: key => {
        try {
          return window.localStorage.getItem(key)
        } catch (error) {
          console.error(`Localstorage: ${error}`)
          return null
        }
      },
      setItem: (key, value) => {
        try {
          return window.localStorage.setItem(key, value)
        } catch (error) {
          console.error(`Localstorage: ${error}`)
          return false
        }
      },
      removeItem: key => {
        try {
          return window.localStorage.removeItem(key)
        } catch (error) {
          console.error(`Localstorage: ${error}`)
          return false
        }
      }
    }

export const sessionStorage = !sessionStorageExists
  ? { getItem: noop, setItem: noop, removeItem: noop, clear: noop }
  : {
      getItem: key => {
        try {
          return window.sessionStorage.getItem(key)
        } catch (error) {
          console.error(`Sessionstorage: ${error}`)
          return null
        }
      },
      setItem: (key, value) => {
        try {
          return window.sessionStorage.setItem(key, value)
        } catch (error) {
          console.error(`Sessionstorage: ${error}`)
          return false
        }
      },
      removeItem: key => {
        try {
          return window.sessionStorage.removeItem(key)
        } catch (error) {
          console.error(`Sessionstorage: ${error}`)
          return false
        }
      },
      clear: () => {
        try {
          return window.sessionStorage.clear()
        } catch (error) {
          console.error(`Sessionstorage: ${error}`)
          return false
        }
      }
    }
