import React from 'react'
import HeaderTop from './HeaderTop'
import Menu from './Menu'
import Box from '../RadioUI/Box'
import ContentWidth from '../RadioUI/ContentWidth'
import { css } from '@emotion/react'
import { useColors } from '../RadioUI/theme/Color'
import { useLocation } from 'react-router-dom'
import { useIsFullscreen } from '../components/StickyPlayer/context/StickyPlayerContext'

const absoulteStyle = bgColor => css`
  background: ${bgColor};
  @media all and (min-width: 1024px) {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1397;
  }
`

const Header = () => {
  const isPlayerFullscreen = useIsFullscreen()
  const colors = useColors()
  const location = useLocation()
  const bgColor = location?.pathname === '/404' ? colors.main.dark : 'none'

  return (
    <Box
      className="Header"
      as="header"
      hidden={isPlayerFullscreen}
      aria-hidden={isPlayerFullscreen}
      css={absoulteStyle(bgColor)}
    >
      <HeaderTop />
      <ContentWidth>
        <Menu />
      </ContentWidth>
    </Box>
  )
}

export default Header
