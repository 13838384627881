import {
  LETTERS_CLEARED,
  LETTERS_LOADED,
  LETTERS_LOADING,
  LETTERS_LOADING_FAILED,
  LETTER_LOADED,
  LETTER_LOADING,
  LETTER_LOADING_FAILED,
  LETTER_PAGE_LOADED,
  LETTER_PAGE_LOADING,
  LETTER_PAGE_LOADING_FAILED
} from './actions'

const initialState = {
  lettersLoading: false,
  categoryId: null,
  letters: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LETTERS_LOADING: {
      return { ...state, lettersLoading: action.loading, ...action.payload }
    }
    case LETTERS_LOADED: {
      return { ...state, lettersLoading: action.loading, ...action.payload }
    }
    case LETTERS_LOADING_FAILED: {
      return {
        ...state,
        letters: initialState.letters,
        lettersLoading: action.loading,
        category: action.payload.category,
        error: action.error.toString()
      }
    }
    case LETTERS_CLEARED: {
      return initialState
    }
    case LETTER_LOADING:
    case LETTER_LOADED:
    case LETTER_LOADING_FAILED: {
      return {
        ...state,
        [action.payload.letter]: {
          ...state[action.payload.letter],
          loading: action.loading,
          error: action.error?.toString?.(),
          ...action.payload
        }
      }
    }

    case LETTER_PAGE_LOADING: {
      return {
        ...state,
        [action.payload.letter]: {
          ...state[action.payload.letter],
          pageLoading: action.loading
        }
      }
    }
    case LETTER_PAGE_LOADED: {
      const { series = [], ...restPayload } = action.payload
      const nextSeries = state[action.payload.letter]?.series
        ? [...state[action.payload.letter].series, ...series]
        : series
      const nextState = {
        ...state,
        [action.payload.letter]: {
          ...state[action.payload.letter],
          series: nextSeries,
          ...restPayload,
          pageLoading: action.loading
        }
      }
      return nextState
    }

    case LETTER_PAGE_LOADING_FAILED: {
      return {
        ...state,
        [action.payload.letter]: {
          ...state[action.payload.letter],
          pageLoading: action.loading
        }
      }
    }
    default:
      return state
  }
}

export default reducer
