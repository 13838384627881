import { fetchCatalogHref, fetchPlaybackMetadataClip } from '../../../apiClients/psapi'
import logger from '../../../logger'

export const EXTRAMATERIAL_CLIPS_LOADING = 'EXTRAMATERIAL_CLIPS_LOADING'
export const EXTRAMATERIAL_CLIPS_LOADED = 'EXTRAMATERIAL_CLIPS_LOADED'
export const EXTRAMATERIAL_CLIPS_FAILED = 'EXTRAMATERIAL_CLIPS_FAILED'
export const EXTRAMATERIAL_CLIPS_CANCELLED = 'EXTRAMATERIAL_CLIPS_CANCELLED'
export const EXTRAMATERIAL_CLIPS_CLEARED = 'EXTRAMATERIAL_CLIPS_CLEARED'

export const FALLBACK_CLIP_LOADING = 'FALLBACK_CLIP_LOADING'
export const FALLBACK_CLIP_LOADED = 'FALLBACK_CLIP_LOADED'
export const FALLBACK_CLIP_FAILED = 'FALLBACK_CLIP_FAILED'
export const FALLBACK_CLIP_CANCELLED = 'FALLBACK_CLIP_CANCELLED'

export const EXTRAMATERIAL_CLIPS_PAGE_LOADING = 'EXTRAMATERIAL_CLIPS_PAGE_LOADING'
export const EXTRAMATERIAL_CLIPS_PAGE_LOADED = 'EXTRAMATERIAL_CLIPS_PAGE_LOADED'
export const EXTRAMATERIAL_CLIPS_PAGE_FAILED = 'EXTRAMATERIAL_CLIPS_PAGE_FAILED'
export const EXTRAMATERIAL_CLIPS_PAGE_CANCELLED = 'EXTRAMATERIAL_CLIPS_PAGE_CANCELLED'
export const EXTRAMATERIAL_CLIPS_PAGE_CLEARED = 'EXTRAMATERIAL_CLIPS_PAGE_CLEARED'

const extraMaterialClipsLoading = payload => {
  return {
    type: EXTRAMATERIAL_CLIPS_LOADING,
    loading: true,
    payload
  }
}

const extraMaterialClipsLoaded = payload => {
  return {
    type: EXTRAMATERIAL_CLIPS_LOADED,
    loading: false,
    payload
  }
}

const extraMaterialClipsFailed = (error, payload) => {
  return {
    type: EXTRAMATERIAL_CLIPS_FAILED,
    loading: false,
    error,
    payload
  }
}

// const extraMaterialClipsCancelled = () => {
//   return {
//     type: EXTRAMATERIAL_CLIPS_CANCELLED,
//     loading: false
//   }
// }

export const extraMaterialClipsCleared = () => {
  return {
    type: EXTRAMATERIAL_CLIPS_CLEARED,
    loading: false
  }
}

export const fetchExtraMaterial = href => dispatch => {
  const abortController = new AbortController()
  dispatch(extraMaterialClipsLoading({ seriesId: href }))

  const extraMaterialPromise = fetchCatalogHref(
    { href },
    {
      signal: abortController.signal
    }
  )
    .then(extraMaterial => dispatch(extraMaterialClipsLoaded({ seriesId: href, ...extraMaterial })))
    .catch(error => dispatch(extraMaterialClipsFailed(error, { seriesId: href })))

  return {
    loadDataPromise: extraMaterialPromise,
    abort: () => abortController.abort()
  }
}

const fallbackClipLoading = () => {
  return {
    type: FALLBACK_CLIP_LOADING,
    loading: true
  }
}

const fallbackCliplLoaded = payload => {
  return {
    type: FALLBACK_CLIP_LOADED,
    loading: false,
    payload
  }
}

const fallbackClipFailed = error => {
  return {
    type: FALLBACK_CLIP_FAILED,
    loading: false,
    error
  }
}

export const fetchFallbackClip = extraMaterialId => dispatch => {
  const abortController = new AbortController()
  dispatch(fallbackClipLoading())
  const fallbackClipPromise = fetchPlaybackMetadataClip(extraMaterialId, {
    signal: abortController.signal,
    useProxy: false,
    headers: { Accept: 'application/json' },
    baseUrl: 'https://psapi.nrk.no'
  })
    .then(({ _links, displayAspectRatio, duration, id, preplay }) =>
      dispatch(fallbackCliplLoaded({ extraMaterialId, _links, displayAspectRatio, duration, id, preplay }))
    )
    .catch(error => {
      logger.error(error)
      dispatch(fallbackClipFailed(error))
    })

  return {
    loadDataPromise: fallbackClipPromise,
    abort: () => {
      abortController.abort()
    }
  }
}

const extraMaterialClipsPageLoading = payload => {
  return {
    type: EXTRAMATERIAL_CLIPS_PAGE_LOADING,
    payload,
    loading: true
  }
}

const extraMaterialClipsPageLoaded = payload => {
  return {
    type: EXTRAMATERIAL_CLIPS_PAGE_LOADED,
    payload,
    loading: false
  }
}

const extraMaterialClipsPageFailed = (error, payload) => {
  return {
    type: EXTRAMATERIAL_CLIPS_PAGE_FAILED,
    payload,
    error,
    loading: false
  }
}

export const extraMaterialClipsPageCleared = () => {
  return {
    type: EXTRAMATERIAL_CLIPS_PAGE_CLEARED,
    loading: false
  }
}

export const fetchExtraMaterialClipsPage = href => dispatch => {
  const extraMaterialClipsPageAbortController = new AbortController()
  dispatch(extraMaterialClipsPageLoading({ href }))
  const loadDataPromise = fetchCatalogHref({ href }, { signal: extraMaterialClipsPageAbortController.signal })
    .then(extraMaterialPage => dispatch(extraMaterialClipsPageLoaded(extraMaterialPage)))
    .catch(error => {
      dispatch(extraMaterialClipsPageFailed(error, { href }))
    })
  return {
    loadDataPromise,
    abort: () => extraMaterialClipsPageAbortController.abort()
  }
}
