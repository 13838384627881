import React, { createContext, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

const LoginNotificationContext = createContext({
  shouldShowNotification: false,
  showNotification: () => {},
  hideNotification: () => {}
})

export const LoginNotificationProvider = ({ children }) => {
  const [shouldShowNotification, setShouldShowNotification] = useState(false)

  const value = useMemo(() => {
    return {
      shouldShowNotification,
      showNotification: () => {
        setShouldShowNotification(true)
      },
      hideNotification: () => {
        setShouldShowNotification(false)
      }
    }
  }, [shouldShowNotification])

  return <LoginNotificationContext.Provider value={value}>{children}</LoginNotificationContext.Provider>
}

LoginNotificationProvider.propTypes = {
  children: PropTypes.node
}

export const useLoginNotification = () => {
  const loginNotificationContext = useContext(LoginNotificationContext)
  return loginNotificationContext
}
