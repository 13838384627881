import React, { createContext, useCallback, useContext, useMemo, useReducer, useRef } from 'react'
import PropTypes from 'prop-types'
import { fetchMusicPlaylist, fetchPlaybackMetadata } from '../../../apiClients/psapi'
import logger from '../../../logger'
import { MEDIA_TYPES } from '../helpers/mediaTypes'
import { mergeIndexPointsAndPlaylist } from '../helpers/mergeIndexPointsAndPlaylist'
import { usePlayerTime } from './PlayerTimeContext'

const initialMetadataState = {
  loading: false,
  error: null,
  episodeId: null,
  seasonId: null,
  seriesId: null,
  channelId: null,
  clipId: null,
  mediaType: null,
  metadata: {}
}

const METADATA_ACTION_TYPES = {
  METADATA_LOADING: 'METADATA_LOADING',
  METADATA_LOADED: 'METADATA_LOADED',
  METADATA_ERROR: 'METADATA_ERROR',
  UPDATE_MEDIATYPE: 'UPDATE_MEDIATYPE',
  METADATA_CLEAR: 'METADATA_CLEAR'
}

const metadataReducer = (state, action) => {
  switch (action.type) {
    case METADATA_ACTION_TYPES.METADATA_LOADING:
      return { ...state, ...action.payload, loading: true }
    case METADATA_ACTION_TYPES.METADATA_LOADED:
      return { ...state, ...action.payload, loading: false }
    case METADATA_ACTION_TYPES.METADATA_ERROR:
      return { ...state, ...action.payload, loading: false, error: action.error.toString() }
    case METADATA_ACTION_TYPES.UPDATE_MEDIATYPE: {
      return { ...state, mediaType: action.payload }
    }
    case METADATA_ACTION_TYPES.METADATA_CLEAR:
      return initialMetadataState
  }
}

const initialPlaylistState = {
  loading: false,
  error: null,
  episodeId: null,
  playlist: []
}

const PLAYLIST_ACTION_TYPES = {
  PLAYLIST_LOADING: 'PLAYLIST_LOADING',
  PLAYLIST_LOADED: 'PLAYLIST_LOADED',
  PLAYLIST_ERROR: 'PLAYLIST_ERROR',
  PLAYLIST_CLEAR: 'PLAYLIST_CLEAR'
}

const playlistReducer = (state, action) => {
  switch (action.type) {
    case PLAYLIST_ACTION_TYPES.PLAYLIST_LOADING:
      return { ...state, ...action.payload, loading: true }
    case PLAYLIST_ACTION_TYPES.PLAYLIST_LOADED:
      return { ...state, ...action.payload, loading: false }
    case PLAYLIST_ACTION_TYPES.PLAYLIST_ERROR:
      return { ...state, ...action.payload, loading: false, error: action.error.toString() }
    case PLAYLIST_ACTION_TYPES.PLAYLIST_CLEAR:
      return initialPlaylistState
  }
}

const PlayerMetadataContext = createContext({
  ...initialMetadataState,
  loadMetadata: () => {},
  updateMediaType: () => {}
})

export const PlayerMetadataProvider = ({ children }) => {
  const [metadataState, metadataDispatch] = useReducer(metadataReducer, initialMetadataState)
  const [playlistState, playlistDispatch] = useReducer(playlistReducer, initialPlaylistState)
  const metadataAbortRef = useRef(() => {})
  const playlistAbortRef = useRef(() => {})

  const loadMetadata = useCallback(
    ({ clipId, episodeId, seasonId, seriesId, channelId, mediaType }) => {
      if (
        ((mediaType === MEDIA_TYPES.PROGRAM && !!episodeId && metadataState.episodeId === episodeId) ||
          (mediaType === MEDIA_TYPES.CHANNEL && !!channelId && metadataState.channelId === channelId) ||
          (mediaType === MEDIA_TYPES.CLIP && !!clipId && metadataState.clipId === clipId) ||
          (!episodeId && !channelId && !clipId)) &&
        !metadataState.error
      )
        return
      metadataAbortRef.current()
      const metadataAbortController = new AbortController()
      metadataAbortRef.current = () => metadataAbortController.abort()

      metadataDispatch({ type: METADATA_ACTION_TYPES.METADATA_CLEAR })
      metadataDispatch({
        type: METADATA_ACTION_TYPES.METADATA_LOADING,
        payload: { clipId, episodeId, seasonId, seriesId, channelId, mediaType }
      })

      fetchPlaybackMetadata(channelId || clipId || episodeId, { signal: metadataAbortController.signal })
        .then(metadata => {
          if (clipId) {
            // Workaround because metadata on clip is lacking
            return fetchPlaybackMetadata(episodeId).then(episodeMetadata => {
              return {
                ...metadata,
                preplay: episodeMetadata.preplay,
                _embedded: { ...episodeMetadata._embedded, ...metadata._embedded }
              }
            })
          }
          return metadata
        })
        .then(metadata => {
          metadataDispatch({
            type: METADATA_ACTION_TYPES.METADATA_LOADED,
            payload: { clipId, episodeId, seasonId, seriesId, channelId, mediaType, metadata }
          })
        })
        .catch(error => {
          logger.error(error)
          metadataDispatch({
            type: METADATA_ACTION_TYPES.METADATA_ERROR,
            error,
            payload: { clipId, episodeId, seasonId, seriesId, channelId, mediaType }
          })
        })

      playlistAbortRef.current()
      const playlistAbortController = new AbortController()
      playlistAbortRef.current = () => playlistAbortController.abort()

      playlistDispatch({ type: PLAYLIST_ACTION_TYPES.PLAYLIST_CLEAR })

      if (mediaType === MEDIA_TYPES.PROGRAM) {
        playlistDispatch({ type: PLAYLIST_ACTION_TYPES.PLAYLIST_LOADING, payload: { episodeId } })

        fetchMusicPlaylist(episodeId, { signal: playlistAbortController.signal })
          .then(playlist => {
            playlistDispatch({ type: PLAYLIST_ACTION_TYPES.PLAYLIST_LOADED, payload: { episodeId, playlist } })
          })
          .catch(error => {
            playlistDispatch({ type: PLAYLIST_ACTION_TYPES.PLAYLIST_ERROR, payload: { episodeId }, error })
          })
      }
      return {
        abort: () => {
          metadataAbortController.abort()
          playlistAbortController.abort()
        }
      }
    },
    [metadataState.channelId, metadataState.clipId, metadataState.episodeId, metadataState.error]
  )

  const updateMediaType = mediaType => {
    metadataDispatch({ type: METADATA_ACTION_TYPES.UPDATE_MEDIATYPE, payload: mediaType })
  }
  const value = useMemo(() => {
    return {
      ...metadataState,
      indexPoints: mergeIndexPointsAndPlaylist(metadataState.metadata, playlistState.playlist),
      loadMetadata,
      updateMediaType
    }
  }, [loadMetadata, metadataState, playlistState.playlist])

  return <PlayerMetadataContext.Provider value={value}>{children}</PlayerMetadataContext.Provider>
}

PlayerMetadataProvider.propTypes = {
  children: PropTypes.node
}

export const usePlayerMetadata = () => {
  const context = useContext(PlayerMetadataContext)
  return context
}

export const useCurrentlyPlayingId = () => {
  const { channelId, episodeId, clipId } = useContext(PlayerMetadataContext)
  return channelId || clipId || episodeId
}

export const useCurrentlyPlayingSeriesId = () => {
  const { seriesId } = useContext(PlayerMetadataContext)
  return seriesId
}

export const useCurrentlyPlayingSeasonId = () => {
  const { seasonId } = useContext(PlayerMetadataContext)
  return seasonId
}

export const useCurrentlyPlayingMediaType = () => {
  const { mediaType } = useContext(PlayerMetadataContext)
  return mediaType
}

export const useCurrentlyPlayingCombinedId = () => {
  const { clipId, seriesId, episodeId, channelId } = useContext(PlayerMetadataContext)

  const head = seriesId ? `${seriesId}:` : ''
  return `${head}${channelId || clipId || episodeId}`
}

export const usePlayerTitle = () => {
  const { metadata } = useContext(PlayerMetadataContext)
  return metadata.preplay?.titles?.title
}

export const usePlayerSubtitle = () => {
  const { metadata } = useContext(PlayerMetadataContext)
  return metadata.preplay?.titles?.subtitle
}

export const useSquarePosters = () => {
  const { metadata } = useContext(PlayerMetadataContext)
  return metadata.preplay?.squarePoster?.images
}

export const useLandscapePosters = () => {
  const { metadata } = useContext(PlayerMetadataContext)
  return metadata.preplay?.poster?.images
}

export const usePoster = () => {
  const { metadata } = useContext(PlayerMetadataContext)
  return (
    metadata.preplay?.squarePoster?.images?.find(({ pixelWidth }) => pixelWidth >= 900)?.url ||
    metadata.preplay?.poster?.images?.find(({ pixelWidth }) => pixelWidth >= 900).url
  )
}

export const useSmallPoster = () => {
  const { metadata } = useContext(PlayerMetadataContext)
  return (
    metadata.preplay?.squarePoster?.images?.find?.(({ pixelWidth }) => pixelWidth >= 300)?.url ||
    metadata.preplay?.poster?.images?.find?.(({ pixelWidth }) => pixelWidth >= 300)?.url
  )
}

export const useActiveIndexPointTitle = () => {
  const { indexPoints } = useContext(PlayerMetadataContext)
  const { time } = usePlayerTime()
  const listOfindexPoints = indexPoints?.filter?.(element => element.start <= time)
  const activeIndexPoint = listOfindexPoints?.pop()
  return activeIndexPoint?.title || null
}

export const useIndexPoints = () => {
  const { indexPoints } = useContext(PlayerMetadataContext)
  return indexPoints
}

export const usePlayerMainTitle = () => {
  const { mediaType, metadata } = useContext(PlayerMetadataContext)
  if (mediaType === MEDIA_TYPES.PODCAST) {
    return metadata._embedded?.podcast?.titles?.title
  } else if (mediaType === MEDIA_TYPES.PROGRAM) {
    return metadata.preplay?.titles?.title
  } else if (mediaType === MEDIA_TYPES.CLIP) {
    return metadata._embedded?.podcast?.titles?.title
  }

  return null
}

export const usePlayerSecondaryTitle = () => {
  const { mediaType, metadata } = useContext(PlayerMetadataContext)
  if (mediaType === MEDIA_TYPES.PODCAST) {
    return metadata.preplay?.titles?.title
  } else if (mediaType === MEDIA_TYPES.PROGRAM) {
    return metadata.preplay?.titles?.subtitle
  } else if (mediaType === MEDIA_TYPES.CLIP) {
    return metadata.preplay?.titles?.title
  }

  return null
}

export const useVideoVersionId = () => {
  const { metadata } = useContext(PlayerMetadataContext)
  return metadata?._embedded?.podcastEpisode?.clipId
}

export const useAudioVersionId = () => {
  const { mediaType, episodeId } = useContext(PlayerMetadataContext)
  return mediaType === MEDIA_TYPES.CLIP && episodeId
}

export const useHasVideoVersion = () => {
  const videoVersionClipId = useVideoVersionId()
  const audioVersionEpisodeId = useAudioVersionId()
  return !!videoVersionClipId || !!audioVersionEpisodeId
}
