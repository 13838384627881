import _pick from 'lodash/pick'
import _partialRight from 'lodash/partialRight'

export const clientConfigPicker = _partialRight(_pick, [
  'BROWSER_LOG_LEVEL',
  'NODE_ENV',
  'PORT',
  'HOST',
  'DIST_PATH',
  'APPINSIGHTS_INSTRUMENTATIONKEY',
  'GA_ACCOUNT',
  'RELEASE_NUMBER',
  'APPLICATION_NAME',
  'APPSETTING_WEBSITE_SITE_NAME',
  'PS_API_CLIENT_BASE_URL',
  'PS_API_BASE_URL',
  'RADIO_INTERACTION_API_URL',
  'PERSONALISED_API_URL',
  'SERVER_ENV',
  'CHROMECAST_ID',
  'LUDO_APICLIENT_DOMAIN',
  'PERSONALISATION_API',
  'OIDC_API',
  'OIDC_IFRAME_PATH',
  'OIDC_CLIENT_ID',
  'CATN',
  'CSSN',
  'SNOWPLOW_COLLECTOR_ENDPOINT'
])

export default process.browser && window.__NRK_RADIO_CONFIG__
  ? window.__NRK_RADIO_CONFIG__
  : clientConfigPicker(process.env)
