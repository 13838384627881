import React from 'react'
import PropTypes from 'prop-types'
import LoadableComponentErrorBoundry from '../LoadableComponentErrorBoundry'

const withLoadableComponentErrorBoundry = LazyComponent => {
  const LoadableErrorBoundryWrappedComponent = ({ showMessageOnError, ...restProps }) => {
    return (
      <LoadableComponentErrorBoundry showMessageOnError={showMessageOnError}>
        <LazyComponent {...restProps} />
      </LoadableComponentErrorBoundry>
    )
  }

  LoadableErrorBoundryWrappedComponent.propTypes = {
    showMessageOnError: PropTypes.bool
  }

  return LoadableErrorBoundryWrappedComponent
}

export default withLoadableComponentErrorBoundry
